import {getApiClient} from "../client";
import qs from "qs";

function ordersAPI() {
  return {
      createOrderInvitation: (orderData) =>
          getApiClient().post("/admin/orderinvitation", orderData),
      editOrderInvitation: (orderData) =>
          getApiClient().put("/admin/orderinvitation", orderData),
      modifyOrderInvitation: (orderData) =>
          getApiClient().put("/admin/orderinvitation", orderData),
      createOrder: (orderData) =>
          getApiClient().post("/admin/ap/custom-order/", orderData),
      editOrder: (updatedOrderData, orderId) =>
          getApiClient().put(`/admin/order/${orderId}`, updatedOrderData),
      getOrdersList: (search, rules) =>
          getApiClient().get("/admin/order/list", {
              params: {
                  search,
                  ...rules,
              },
              paramsSerializer: (params) => {
                  return qs.stringify(params, {arrayFormat: "comma"});
              },
          }),
      getBoosterOrders: (boosterId, ordering = "-created") =>
          getApiClient().get(`/admin/booster/${boosterId}/orders/purchased`, {
              params: {ordering},
          }),
      getCustomOrders: (boosterId) =>
          getApiClient().get(`/admin/booster/${boosterId}/custom-orders`),
      getBuyerOrders: (buyerId) =>
          getApiClient().get(`/admin/user/${buyerId}/buyer/orders`),
      updateCustomOrderStatus: (boosterId,id, status) =>
          getApiClient().put(`admin/booster/${boosterId}/custom-order/${id}`, {status}),
      updateNewOrder: (boosterId,id, status) =>
          getApiClient().put(`admin/booster/${boosterId}/order/${id}`, {invite_status:status}),
      getActiveRequestLobbyOrders: (boosterID, params) =>
          getApiClient().get(`/admin/booster/${boosterID}/request-lobby`, {params}),
      getBoostingNewOrders: (boosterId, params) =>
          getApiClient().get(`/admin/booster/${boosterId}/orders/new`, {params}),
      getBoostingAcceptedOrders: (boosterId, params) =>
          getApiClient().get(`/admin/booster/${boosterId}/orders/accepted`, {params}),
      getBoostingSettingsServices: (boosterId) =>
          getApiClient().get(`/admin/booster/${boosterId}/settings/services`),
      getBoostingSentRequests: (boosterId) =>
          getApiClient().get(`/admin/booster/${boosterId}/settings/requests`),


      //updateCustomOrderStatus
  };
}

export default ordersAPI;
