import { useState } from "react";

import { Menu as AntMenu, Space, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { Container, Menu, Button } from "./MoreMenu.style";
import { notifyError, notifySuccess } from "../../utils/notifications";
import { EditOutlined, MoreOutlined, UploadOutlined } from "@ant-design/icons";
import usersAPI from "../../http/users";
import styled from "@emotion/styled";
import methodsAPI from "../../http/methods";

const MoreMenu = ({ reload, userId, editing, methodID }) => {
  const [loading, setLoading] = useState(false);

  console.log({ editing, methodID });

  const customRequest = ({ file, onSuccess, onError }) => {
    const data = new FormData();
    if (editing) {
      data.append("image", file);
    } else {
      data.append("profile_picture", file);
    }

    if (onSuccess) {
      setLoading(true);

      if (editing) {
        onSuccess(
          data,
          methodsAPI()
            .editMethod(data, methodID)
            .then(({ data }) => {
              setLoading(false);
              notifySuccess("Your avatar has been uploaded successfully.");
              reload();
            })
            .catch((err) => {
              if (onError) {
                onError(err);
                setLoading(false);
              }
              notifyError(err?.response?.data);
            })
        );
      } else {
        onSuccess(
          data,
          usersAPI()
            .uploadAdminAvatar(userId, data)
            .then(({ data }) => {
              setLoading(false);
              notifySuccess("Your avatar has been uploaded successfully.");
              reload();
            })
            .catch((err) => {
              if (onError) {
                onError(err);
                setLoading(false);
              }
              notifyError(err?.response?.data);
            })
        );
      }
    }
  };

  return (
    <>
      {/*<ImgCrop>*/}
      <Upload
        accept="image/png, image/gif, image/jpeg"
        fileList={[]}
        customRequest={customRequest}
      >
        {!editing ? (
          <Space>
            <EditOutlined></EditOutlined>
            <b>Change user image</b>
          </Space>
        ) : (
          <StyledSpan>Change logo</StyledSpan>
        )}
      </Upload>
      {/*</ImgCrop>*/}
    </>
  );
};

export default MoreMenu;

const StyledSpan = styled.span`
  font-family: 'Montserrat';
  font-size: 13px;
  color: #1aa2f9;
  text-align: center;
  cursor: pointer;
`;
