import styles from "./TypeOfService.module.css";
import {
  Button,
  Input,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  Skeleton,
} from "antd";
import { useHistory } from "react-router-dom";
import add_new_service from "../../assets/icons/ADD-NEW-SERVICE.png";
import service_type_inner from "../../assets/icons/Service-Test-2.png";
import edit_Service from "../../assets/icons/EDIT-SERVICE.png";
import DeletePopup from "../DeletePopup/DeletePopup";
import {
  DeleteOutlined,
  EyeOutlined,
  PercentageOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import gamesAPI from "../../http/games";
import moment from "moment";
import ModalAddGame from "./Modals/ModalAddGame";
import { debounce } from "lodash";
import { notifySuccess } from "../../utils/notifications";
import parseErrors from "../../utils/parseServerError";
import delete_service from "../../assets/icons/DELETE-SERVICE.png";
import { Link } from "react-router-dom";
import { StyledTable } from "../Users/Users";

const { Title } = Typography;
const { Search } = Input;

const initialModalState = {
  visible: false,
  item: null,
  title: "Add new Brand",
};

const Games = () => {
  const history = useHistory();
  const [modalState, setModalState] = useState(initialModalState);
  const [gamesListData, setGamesListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 10,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSizeOptions: [10, 20, 50, 100],
    total: 0,
  });
  const [popupDelete, setPopupDelete] = useState(false);
  const onPopup = (state) => {
    setPopupDelete(state);
  };
  const [params, setParams] = useState({
    limit: 20,
    offset: 0,
  });
  const [currentGame, setCurrentGame] = useState(null);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPaginationSettings({ ...paginationSettings, current, pageSize });
    setParams({
      ...params,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    });
  };

  const getListOfGames = async (searchKeyWord, params) => {
    try {
      setIsLoading(true);
      const res = await gamesAPI().getGameList(searchKeyWord, params);
      setGamesListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };

  const openModal = (game) => {
    setModalState({ ...initialModalState, visible: true, item: game });
  };

  const viewDetails = (game) => {
    return history.push("/wb/brands/" + game.id);
  };

  const handlePopularGame = async (data) => {
    try {
      await gamesAPI().editGame({ ...data, popular: !data.popular }, data.id);
      getListOfGames(searchKeyWord, params);
      notifySuccess(`${data.name} is updated.`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getListOfGames(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "Game name",
      render: (data) => {
        return (
          <Tooltip title={"View details"}>
            <Space
              style={{ cursor: "pointer" }}
              onClick={() => viewDetails(data)}
            >
              <EyeOutlined></EyeOutlined>
              <b>{data?.name ?? "/"}</b>
            </Space>
          </Tooltip>
        );
      },
    },

    {
      title: "Popular",
      render: (data) => {
        return (
          <Switch
            checked={data.popular}
            onChange={() => handlePopularGame(data)}
          />
        );
      },
    },
    {
      title: "Sale",
      render: (data) => {
        return (
          <div>
            <b>{data?.sale ?? 0}</b>
            <PercentageOutlined />
          </div>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created",
      key: "created",
      render: (created) => {
        return (
          <Tag color={"green"}>{moment(created).format("DD MM YYYY")}</Tag>
        );
      },
    },
    {
      title: "Updated at",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => {
        return <Tag color={"blue"}>{moment(updated).format("DD MM YYYY")}</Tag>;
      },
    },
    {
      title: "Edit",
      key: "edit",
      render: (game) => {
        return (
          <Space>
            <Button onClick={() => openModal(game)}>Edit Brand</Button>
            <Popconfirm
              title={"Are you sure to delete " + game.name + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => handleDelete(game)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: paginationSettings.current - 1,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  const handleDelete = async (item) => {
    try {
      const { id } = item;
      await gamesAPI().deleteGame(id);
      getListOfGames(searchKeyWord, params);
      notifySuccess(`${item?.name}  is deleted.`);
      setPopupDelete(false)
    } catch (e) {
      parseErrors(e.response.data);
    }
  };
  console.log("brands ki details", currentGame);
  return (
    <div className={styles.container}>
      <ModalAddGame
        refreshData={() => getListOfGames(searchKeyWord, params)}
        modalState={modalState}
        closeModal={closeModal}
      />
      <h1 className={styles.header}>Brands </h1>

      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search games"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />

        <button
          className="bg-button_bg_main p-3 w-52 text-white border-neon_border_line border-2 flex justify-center items-center gap-3"
          onClick={() => openModal()}
          size={"large"}
          icon={<PlusSquareOutlined />}
        >
          <img src={add_new_service} alt={add_new_service}></img>
          Add New Brand
        </button>
      </div>
      {/* <StyledTable
        pagination={paginationSettings}
        dataSource={gamesListData}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      /> */}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div className="grid grid-cols-2 gap-4">
          {gamesListData?.map((item) => (
            <div
              className="flex items-center gap-5 bg-[#03364a] w-[100%] h-[8vw] rounded-lg"
              key={item.id}
            >
              <div className="flex items-center justify-center left-panel w-4/5 gap-2">
                <div className="bg-[#052634] p-4 flex justify-center items-center rounded-lg">
                  <img
                    className=""
                    src={service_type_inner}
                    alt="service_type_inner"
                  ></img>
                </div>
                <div className="flex flex-col items-center justify-center gap-2">
                  <h1 className="text-white font-bold text-2xl text-left w-full">
                    <Link to={`/wb/brands/${item.id}`}>{item.name}</Link>{" "}
                  </h1>
                  <div className="flex items-center gap-5">
                    <button
                      className="new_btn_service text-sm font-bold flex items-center gap-1"
                      onClick={() => {
                        setModalState({
                          ...initialModalState,
                          visible: true,
                          item: item,
                        });
                        openModal(item);
                      }}
                    >
                      <img src={edit_Service} alt="editService"></img>Edit Brand
                    </button>

                    {popupDelete ? (
                      <DeletePopup
                        id="deletepopup"
                        style={{ color: "red", transform: "scale(1.3)" }}
                        onDelete={(item) => handleDelete(item)}
                        handleCancel={onPopup}
                        serviceName={currentGame?.name}
                        serviceId={currentGame?.id}
                        item={currentGame}
                      />
                    ) : null}
                    {/* <Popconfirm
                  title={"Are you sure to delete " + item.name + "?"}
                  icon={
                    <QuestionCircleOutlined
                      style={{ color: "red", transform: "scale(1.3)" }}
                    />
                  }
                  onConfirm={() => deleteServis(item)}
                  okText="Yes"
                  cancelText="No"
                  size={"large"}
                > */}
                    <button
                      className="danger_btn text-sm font-bold flex items-center gap-1 "
                      onClick={(e) => {
                        setCurrentGame(item);
                        onPopup(e);
                      }}
                    >
                      <img src={delete_service} alt="editService"></img>Delete
                      Brand
                    </button>
                    {/* </Popconfirm> */}
                  </div>
                </div>
              </div>
              <div className="flex items-start justify-end right-panel h-full pt-3 gap-3 pr-2 w-2/5 ">
                <span className="text-[#b1b6b9] font-bold">Service Status</span>
                <div class="toggle-switch">
                  <input type="checkbox" id="toggle" class="toggle-input" />
                  <label for="toggle" class="toggle-label"></label>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Games;
