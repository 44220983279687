import usersAPI from "../../../http/users";
import { notifyError, notifySuccess } from "../../../utils/notifications";
import { useEffect, useMemo, useState } from "react";
import Search from "antd/es/input/Search";
import lodash, { debounce } from "lodash";
import styled from "@emotion/styled";
import { StyledTable } from "../Users";

const SettingsRequests = ({ userId }) => {
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState({});
  const [modalState, setModalState] = useState({
    isVisible: false,
    item: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const getList = async () => {
    try {
      setIsLoading(true);
      const res = await usersAPI().getSettingsRequests(
        userId,
        searchKeyWord,
        params
      );
      const formatedItems = res.data.results.map((result) => result.games);
      setData(lodash.flattenDeep(formatedItems));
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notifyError(e?.response?.data);
    }
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
    getList();
  }, [searchKeyWord, params]);

  const viewDetailsModal = (data) => {
    setModalState({ ...modalState, isVisible: true, item: data });
  };

  const closeModal = (data) => {
    setModalState({ isVisible: false });
  };

  const acceptOrder = async (data) => {
    console.log(data);
    try {
      const res = await usersAPI().handleNewOrder(userId, data.id, {
        invite_status: 1,
      });
      getList();
      notifySuccess("Order is accepted.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };
  const declineOrder = async (data) => {
    console.log(data);
    try {
      const res = await usersAPI().handleNewOrder(userId, data.id, {
        invite_status: 2,
      });
      getList();
      notifySuccess("Order is declined.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };

  const mouseHandle = (id, type) => {
    if (type == 0) {
      setShowDetails({ ...showDetails, [id]: true });
    } else {
      setShowDetails({ ...showDetails, [id]: false });
    }
  };

  const columns = [
    {
      title: "Sent requests",
      key: "request",
      render: (data) => {
        return (
          <RequestContainer>
            <GameName>{data?.game?.name ?? "Game name missing."}</GameName>
            <ServicesList>
              <AppliedStyle>Applied for: </AppliedStyle>

              <ServicesStyleNames>
                {data?.services.map((item, key) => {
                  if (key == data.services.length - 1) return item.name;
                  return item.name + ", ";
                })}
              </ServicesStyleNames>
            </ServicesList>
          </RequestContainer>
        );
      },
    },
  ];

  return (
    <Container>
      <StyledSearch
        placeholder="Search requests"
        size="large"
        allowClear
        loading={false}
        onChange={debouncedChangeHandler}
      />

      <StyledTable
        pagination={paginationSettings}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Container>
  );
};

export default SettingsRequests;

const RequestContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #ffffff;
  border: 1px solid #e0e5ec;
  border-radius: 5px;
`;

const GameName = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 3.25;
  font-family: 'Montserrat';
`;

const ServicesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledSearch = styled(Search)`
  width: 300px;
  margin: 10px 0;
`;

export const StyledInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  transition: ease-in 1s;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppliedStyle = styled.span`
  margin: 0px 5px 0 0;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #001334;
`;

const ServicesStyleNames = styled.span`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #001334;
`;
