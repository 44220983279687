import { PageHeader, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./UserDetails.module.css";
import usersApi from "../../http/users/";
import ManageProfile from "./ManageProfile";
import Transactions from "./Transactions";
import Selling from "./Selling/index";
import Orders from "./Orders";
import { Skeleton } from "antd/es";

const { TabPane } = Tabs;

const UserDetails = (userId) => {
  const router = useHistory();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");

  const redirectToUsersList = () => {
    router.push("/users");
  };

  const loadUserDetails = async () => {
    try {
      setLoading(true);
      const res = await usersApi().getUserDetails(userId?.userId);
      setUser(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.dir(e);
    }
  };

  console.log(user);

  useEffect(() => {
    loadUserDetails();
  }, []);
  return (
    <div className={styles.container}>
      <PageHeader
        className="site-page-header"
        onBack={redirectToUsersList}
        title="Back to users list"
      />
      <h1 className={styles.title}>Edit user </h1>
      {!loading ? (
        <Tabs
          onChange={(tab) => setActiveTab(tab)}
          type="card"
          defaultActiveKey="profile"
          style={{ marginBottom: 32 }}
        >
          <TabPane tab="Manage profile" key="profile" />
          <TabPane tab="Purchased Orders" key="orders" />
          {user?.role !== 0 && <TabPane tab="Boosting" key="selling" />}
          <TabPane tab="Transaction" key="transaction" />
        </Tabs>
      ) : (
        <Skeleton />
      )}

      <div>
        {activeTab == "profile" && (
          <ManageProfile
            user={user}
            refreshData={loadUserDetails}
            loading={loading}
          />
        )}
        {activeTab == "orders" && <Orders userId={userId?.userId} />}
        {activeTab == "selling" && (
          <Selling refreshData={loadUserDetails} userId={userId?.userId} />
        )}
        {activeTab == "transaction" && <Transactions user={user} />}
      </div>
    </div>
  );
};

export default UserDetails;
