import React, { useState } from "react";
import { Button, Space } from "antd";
import styled from "@emotion/styled";
import RequestLobby from "./RequestLobby";
import RequestLobbySentOffers from "./RequestLobbySentOffers";
import SettingsServices from "./SettingsServices";
import SettingsRequests from "./SettingsRequests";

const MainRequestLobby = ({ userId }) => {
  const [activeView, setActiveView] = useState(0);

  const handleViewChange = (view) => {
    if (view == activeView) {
      return;
    }
    setActiveView(view);
  };
  return (
    <>
      <Space>
        <Button
          type={"round"}
          disabled={activeView == 0}
          onClick={() => handleViewChange(0)}
        >
          Services
        </Button>
        <Button
          type={"round"}
          disabled={activeView == 1}
          onClick={() => handleViewChange(1)}
        >
          Sent Requests
        </Button>
      </Space>

      {activeView == 0 && <SettingsServices userId={userId} />}
      {activeView == 1 && <SettingsRequests userId={userId} />}
    </>
  );
};
export default MainRequestLobby;

const StyledSpace = styled(Space)`
  margin-bottom: 10px;
`;
