import { getApiClient } from "../client";

function requestLobbyAPI() {
  return {
    getList: (search, rules) =>
      getApiClient().get(`/admin/booster-offer`, {
        params: {
          search,
          ...rules,
        },
      }),
    getActiveList: (search, rules) =>
      getApiClient().get(`/admin/custom/order`, {
        params: {
          search,
          ...rules,
        },
      }),
    createRequest: (reqData) =>
      getApiClient().post(`/admin/custom/order`, reqData),
    acceptOffer: (offerId, data) =>
      getApiClient().put(`/admin/booster-offer/${offerId}`, data),
  };
}

export default requestLobbyAPI;
