import styles from "../RoomsPage.module.css";
import { Input } from "antd";
import { useParams } from "react-router-dom";

import roomsAPI from "../../../http/rooms";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { StyledTable } from "../../Users/Users";

const { Search } = Input;

const RoomsPage = () => {
  const { roomId } = useParams();

  const [pageListData, setPageListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
    defaultCurrent: 1,
    defaultPageSize: 5,
    pageSizeOptions: [5,10,20,50,100],
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    ordering: "-created",
  });

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPaginationSettings({ ...paginationSettings, current, pageSize });
    setParams({
      ...params,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    });
  };

  const getListOfMessages = async () => {
    try {
      if (!roomId) {
        return;
      }

      setIsLoading(true);
      const res = await roomsAPI().getRoom(roomId, searchKeyWord, params);
      setPageListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
        getListOfMessages(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [roomId, searchKeyWord, params]);

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user) => <p>{user.username}</p>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Seen",
      dataIndex: "seen",
      key: "seen",
      render: (seen) => <p>{seen ? "Yes" : "No"}</p>,
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Room page</h1>
      <div className={styles.uploadSitemap}></div>
      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search messages"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
      </div>
      <StyledTable
        pagination={paginationSettings}
        dataSource={pageListData}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default RoomsPage;
