import styles from "./Orders.module.css";
import OrdersComponent from "../../components/Sales/Orders.js";

const Orders = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Orders </h1>
      <OrdersComponent />
    </div>
  );
};

export default Orders;
