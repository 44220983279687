import { Button, Modal, Tabs } from "antd";
import React, { useState } from "react";
import Details from "./Details";
import ComplaintMessages from "./ComplaintMessages";
import Settings from "./Settings";

const { TabPane } = Tabs;

const ModalAllOrderInfos = ({ closeModal, modalState, refreshData }) => {
  const [step, setStep] = useState(0);
  if (!modalState.item) return <></>;

  const { item } = modalState;

  console.log({ item });
  const handleTabChange = (numberOfStep) => {
    setStep(numberOfStep);
  };
  return (
    <Modal
      width={1080}
      title="Viewing order"
      visible={modalState.isVisible}
      onCancel={closeModal}
      footer={[<Button onClick={closeModal}>Close</Button>]}
    >
      <Tabs onChange={handleTabChange} type="card" value={step}>
        <TabPane tab="Order info" key="0"></TabPane>
        <TabPane tab="Order settings" key="1"></TabPane>
        <TabPane tab="Complaints" key="2"></TabPane>
      </Tabs>

      {step == 0 && <Details item={item} />}
      {step == 1 && (
        <Settings
          item={item}
          refreshData={refreshData}
          closeModal={closeModal}
        />
      )}
      {step == 2 && (
        <ComplaintMessages
          item={item}
          refreshData={refreshData}
          messages={item.complaints ?? []}
        />
      )}
    </Modal>
  );
};

export default ModalAllOrderInfos;
