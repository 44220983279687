import gamesAPI from "../../http/games";
import { useEffect, useState } from "react";

import styles from "./GameDetails.module.css";
import { Card, Empty, PageHeader, Skeleton, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import Title from "antd/es/typography/Title";
import { EyeOutlined } from "@ant-design/icons";
import offersApi from "../../http/offers";
import productsAPI from "../../http/products";
import categoriesAPI from "../../http/categories";

const GameDetails = (props) => {
  const {
    match: {
      params: { gameId },
    },
  } = props;

  const router = useHistory();
  const [gameServicesData, setterServicesData] = useState([]);
  const [gameDetails, setterGameDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getGameDetails = async (gameIdent) => {
    try {
      const res = await gamesAPI().getGameDetails(gameIdent);
      setterGameDetails(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getGameServices = async (gameIdent) => {
    try {
      setIsLoading(true);
      const res = await gamesAPI().getGameServices(gameIdent);
      console.log({ servis: res.data });
      setterServicesData(res.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    getGameDetails(gameId);
    getGameServices(gameId);
  }, [gameId]);

  const redirectToGames = () => {
    router.push("/wb/brands");
  };

  const redirectToServiceDetails = (id) => {
    router.push(`/wb/brands/${gameId}/services/${id}`);
  };

  return (
    <div className={styles.container}>
      <PageHeader
        className="site-page-header"
        onBack={() => redirectToGames()}
        title={"Back to game list"}
      />
      <div className={styles.title}>
        <Title>{gameDetails?.name}</Title>
      </div>

      {isLoading ? (
        <Skeleton />
      ) : (
        <div className={styles.cardContainers}>
          {gameServicesData.length > 0 ? (
            gameServicesData.map((service) => (
              <GameCard gameId={gameId} service={service} />
            ))
          ) : (
            <Empty />
          )}
        </div>
      )}
    </div>
  );
};

const GameCard = ({ gameId, service }) => {
  const router = useHistory();

  const [offersCount, setOffersCount] = useState([]);
  const [productsCount, setProductsCount] = useState([]);
  const [categoriesCount, setCategoriesCount] = useState([]);

  const getOffers = async () => {
    const res = await offersApi().getOffers(gameId, service.id, 1, 0);
    setOffersCount(res.data.count);
  };

  const getProducts = async () => {
    const res = await productsAPI().getProductsList(gameId, service.id, 1, 0);
    setProductsCount(res.data.count);
  };

  const getCategories = async () => {
    const res = await categoriesAPI().getCategories(gameId, service.id, 1, 0);
    setCategoriesCount(res.data.count);
  };

  useEffect(() => {
    getCategories();
    getOffers();
    getProducts();
  }, []);

  const redirectToServiceDetails = (id) => {
    router.push(`/wb/brands/${gameId}/services/${id}`);
  };

  return (
    <Card
      title={service.name}
      bordered={true}
      style={{ width: 300 }}
      extra={
        <Tooltip title={`View more details for ${service.name} `}>
          <EyeOutlined style={{ cursor: "pointer" }} />
        </Tooltip>
      }
      onClick={() => redirectToServiceDetails(service?.id)}
    >
      {service.type_of_service == 0 && (
        <div className={styles.innerCards}>
          <b>Products</b>
          <span>{productsCount}</span>
        </div>
      )}
      {service.type_of_service == 0 && (
        <div className={styles.innerCards}>
          <b>Categories</b>
          <span>{categoriesCount}</span>
        </div>
      )}
      <div className={styles.innerCards}>
        <b>Offers</b>
        <span>{offersCount}</span>
      </div>
    </Card>
  );
};

export default GameDetails;
