import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tooltip,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import gamesAPI from "../../../http/games";
import servicesAPI from "../../../http/services";
import { notifySuccess } from "../../../utils/notifications";
import parseErrors from "../../../utils/parseServerError";
import ReactQuill from "react-quill";
import edit_new_service_btn from "../../../assets/icons/edit-file.png";
import add_new_service_btn from "../../../assets/icons/plus-icon.png";
import ImageUploadButton from "../../Button/ImageUploadButton";

const { Item, useForm } = Form;
const { Group } = Checkbox;
const { Option } = Select;

const ModalAddGame = ({ modalState, closeModal, refreshData }) => {
  const [form] = useForm();
  const formRef = useRef();
  const [services, setServices] = useState([]);
  const [quillData, setQuillData] = useState("");

  const loadServicesList = async () => {
    try {
      const res = await servicesAPI().getServicesList();
      setServices(() => res.data);
    } catch (e) {
      console.dir(e);
    }
  };

  const handleDataFromQuill = (data) => {
    setQuillData(data);
  };

  const onFinish = (values) => {
    if (modalState.item) {
      console.log("Edit");
      editExistingGame({ ...values });
    } else {
      addNewGame({ ...values });
    }
  };

  console.log(modalState.item);

  useEffect(() => {
    loadServicesList();
  }, []);

  const formatServices = () => {
    const result = services.map((service) => {
      return {
        label: service.name,
        value: service.id,
      };
    });
    return result;
  };

  const memoizedServices = useMemo(() => formatServices(), [services]);

  const addNewGame = async (gameData) => {
    try {
      await gamesAPI().addGame(gameData);
      refreshData();
      closeModal();
      notifySuccess("New game added.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };
  const editExistingGame = async (gameData) => {
    try {
      await gamesAPI().editGame(gameData, modalState?.item?.id);
      refreshData();
      closeModal();
      notifySuccess("Game edited.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [modalState]);

  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState?.item ? "Edit game" : "Add game"}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button  className="flex items-center gap-2 cancel_service" key="back" onClick={() => closeModal()}>
           <img
              width={20}
              src={add_new_service_btn}
              alt="add_new_service_btn"
            ></img>{" "}
          Cancel
        </Button>,
        <Button  className="flex items-center gap-2 text-bold new_btn_service" key="add" onClick={() => formRef.current.submit()}>
          {modalState?.item ? (
              <img
                width={20}
                src={edit_new_service_btn}
                alt="add_new_service_btn"
              ></img>
            ) : (
              <img
                width={20}
                src={add_new_service_btn}
                alt="add_new_service_btn"
              ></img>
            )}
          {modalState?.item ? "Edit game" : "Add game"}
        </Button>,
      ]}
    >
      <Form
        initialValues={modalState.item}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Item
          name="name"
          label="Brand Name"
          required
          // tooltip="This is a required field"
          // rules={[{ required: true, message: "Please input game name!" }]}
        >
          <Input placeholder="Brand Name" />
        </Item>
        <Item
          name="image"
          label="Brand Image"
          // tooltip="This is a required field"
          // rules={[{ required: true, message: "Please input game name!" }]}
        >

        <ImageUploadButton/>
        </Item>
        {/* <Item
          // required
          name="services"
          label="Services for this game"
          tooltip="This is a required field"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please select services for game name!",
          //   },
          // ]}
        > */}
          {/* <Select
            optionFilterProp="children"
            showArrow={true}
            allowClear
            mode="multiple"
          >
            {memoizedServices.map((option) => {
              return <Option value={option.value}>{option.label}</Option>;
            })}
          </Select> */}
        {/* </Item> */}
        {/* <Item name="sale" label="Game sale">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Enter sale amount"
            addonAfter="$"
            max={100}
          />
        </Item> */}
      </Form>
    </Modal>
  );
};

export default ModalAddGame;
