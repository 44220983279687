import { getApiClient } from "../client";

function boosterRequestsAPI() {
  return {
    getList: (search, rules) =>
      getApiClient().get(`/admin/booster/requests/list/`, {
        params: {
          search,
          ...rules,
        },
      }),
    edit: (updatedData, id) =>
      getApiClient().put(`/admin/booster/request/${id}`, updatedData),
  };
}

export default boosterRequestsAPI;
