import { Button, Input, Select, Space, Tag } from "antd";
import { debounce } from "lodash";
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import transactionApi from "../../http/transactions/";
import moment from "moment";
import styles from "./RefundRequests.module.css";
import { notifySuccess } from "../../utils/notifications";

import {
  REFUND_ACCEPTED,
  REFUND_COMPLETED,
  REFUND_DECLINED,
  REFUND_PENDING,
} from "../../utils/constants.js";
import parseErrors from "../../utils/parseServerError";
import RefundModal from "./Modals/RefundModal";
import ModalRefundListOrderInfo from "./Modals/ModalRefundListOrderInfo";
import { StyledTable } from "../Users/Users";

const { Option } = Select;
const { Search } = Input;

const initialModalState = {
  isVisible: false,
  item: null,
};

const WithdrawRequests = () => {
  const [refunds, setRefunds] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);
  const [modalStateViewOrder, setModalStateViewOrder] =
    useState(initialModalState);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [activeView, setActiveView] = useState(REFUND_PENDING);
  const [isLoading, setLoading] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    status: activeView,
    ordering: "-created",
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };

  const getListOfRefunds = async (searchKeyWord = "", params) => {
    try {
      setLoading(true);
      const res = await transactionApi().getRefundsList(searchKeyWord, params);
      setRefunds(res.data.results);
      console.log(res, "iz refund liste");
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      parseErrors(e.response.data);
      console.dir(e);
    }
  };

  const handleActive = (status) => {
    setParams({ ...params, status });
    setActiveView(status);
  };

  const editWithdraw = (withdraw) => {
    setModalState({
      ...initialModalState,
      visible: true,
      item: withdraw,
      refresh: () => getListOfRefunds(searchKeyWord, params),
    });
  };

  const handleStatusChange = async (event, refund, booster_status) => {
    //     if (event == WITHDRAW_DECLINED) return editWithdraw(withdraw);
    try {
      const { id } = refund;
      let updatedRefundData;
      if (booster_status == "buyers_confirmation") {
        updatedRefundData = { ...refund, buyer_status: event };
      } else if (booster_status == "booster_status") {
        updatedRefundData = { ...refund, booster_status: event };
      } else {
        updatedRefundData = { ...refund, status: event };
      }
      const res = await transactionApi().editRefund(updatedRefundData, id);
      notifySuccess(`Status for refund ${id} is updated.`);
      getListOfRefunds(searchKeyWord, params);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };
  const closeModalViewOrder = () => {
    setModalStateViewOrder(initialModalState);
  };

  const openModalForRefund = (data) => {
    setModalState({ ...modalState, isVisible: true, item: data });
  };
  const openModalForOrderDetails = (data) => {
    setModalStateViewOrder({ ...modalState, isVisible: true, item: data });
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
    getListOfRefunds(searchKeyWord, params);
  }, [searchKeyWord, params]);

  console.log(refunds);

  let columns = [
    {
      title: "Order info",
      key: "id",
      render: (data) => {
        const { order_id, buyer_name, player_name, created } = data;
        return (
          <div className={styles.order_info}>
            <Space className={styles.order}>
              <span>
                Order ID: <b>{order_id}</b>
              </span>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => openModalForOrderDetails(data)}
              >
                {" "}
                <EyeOutlined /> <b>View order</b>
              </div>
            </Space>
            <span>
              Date : <b>{moment(created).format("DD MMM YYYY")}</b>
            </span>
            <span>
              Buyer : <b>{buyer_name}</b>
            </span>
            <span>
              Player : <b>{player_name}</b>{" "}
            </span>
          </div>
        );
      },
    },
    {
      title: "Buying price",
      key: "order_price",
      dataIndex: "order_price",
      render: (data) => <Tag color="green">{data} $</Tag>,
    },
    {
      title: "Approval",
      key: "status",
      render: (data) => {
        const { status } = data;

        console.log({ activeView });
        if (activeView == 1) {
          return (
            <Button onClick={() => openModalForRefund(data)}>
              {" "}
              Refund order
            </Button>
          );
        }

        if (activeView == 0) {
          if (status == REFUND_DECLINED)
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">
                <Space>
                  <b>Declined</b>
                </Space>
              </Tag>
            );
          return (
            <Select
              value={status}
              style={{ width: "100%" }}
              onChange={(event) => handleStatusChange(event, data)}
            >
              <Option value={REFUND_PENDING} disabled style={{ color: "grey" }}>
                Refund pending
              </Option>{" "}
              <Option value={REFUND_ACCEPTED} style={{ color: "green" }}>
                Approve request{" "}
              </Option>
              <Option value={REFUND_DECLINED} style={{ color: "red" }}>
                Decline request{" "}
              </Option>{" "}
            </Select>
          );
        }

        return (
          <Select
            value={status}
            onChange={(event) => handleStatusChange(event, data)}
          >
            <Option value={REFUND_PENDING} style={{ color: "orange" }}>
              Pending
            </Option>
            <Option value={REFUND_ACCEPTED} style={{ color: "green" }}>
              Accepted
            </Option>{" "}
            <Option value={REFUND_DECLINED} style={{ color: "purple" }}>
              Declined{" "}
            </Option>
            <Option value={REFUND_COMPLETED} style={{ color: "red" }}>
              Completed
            </Option>
          </Select>
        );
      },
    },
  ];

  if (activeView == 3) {
    columns.pop();
    let copyOfColumns = [...columns];
    copyOfColumns.push({
      title: "Buyers confirmation",
      key: "status",
      render: (data) => {
        const { buyer_status } = data;

        if (buyer_status == 1)
          return (
            <Tag color="green">
              <Space>
                <CheckCircleFilled />
                <b>Accepted</b>
              </Space>{" "}
            </Tag>
          );
        return (
          <Select
            value={buyer_status}
            onChange={(event) =>
              handleStatusChange(event, data, "buyers_confirmation")
            }
            style={{ width: "100%" }}
          >
            <Option value={0} style={{ color: "orange" }} disabled>
              <Space>
                <ClockCircleFilled />
                <b>Pending</b>
              </Space>
            </Option>
            <Option value={2} style={{ color: "red" }} disabled>
              <Space>
                <CloseCircleOutlined />
                <b>Declined</b>
              </Space>
            </Option>
            <Option value={4}>
              <Space>
                <CheckCircleFilled />
                <b>Admin Approved</b>
              </Space>
            </Option>
            <Option value={3}>
              <Space>
                <ClockCircleFilled />
                <b>Resend</b>
              </Space>
            </Option>
          </Select>
        );
      },
    });
    copyOfColumns.push({
      title: "Players confirmation",
      key: "status",
      render: (data) => {
        const { booster_status } = data;

        if (booster_status == 1)
          return (
            <Tag color="green">
              <Space>
                <CheckCircleFilled />
                <b>Accepted</b>
              </Space>{" "}
            </Tag>
          );
        return (
          <Select
            value={booster_status}
            onChange={(event) =>
              handleStatusChange(event, data, "booster_status")
            }
            style={{ width: "100%" }}
          >
            <Option value={0} style={{ color: "orange" }} disabled>
              <Space>
                <ClockCircleFilled />
                <b>Pending</b>
              </Space>
            </Option>
            <Option value={2} style={{ color: "red" }} disabled>
              <Space>
                <CloseCircleOutlined />
                <b>Declined</b>
              </Space>
            </Option>
            <Option value={4}>
              <Space>
                <CheckCircleFilled />
                <b>Admin Approved</b>
              </Space>
            </Option>
            <Option value={3}>
              <Space>
                <ClockCircleFilled />
                <b>Resend</b>
              </Space>
            </Option>
          </Select>
        );
      },
    });
    columns = copyOfColumns;
  }

  return (
    <>
      <RefundModal
        closeModal={closeModal}
        modalState={modalState}
        reloadData={() => getListOfRefunds(searchKeyWord, params)}
      />
      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search refund list.."
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
        <Space>
          <Button
            onClick={() => handleActive(REFUND_PENDING)}
            shape="round"
            type={activeView == REFUND_PENDING ? "primary" : "dashed"}
          >
            Cancel Requests{" "}
          </Button>
          <Button
            onClick={() => handleActive(REFUND_ACCEPTED)}
            shape="round"
            type={activeView == REFUND_ACCEPTED ? "primary" : "dashed"}
          >
            Refund pending{" "}
          </Button>
          <Button
            onClick={() => handleActive(REFUND_COMPLETED)}
            shape="round"
            type={activeView == REFUND_COMPLETED ? "primary" : "dashed"}
          >
            Refunded
          </Button>
          {/*<Button*/}
          {/*  onClick={() => handleActive(REFUND_DECLINED)}*/}
          {/*  shape="round"*/}
          {/*  type={activeView == REFUND_DECLINED ? "danger" : "dashed"}*/}
          {/*>*/}
          {/*  Declined*/}
          {/*</Button>{" "}*/}
        </Space>
      </div>
      <ModalRefundListOrderInfo
        modalState={modalStateViewOrder}
        closeModal={closeModalViewOrder}
      />
      <StyledTable
        pagination={paginationSettings}
        onChange={handleTableChange}
        loading={isLoading}
        columns={columns}
        dataSource={refunds}
      />
    </>
  );
};

export default WithdrawRequests;
