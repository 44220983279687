import { Input, Select, Space, Table, Tag } from "antd";
import { debounce } from "lodash";
import { CloseCircleFilled } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import transactionApi from "../../http/transactions/";
import moment from "moment";
import styles from "./WithdrawRequests.module.css";
import { notifySuccess } from "../../utils/notifications";
import ModalAdminReason from "./Modals/ModalAdminReason";

import {
  STATUS_ADMIN_CONFIRMED_USER,
  STATUS_CONFIRMED_USER,
  STATUS_DECLINED_USER,
  STATUS_RESENT_USER,
  WITHDRAW_ACCEPTED,
  WITHDRAW_ADMIN_CONFIRMED,
  WITHDRAW_DECLINED,
  WITHDRAW_PENDING,
} from "../../utils/constants.js";
import parseServerError from "../../utils/parseServerError";
import { StyledTable } from "../Users/Users";
import ModalPaymentMethodInfo from "./Modals/ModalPaymentMethodInfo";

const { Option } = Select;
const { Search } = Input;

const initialModalState = {
  isVisible: false,
  item: null,
};

const WithdrawRequests = () => {
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(initialModalState);
  const [modalStatePayments, setModalStatePayments] =
    useState(initialModalState);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [userValueStatus, setUserValueStatus] = useState(STATUS_DECLINED_USER);

  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };

  const getListOfWithdraws = async () => {
    try {
      setLoading(true);
      const res = await transactionApi().getWithdrawsList(
        searchKeyWord,
        params
      );
      setDiscounts(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setLoading(false);
      console.log({ res });
    } catch (e) {
      setLoading(false);
      console.dir(e);
    }
  };

  const editWithdraw = (withdraw) => {
    setModalState({
      ...initialModalState,
      visible: true,
      item: withdraw,
      refresh: () => getListOfWithdraws(),
    });
  };

  const viewPaymentDetails = (payment) => {
    setModalStatePayments({
      ...initialModalState,
      visible: true,
      item: payment,
    });
  };

  const handleStatusChange = async (event, withdraw) => {
    if (event == WITHDRAW_DECLINED) return editWithdraw(withdraw);
    try {
      const { id } = withdraw;
      const updatedWithdrawData = {
        ...withdraw,
        status: event,
        admin_note: "",
      };
      const res = await transactionApi().editWithdraw(updatedWithdrawData, id);
      getListOfWithdraws();
      notifySuccess(`Status for withdraw ${id} is updated.`);
    } catch (e) {
      console.dir(e);
      parseServerError(e);
    }
  };

  const updateUserStatus = async (value, withdraw) => {
    try {
      setUserValueStatus(value);
      const { id } = withdraw;
      const res = await transactionApi().editWithdraw(
        { booster_status: value },
        id
      );
      getListOfWithdraws();
      notifySuccess(`Booster status for withdraw ${id} is updated.`);
    } catch (e) {
      console.dir(e);
      parseServerError(e?.response?.data);
    }
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };
  const closeModalPayments = () => {
    setModalStatePayments(initialModalState);
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
        getListOfWithdraws();
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "Booster",
      key: "booster",
      render: (data) => {
        const {
          billing_info: { email },
        } = data;
        return <span>{email} </span>;
      },
    },
    {
      title: "Request date",
      dataIndex: "created",
      render: (date) => (
        <Tag color="success"> {moment(date).format("DD MM YYYY")} </Tag>
      ),
    },
    {
      title: "Payment method",
      render: (data) => {
        return (
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => viewPaymentDetails(data)}
            color="purple"
          >
            {data?.method?.toString()?.toUpperCase() ?? "/"}{" "}
          </Tag>
        );
      },
    },
    {
      title: "Price requested",
      dataIndex: "amount",
      render: (amount) => <Tag color="blue"> {amount} $</Tag>,
    },
    {
      title: "Status",
      key: "status",
      render: (data) => {
        const { status } = data;
        if (status == WITHDRAW_ACCEPTED || status == WITHDRAW_ADMIN_CONFIRMED)
          return <Tag color="green">Approved</Tag>;
        if (status == WITHDRAW_DECLINED)
          return (
            <Tag color="red">
              <Space>
                <CloseCircleFilled />
                <b>Declined</b>
              </Space>{" "}
            </Tag>
          );
        return (
          <Select
            value={status}
            onChange={(event) => handleStatusChange(event, data)}
          >
            <Option value={WITHDRAW_PENDING} style={{ color: "orange" }}>
              Pending
            </Option>
            <Option value={WITHDRAW_ACCEPTED} style={{ color: "green" }}>
              Approved
            </Option>{" "}
            <Option
              value={WITHDRAW_ADMIN_CONFIRMED}
              style={{ color: "purple" }}
            >
              Admin Approved
            </Option>
            <Option value={WITHDRAW_DECLINED} style={{ color: "red" }}>
              Declined
            </Option>
          </Select>
        );
      },
    },
    {
      title: "Seller confirmation",
      key: "status",
      render: (data) => {
        const { booster_status, status } = data;

        if (status == WITHDRAW_PENDING || status == WITHDRAW_DECLINED) {
          return <Tag>/</Tag>;
        }

        if (status == WITHDRAW_ACCEPTED) {
          if (booster_status == STATUS_CONFIRMED_USER)
            return <Tag color="green">Confirmed</Tag>;
          if (booster_status == STATUS_DECLINED_USER)
            return (
              <Select
                color="red"
                value={userValueStatus}
                onChange={(e) => updateUserStatus(e, data)}
              >
                <Select.Option value={STATUS_DECLINED_USER}>
                  {" "}
                  Declined
                </Select.Option>
                <Select.Option value={STATUS_ADMIN_CONFIRMED_USER}>
                  {" "}
                  Admin Confirmed
                </Select.Option>
                <Select.Option value={STATUS_RESENT_USER}>
                  {" "}
                  Resend
                </Select.Option>
              </Select>
            );
          return <Tag>Pending</Tag>;
        }
        if (booster_status == WITHDRAW_DECLINED)
          return (
            <Tag color="red">
              <Space>
                <CloseCircleFilled />
                <b>Declined</b>
              </Space>{" "}
            </Tag>
          );
        return (
          <Tag color="red">
            <Space>
              <CloseCircleFilled />
              <b>Declined</b>
            </Space>{" "}
          </Tag>
        );
      },
    },
  ];

  return (
    <>
      <ModalAdminReason modalState={modalState} closeModal={closeModal} />
      <div className={styles.searchContainer}>
        <Search
          placeholder="Search"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
      </div>
      <StyledTable
        pagination={paginationSettings}
        onChange={handleTableChange}
        columns={columns}
        dataSource={discounts}
        loading={loading}
      />
      <ModalPaymentMethodInfo
        modalState={modalStatePayments}
        closeModal={closeModalPayments}
      />
    </>
  );
};

export default WithdrawRequests;
