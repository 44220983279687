import usersAPI from "../../../http/users";
import { notifyError, notifySuccess } from "../../../utils/notifications";
import { useEffect, useMemo, useState } from "react";
import { Button, Popconfirm, Space, Table } from "antd";
import Search from "antd/es/input/Search";
import { debounce } from "lodash";
import moment from "moment";
import { DollarCircleFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import PurchaseReviewModal from "./Modals/PurchaseReviewModal";
import ModalOrderInfo from "../../Sales/Modals/ModalOrderInfo";
import ModalAllOrderInfos from "../../Sales/Modals/ModalAllOrderInfos";
import ModalActiveOrderInfo from "../../Sales/Modals/ModalActiveOrderInfo";
import { StyledTable } from "../Users";

const CustomOrders = ({ userId }) => {
  const [data, setData] = useState([]);
  const [modalState, setModalState] = useState({
    isVisible: false,
    item: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const getList = async () => {
    try {
      setIsLoading(true);
      const res = await usersAPI().purchasedCustomOrders(
        userId,
        searchKeyWord,
        params
      );
      setData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      console.log({ res }, "regular orders");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notifyError(e?.response?.data);
    }
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
    getList();
  }, [searchKeyWord, params]);

  const viewDetailsModal = (data) => {
    setModalState({ ...modalState, isVisible: true, item: data });
  };

  const closeModal = (data) => {
    setModalState({ isVisible: false });
  };

  const acceptOrder = async (data) => {
    console.log(data);
    try {
      const res = await usersAPI().handleCustomOrder(userId, data.id, {
        status: 1,
      });
      getList();
      notifySuccess("Order is accepted.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };
  const declineOrder = async (data) => {
    console.log(data);
    try {
      const res = await usersAPI().handleCustomOrder(userId, data.id, {
        status: 3,
      });
      getList();
      notifySuccess("Order is declined.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };

  const columns = [
    {
      title: "Order info",
      key: "name",
      width: 280,

      render: (data) => {
        return (
          <Space direction="vertical">
            <Space>
              <b>Game:</b>
              <span> {data?.game?.name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b>Service:</b>
              <span> {data?.game_name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b> Date:</b>
              <span> {moment(data.created).format("DD MMM YYYY")}</span>
            </Space>{" "}
            <Space>
              <b> By:</b>
              <span>
                {" "}
                {data.buyer?.first_name + " " + data?.buyer?.last_name ?? "/"}
              </span>
            </Space>{" "}
            <Space>
              <b> Price:</b>
              {data?.transaction?.amount ? (
                <Space>
                  {" "}
                  <DollarCircleFilled />
                  <span>{data?.transaction?.amount ?? "/"}</span>
                </Space>
              ) : (
                "/"
              )}
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Offer",
      key: "offer",
      render: (data) => {
        return (
          <Space
            direction={"vertical"}
            align={"center"}
            style={{ width: "100%" }}
          >
            <b>{data?.offer_name ?? "/"}</b>
            <Space>
              <Button onClick={() => viewDetailsModal(data)}>
                Order Info{" "}
              </Button>
              <Popconfirm
                title="Are you sure to accept this order?"
                onConfirm={() => acceptOrder(data)}
                okText="Yes"
                cancelText="No"
              >
                <Button type={"primary"}>Accept</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure to decline this order?"
                onConfirm={() => declineOrder(data)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Decline</Button>
              </Popconfirm>
            </Space>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {/*<PurchaseReviewModal modalState={modalState} closeModal={closeModal} />*/}
      <ModalActiveOrderInfo modalState={modalState} closeModal={closeModal} />
      <StyledSearch
        placeholder="Search orders"
        size="large"
        allowClear
        loading={false}
        onChange={debouncedChangeHandler}
      />

      <StyledTable
        pagination={paginationSettings}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default CustomOrders;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #f1f1f6;
  border: solid 1px #e0e5ec;
  border-radius: 6px;
  height: 58px;
  width: 100%;
`;

const StyledSearch = styled(Search)`
  width: 300px;
  margin: 10px 0;
`;
