import { notifyError } from "./notifications";

function parseErrors(error) {
  console.dir(error);
  console.log(error);
  if (error?.code == "validationError") {
    let parsedErrorMessage = JSON.parse(error.errors[0].message);
    let text = "";
    parsedErrorMessage[0].fields.map((field, iterator) => {
      let sign =
        iterator + 1 == parsedErrorMessage[0].fields.length ? " " : ", ";
      text = text + (field.toUpperCase() + sign);
    });
    notifyError(
      <span>
        {text}
        <br />
        {parsedErrorMessage[0].reason}
      </span>
    );
  }else {
    notifyError(
      error?.response?.data?.error_description ??
        "There is a problem with this action, please try again."
    );
  }
}

export default parseErrors;
