import { Button, Select, Skeleton, Space, Table, Tag, Tooltip } from "antd";
import styles from "./Transaction.module.css";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import parseErrors from "../../utils/parseServerError";
import usersAPI from "../../http/users";
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  DollarCircleFilled,
  EuroCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import ModalViewOffer from "./Modals/ModalViewOffer";
import transactionsAPI from "../../http/transactions";
import { notifyError, notifySuccess } from "../../utils/notifications";
import { StyledTable } from "./Users";

const Transactions = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [spendingInfo, setSpendingInfo] = useState(null);
  const [orderInfoState, setOrderInfoState] = useState({
    item: null,
    isVisible: false,
  });
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    ordering: null,
  });

  const closeOrderInfo = () => {
    setOrderInfoState({ item: null, isVisible: false });
  };
  const openOrderInfo = (item) => {
    setOrderInfoState({ ...orderInfoState, item, isVisible: true });
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  const getListOfUserTransactions = async () => {
    try {
      setLoading(true);
      const res = await usersAPI().getTransactions(
        user?.id,
        searchKeyWord,
        params
      );
      setTransactions(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.dir(e);
      parseErrors(e?.response?.data);
    }
  };

  const getCashInfo = async () => {
    try {
      setLoading(true);
      const res = await usersAPI().getCashInfo(user?.id);
      console.log({ res });
      setSpendingInfo(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      parseErrors(e?.reponse?.data);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getCashInfo();
    }
  }, [user]);

  useEffect(() => {
    if (user?.id) {
      getListOfUserTransactions();
    }
  }, [searchKeyWord, user, params]);

  console.log({ transactions });

  const statusText = [
    {
      text: "Founds pending, waiting for order to be complete",
      color: "grey",
      icon: <ClockCircleFilled />,
    },
    {
      text: "Order complete, founds are now available for withdrawal",
      color: "green",
      icon: <CheckCircleFilled />,
    },
    { text: "Withdrawal pending", color: "grey", icon: <ClockCircleFilled /> },
    {
      text: "Withdrawal successful",
      color: "green",
      icon: <CheckCircleFilled />,
    },
    {
      text: "Withdrawal declined",
      color: "red",
      icon: <CloseCircleFilled />,
    },
    {
      text: "Order refund pending approval ",
      color: "grey",
      icon: <ClockCircleFilled />,
    },
    {
      text: "Order refund complete  ",
      color: "green",
      icon: <CheckCircleFilled />,
    },
    {
      text: "Refund declined  ",
      color: "red",
      icon: <CloseCircleFilled />,
    },
    {
      text: "Purchase successful  ",
      color: "green",
      icon: <CheckCircleFilled />,
    },
  ];

  const renderPurchase = (purchase) => {
    return (
      <Space direction={"vertical"}>
        <Tag color={statusText[8].color}>
          <Space>
            {statusText[8].icon}
            <b>{statusText[8].text} </b>
          </Space>
        </Tag>
        <Space>
          <span>
            Order ID: <b>{purchase?.order?.id ?? "/"}</b>
          </span>
          <span
            className={styles["clickable"]}
            onClick={() => openOrderInfo(purchase.order)}
          >
            (View order)
          </span>
        </Space>
      </Space>
    );
  };

  const renderAcceptedOrder = (order) => {
    console.log({ acceptedOrder: order });
    let statusNumber = 0;
    if (order.status > 4) {
      statusNumber = 1;
    }

    return (
      <Space direction={"vertical"}>
        <Tag color={statusText[statusNumber].color}>
          <Space>
            {statusText[statusNumber].icon}
            <b>{statusText[statusNumber].text} </b>
          </Space>
        </Tag>
        <Space>
          <span>
            Order ID: <b>{order?.order?.id ?? "/"}</b>
          </span>
          <span
            className={styles["clickable"]}
            onClick={() => openOrderInfo(order.order)}
          >
            (View order)
          </span>
        </Space>
      </Space>
    );
  };

  const handleWithdrawStatusChange = async (item, status) => {
    try {
      const res = await transactionsAPI().editWithdraw(
        { booster_status: status },
        item.id
      );
      getListOfUserTransactions();
      if (status == 1) {
        notifySuccess("Thank you for confirmation of withdraw.");
      } else {
        notifySuccess("Withdraw is declined.");
      }
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };
  const handleRefundStatusChange = async (item, data) => {
    try {
      const res = await transactionsAPI().editRefund({ ...data }, item.id);
      getListOfUserTransactions();
      notifySuccess("Thank you for answering.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };

  const renderWithdrawal = (withdrawal) => {
    let statusNumber;
    if (withdrawal.status == 0) {
      statusNumber = 2;
    } else if (withdrawal.status == 1) {
      statusNumber = 3;
    } else {
      statusNumber = 4;
    }
    return (
      <Space direction={"vertical"}>
        <Tag color={statusText[statusNumber].color}>
          <Space>
            {statusText[statusNumber].icon}
            <b>{statusText[statusNumber].text} </b>
          </Space>
        </Tag>
        <Space>
          <span
            className={styles["clickable"]}
            onClick={() => openOrderInfo(withdrawal.order)}
          >
            (View info)
          </span>
          <span>
            <b>{withdrawal?.method?.toUpperCase() ?? "/"}</b>
          </span>
        </Space>
        {withdrawal.status == 1 && withdrawal?.booster_status == 0 && (
          <Space>
            <span>Please confirm your withdraw is successfully completed</span>
            <Button
              type="round"
              color="primary"
              onClick={() => handleWithdrawStatusChange(withdrawal, 1)}
            >
              Confirm
            </Button>
            <Button
              danger
              type="round"
              onClick={() => handleWithdrawStatusChange(withdrawal, 2)}
            >
              Declined
            </Button>
            <Tooltip title={"If your withdraw is completed, please approve it"}>
              <InfoCircleFilled />
            </Tooltip>
          </Space>
        )}
      </Space>
    );
  };

  const renderRefund = (refund) => {
    let statusNumber;
    if (refund.status == 0) {
      statusNumber = 5;
    } else if (refund.status == 1) {
      statusNumber = 6;
    } else {
      statusNumber = 7;
    }

    console.log({ refund });

    let role = refund.role;
    let condition = refund.status == 1 && refund?.buyer_status == 0;
    if (role == "booster") {
      condition = refund.status == 1 && refund?.booster_status == 0;
    }
    return (
      <Space direction={"vertical"}>
        <Tag color={statusText[statusNumber].color}>
          <Space>
            {statusText[statusNumber].icon}
            <b>{statusText[statusNumber].text} </b>
          </Space>
        </Tag>
        <Space>
          <span>
            Order ID: <b>{refund?.order?.id ?? "/"}</b>
          </span>
          <span
            className={styles["clickable"]}
            onClick={() => openOrderInfo(refund.order)}
          >
            (View order)
          </span>
        </Space>
        {condition && (
          <Space>
            <span>Please confirm your refund is successfully completed</span>
            <Button
              type="round"
              color="primary"
              onClick={() =>
                handleRefundStatusChange(
                  refund,
                  role == "booster"
                    ? { booster_status: 1 }
                    : { buyer_status: 1 }
                )
              }
            >
              Confirm
            </Button>
            <Button
              danger
              type="round"
              onClick={() =>
                handleRefundStatusChange(
                  refund,
                  role == "booster"
                    ? { booster_status: 2 }
                    : { buyer_status: 2 }
                )
              }
            >
              Declined
            </Button>
            <Tooltip title={"If your withdraw is completed, please approve it"}>
              <InfoCircleFilled />
            </Tooltip>
          </Space>
        )}
      </Space>
    );
  };

  const renderPrice = (item) => {
    if (item.transaction_type == "accepted_order") {
      return (
        <Space>
          <DollarCircleFilled />
          <b>{item?.booster_price_usd ?? 0}</b>
        </Space>
      );
    }
    return (
      <Space>
        {item.currency_symbol == "$" ? (
          <DollarCircleFilled />
        ) : (
          <EuroCircleFilled />
        )}
        <b>{item.amount}</b>
      </Space>
    );
  };
  const columns = [
    {
      title: "Date",
      key: "date",
      render: (data) => {
        return moment(data?.created).format("DD MMM YYYY  hh:mm A");
      },
    },
    {
      title: "For",
      key: "For",
      render: (data) => {
        console.log("Anes");
        console.log(data);
        if (data?.transaction_type == "purchased_order") {
          return renderPurchase(data);
        }
        if (data?.transaction_type == "withdraw") {
          return renderWithdrawal(data);
        }
        if (data?.transaction_type == "refund") {
          return renderRefund(data);
        }
        if (data?.transaction_type == "accepted_order") {
          return renderAcceptedOrder(data);
        }
        return (
          <Space direction={"vertical"}>
            <b>No data.</b>
          </Space>
        );
      },
    },
    {
      title: "Amount",
      key: "Amount",
      render: (data) => renderPrice(data),
    },
  ];

  return (
    <Space direction="vertical" size="large" className={styles["container"]}>
      <ModalViewOffer modalState={orderInfoState} closeModal={closeOrderInfo} />
      <div className={styles["transaction-container"]}>
        <Space direction="vertical" className={styles["transaction-space"]}>
          {loading ? (
            <Skeleton.Image />
          ) : (
            <>
              <span className={styles["transaction-subtitle"]}>Net income</span>
              <b className={styles["transaction-value"]}>
                {" "}
                {spendingInfo?.net_income?.total_usd ?? 0} $
              </b>
            </>
          )}
        </Space>{" "}
        <Space direction="vertical" className={styles["transaction-space"]}>
          {loading ? (
            <Skeleton.Image />
          ) : (
            <>
              <span className={styles["transaction-subtitle"]}>Withdrawn</span>
              <b className={styles["transaction-value"]}>
                {spendingInfo?.withdrawn?.total_usd ?? 0} $
              </b>
            </>
          )}
        </Space>{" "}
        <Space direction="vertical" className={styles["transaction-space"]}>
          {loading ? (
            <Skeleton.Image />
          ) : (
            <>
              <span className={styles["transaction-subtitle"]}>
                Used for Purchases
              </span>
              <b className={styles["transaction-value"]}>
                {spendingInfo?.purchased?.total_usd ?? 0} $
              </b>
            </>
          )}
        </Space>{" "}
        <Space direction="vertical" className={styles["transaction-space"]}>
          {loading ? (
            <Skeleton.Image />
          ) : (
            <>
              <span className={styles["transaction-subtitle"]}>
                Pending approval
              </span>
              <b className={styles["transaction-value"]}>
                {" "}
                {spendingInfo?.pending_income?.total_usd ?? 0} $
              </b>
            </>
          )}
        </Space>{" "}
        <Space direction="vertical" className={styles["transaction-space"]}>
          {loading ? (
            <Skeleton.Image />
          ) : (
            <>
              <span className={styles["transaction-subtitle"]}>
                Available for Withdrawn
              </span>
              <b className={styles["transaction-value"]}>
                {spendingInfo?.balance?.total_usd ?? 0} $
              </b>
            </>
          )}
        </Space>
      </div>
      <Space direction={"vertical"} className={styles["container"]}>
        {/*<Search*/}
        {/*  placeholder="Search transactions"*/}
        {/*  size="large"*/}
        {/*  allowClear*/}
        {/*  loading={false}*/}
        {/*  onChange={debouncedChangeHandler}*/}
        {/*  style={{ width: "50%" }}*/}
        {/*/>*/}
        <Space>
          <b>Order by: </b>
          <Select
            style={{ width: "100%" }}
            value={params?.ordering}
            onChange={(value) => {
              if (value) {
                setParams((prevState) => {
                  return { ...params, ordering: "-created" };
                });
              } else {
                setParams((prevState) => {
                  return { ...prevState, ordering: null };
                });
              }
            }}
          >
            <Select.Option value={"-created"}>New transactions</Select.Option>
            <Select.Option value={null}>Old transactions</Select.Option>
          </Select>
        </Space>
        <StyledTable
          pagination={paginationSettings}
          onChange={handleTableChange}
          columns={columns}
          loading={loading}
          dataSource={transactions}
        />
      </Space>
    </Space>
  );
};

export default Transactions;
