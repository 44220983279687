import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { notifySuccess } from "../../../utils/notifications";
import parseErrors from "../../../utils/parseServerError";
import offersApi from "../../../http/offers";
import AddOfferOption from "./AddOfferOption/AddOfferOption";
import TextEditor from "../../TextEditor/TextEditor";

const { Item, useForm } = Form;

const ModalAddOffer = ({
  modalState,
  closeModal,
  refreshData,
  products,
  serviceId,
  serviceType,
  gameId,
}) => {
  const [form] = useForm();
  const formRef = useRef();

  const [options, setOptions] = useState([]);
  const [quillData, setQuillData] = useState("");

  useEffect(() => {
    if (modalState.item) {
      setOptions(modalState.item.offer_options);
    }
    form.resetFields();
  }, [modalState]);

  const onFinish = (values) => {
    if (modalState?.item) {
      editOffer(
        {
          ...modalState?.item,
          ...values,
          status: values.status ? 1 : 0,
          offer_options: options,
        },
        modalState?.item?.id
      );
    } else {
      addNewOffer({
        ...values,
        service: serviceId,
        offer_options: options,
        status: values.status ? 1 : 0,
        game: gameId,
      });
    }
  };

  const formatSelectData = (items) => {
    const result = items.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    return result;
  };

  const memoizedProducts = useMemo(
    () => formatSelectData(products),
    [products]
  );

  const addNewOffer = async (offerData) => {
    try {
      const dataForRequest = {
        ...offerData,
        offer_name: offerData.name,
        offer_description: offerData.description,
      };
      delete dataForRequest.name;
      delete dataForRequest.description;
      await offersApi().addOffer(dataForRequest);
      refreshData();
      cleanData();
      notifySuccess("New offer added.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };
  const editOffer = async (offerData, id) => {
    console.log({ offerData, id });
    try {
      await offersApi().editOffer(offerData, id);
      refreshData();
      closeModal();
      notifySuccess("Offer modified.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const cleanData = () => {
    setOptions([]);
    form.resetFields();
    closeModal();
  };

  useEffect(() => {
    form.resetFields();
  }, [modalState]);
  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState?.item ? "Edit offer" : "Add offer"}
      onOk={() => console.log("OK")}
      onCancel={cleanData}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          {modalState?.item ? "Edit offer" : "Add offer"}
        </Button>,
      ]}
    >
      <Form
        initialValues={modalState.item}
        ref={formRef}
        form={form}
        layout="vertical"
        shouldUpdate
        onFinish={onFinish}
      >
        <Item
          name="name"
          label="Offer name"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input offer name!" }]}
        >
          <Input placeholder="Enter offer name" />
        </Item>{" "}
        <Item name="description" label="Offer description">
          <TextEditor placeholder="Enter description" />
        </Item>
        {serviceType == 0 && (
          <Item
            shouldUpdate
            name="product"
            label="Choose product for this offer"
            required
            tooltip="This is a required field"
            rules={[{ required: true, message: "Please choose products!" }]}
          >
            <Select>
              {memoizedProducts.map((item) => {
                return (
                  <Select.Option value={item.value}>{item.label}</Select.Option>
                );
              })}
            </Select>
          </Item>
        )}
        <label>
          <Tooltip
            title={"This field is required and will be included in payments"}
          >
            <sup style={{ color: "red", fontSize: "10px" }}>* </sup>Options
          </Tooltip>
        </label>
        <AddOfferOption options={options} setOptions={setOptions} />
        <Item name="usd_price" label="Offer price">
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Enter starting price"
            addonAfter="$"
            min={0}
          />
        </Item>
        <Item name="sale" label="Offer sale">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            max={100}
            placeholder="Enter sale amount"
            addonAfter="$"
          />
        </Item>
        <Item
          name="status"
          label="Status"
          tooltip="This field is for offer activation"
          initialValue={modalState?.item?.status ?? false}
        >
          <Switch
            defaultChecked={Boolean(modalState?.item?.status) ?? false}
          ></Switch>
        </Item>
      </Form>
    </Modal>
  );
};

export default ModalAddOffer;
