import React, { useState } from 'react';
import { FaPlusSquare } from 'react-icons/fa'; // Using Font Awesome icon
import upload_img from "../../assets/icons/plus-icon.png"

const ImageUploadButton = ({upload_image}) => {
  const [image, setImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      upload_image(URL.createObjectURL(file))
    }
  };


  return (
    <div style={styles.container}>
      <label htmlFor="image-upload" style={styles.uploadLabel}>
        {image ? (
          <img width={20} height={20} src={image} alt="Uploaded" style={styles.image} />
        ) : (
        //   <FaPlusSquare style={styles.icon} />
         <img id='upload_img' width={20} src={upload_img} alt='upload_img'></img>
        )}
      </label>
      <input
        id="image-upload"
        type="file"
        accept="image/*"
        style={styles.input}
        onChange={handleImageUpload}
      />
      {/* <div style={styles.label}>Service Image</div> */}
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    width: '100px',
    height: '100px',
    backgroundColor: '#052634', // Dark background
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  uploadLabel: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  icon: {
    color: '#6b8b9e', // Light blue/gray icon color
    fontSize: '40px',
  },
  input: {
    display: 'none',
  },
  image: {
    width: '50%',
    height: '50%',
    objectFit: 'contain',
    borderRadius: '12px',
  },
  label: {
    position: 'absolute',
    top: '-25px',
    color: '#b0bec5', // Light gray text color
    fontSize: '16px',
  },
};

export default ImageUploadButton;
