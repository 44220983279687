import { getApiClient } from "../client";

function methodsAPI() {
  return {
    addMethod: (updatedMethodData) =>
      getApiClient().post("/admin/withdraw/method", updatedMethodData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    editMethod: (updatedMethodData, methodId) =>
      getApiClient().put(
        `/admin/withdraw/method/${methodId}`,
        updatedMethodData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      ),
    deleteMethod: (methodId) =>
      getApiClient().delete(`/admin/withdraw/method/${methodId}`),
    getMethodList: (search, rules) =>
      getApiClient().get("/withdraw/method/list", {
        params: { search, ...rules },
      }),
  };
}

export default methodsAPI;
