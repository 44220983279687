import { getApiClient } from "../client";

function gamesAPI() {
  return {
    addGame: (gameData) => getApiClient().post("/admin/game", gameData),
    editGame: (updatedGameData, gameId) =>
      getApiClient().put(`/admin/game/${gameId}`, updatedGameData),
    editPopularGame: (updatedGameData, popularGameId) =>
      getApiClient().put(
        `/admin/game/edit/popular/games/${popularGameId}`,
        updatedGameData
      ),
    getGameList: (search, rules) =>
      getApiClient().get("/admin/games", { params: { search, ...rules } }),
    getGameDetails: (gameId) => getApiClient().get(`/admin/game/${gameId}`),
    deleteGame: (gameId) => getApiClient().delete(`/admin/game/${gameId}`),
    getGameServices: (gameId) =>
      getApiClient().get(`/admin/game/${gameId}/services`),
    getGamesByChar: (gameId) => getApiClient().get(`/games/chars`),
    getPopularGames: (search, rules) =>
      getApiClient().get(`/admin/games/popular`, { params: { search, ...rules } }),
    editServiceInGameDetails: (gameId, serviceId, data) =>
      getApiClient().put(`/admin/game/${gameId}/service/${serviceId}/`, data),
    getServiceInGameDetails: (gameId, serviceId) =>
      getApiClient().get(`/admin/game/${gameId}/service/${serviceId}/`),
  };
}

export default gamesAPI;
