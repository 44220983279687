import Image from "../../components/Login/Image";
import styles from "./Login.module.css";
import forget from "../../assets/icons/Account-Settings.png"

import { Button, Form, Input } from "antd";
import { UnlockOutlined, UserOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";

const { useForm, Item } = Form;
const Login = ({ handleLogin, loading }) => {
  const [form] = useForm();
  const formRef = useRef();

  return (
    <div className={styles.container} id="login_page">
      <div className="absolute bg-[#052534de] z-5 w-[100vw] h-[100vw] top-0"></div>
      <div className="relative flex flex-col items-center justify-center w-[70vw] h-[90%] bg-[#042634c4] rounded-lg">
        <div className="flex justify-center items-center gap-10 h-[80%]">
          <div className="flex justify-end items-center w-[30vw] h-[80%]">
            <Image />
          </div>
          <div className="h-[90%] w-[1px] bg-[#017371]"></div>
          <div className="w-[29vw] h-[80%] flex flex-col justify-center ">
            <h1 className="text-white font-semibold text-4xl text-left">
              Admin Panel
            </h1>
            <Form
              ref={formRef}
              layout="vertical"
              form={form}
              className={styles.dataInput}
              onFinish={(values) => {
                console.log({ values });
                handleLogin(values);
              }}
              size="large"
            >
              <Item
                name="username"
                label="EMAIL ADDRESS"
                className={styles.item}
                required
                // tooltip="This is a required field"
                // rules={[{ required: true, message: "Please input username!" }]}
              >
                <Input
                  size="large"
                  placeholder="Email Address"
                  // prefix={<UserOutlined />}
                />
              </Item>
              <Item
                className={styles.item}
                name="password"
                label="PASSWORD"
                required
                // tooltip="This is a required field"
                rules={[{ required: true, message: "Please input password" }]}
              >
                <Input
                  size="large"
                  placeholder="Password"
                  // prefix={<UnlockOutlined />}
                  type="password"
                />
              </Item>
              <div className="flex gap-5 py-2">
                <input type="checkbox"></input><span className="text-white font-semibold">Remember Me</span>
              </div>
              <div className="flex gap-5 py-2">
                <img src={forget} alt="forget"></img><button className="text-white font-semibold" onClick={()=>alert("Api Not Connected")}>Forget your password?</button>
              </div>
              <Button
                size="large"
                type="primary"
                className={styles.button}
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Login
              </Button>
            </Form>
          </div>
        </div>
        <div className="flex items-end h-[10%]">
        <span className="text-white">&#169; 2024 masterlot. All rights reserved</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
