import { Button, Input, Popconfirm, Space, Table } from "antd";
import { debounce } from "lodash";
import {
  DeleteOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DollarTwoTone,
  MoreOutlined,
  PercentageOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import discountsApi from "../../http/discounts/";
import moment from "moment";
import ModalModifyDiscount from "./Modals/ModalModifyDiscount";
import styles from "./Discounts.module.css";
import gamesAPI from "../../http/games";
import { notifySuccess } from "../../utils/notifications";
import parseErrors from "../../utils/parseServerError";
import { StyledTable } from "../Users/Users";

const { Search } = Input;

const initialModalState = {
  isVisible: false,
  item: null,
  title: "Add new discount",
};

const Discount = () => {
  const [discounts, setDiscounts] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };

  const getListOfDiscounts = async (searchKeyWord = "", params) => {
    try {
      const res = await discountsApi().getDiscountsList(searchKeyWord, params);
      setDiscounts(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      console.log({ res });
    } catch (e) {
      console.dir(e);
    }
  };

  const addNewDiscount = () => {
    setModalState({ ...initialModalState, visible: true });
  };

  const editDiscount = (discount) => {
    setModalState({
      ...initialModalState,
      visible: true,
      item: discount,
      title: "Edit discount",
    });
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
    getListOfDiscounts(searchKeyWord, params);
  }, [searchKeyWord, params]);

  const handleDelete = async (item) => {
    try {
      const { discount_code } = item;
      await discountsApi().deleteDiscount(discount_code);
      getListOfDiscounts(searchKeyWord, params);
      notifySuccess(`${item?.name}  is deleted.`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const columns = [
    {
      title: "Code",
      key: "discount_code",
      dataIndex: "discount_code",
      render: (data) => {
        return <b>{data}</b>;
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Discount",
      key: "discount",
      render: (data) => {
        const { discount_type, discount_value } = data;
        if (discount_type == 0) {
          return (
            <span>
              {discount_value} <PercentageOutlined />{" "}
            </span>
          );
        }
        return (
          <span>
            {discount_value} <DollarOutlined />
          </span>
        );
      },
    },
    {
      title: "Start date",
      key: "start_date",
      dataIndex: "start_date",
      render: (date) => {
        return (
          <span className={styles.start_date}>
            {moment(date).format("D MMM YYYY")}{" "}
          </span>
        );
      },
    },
    {
      title: "End date",
      key: "end_date",
      dataIndex: "end_date",
      render: (date) => {
        return (
          <span className={styles.end_date}>
            {moment(date).format("D MMM YYYY")}{" "}
          </span>
        );
      },
    },
    {
      title: "Max using limit",
      key: "max_limit",
      dataIndex: "max_limit",
    },
    {
      title: "Times used",
      key: "times_used",
      dataIndex: "times_used",
    },
    {
      title: "Actions",
      key: "edit",
      render: (discount) => {
        return (
          <Space>
            <Button
              type={"primary"}
              onClick={() => editDiscount(discount)}
              icon={<MoreOutlined />}
            >
              View details
            </Button>
            <Popconfirm
              title={"Are you sure to delete " + discount.name + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => handleDelete(discount)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <ModalModifyDiscount
        modalState={modalState}
        closeModal={closeModal}
        refreshData={() => getListOfDiscounts(searchKeyWord, params)}
      />
      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search discounts"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />

        <Button
          shape={"round"}
          onClick={() => addNewDiscount()}
          size={"large"}
          icon={<PlusSquareOutlined />}
        >
          Add discount
        </Button>
      </div>
      <StyledTable
        pagination={paginationSettings}
        onChange={handleTableChange}
        columns={columns}
        dataSource={discounts}
      />
    </>
  );
};

export default Discount;
