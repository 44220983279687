import { Button, List, Modal } from "antd";

const ModalActiveOrderInfo = ({ modalState, closeModal }) => {
  if (modalState.item == null) return <></>;

  console.log(modalState.item);
  console.log("Anes");

  const { game, offer_name, service, user, id, buyer } = modalState.item;
  return (
    <Modal
      width={1080}
      footer={[
        <Button type="round" key="back" onClick={closeModal}>
          Close order details
        </Button>,
      ]}
      onCancel={closeModal}
      visible={modalState.isVisible}
      title={"Viewing order details"}
    >
      <List size="large">
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Order ID:</b>
            <span>{id} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Game:</b>
            <span>{game?.name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Service:</b>
            <span>{service?.name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Offer:</b>
            <span>{offer_name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Seller:</b>
            <span>
              {user?.first_name.concat(" ".concat(user?.last_name ?? ""))}
            </span>
          </div>
        </List.Item>{" "}
                <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Buyer:</b>
            <span>
              {buyer?.first_name.concat(" ".concat(buyer?.last_name ?? ""))}
            </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Buyer email:</b>
            <span>
              {buyer?.email}
            </span>
          </div>
        </List.Item>{" "}
                <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Buyer username:</b>
            <span>
              {buyer?.username}
            </span>
          </div>
        </List.Item>{" "}
      </List>
    </Modal>
  );
};
export default ModalActiveOrderInfo;
