// Withdraws
export const WITHDRAW_PENDING = 0;
export const WITHDRAW_ACCEPTED = 1;
export const WITHDRAW_DECLINED = 2;
export const WITHDRAW_ADMIN_CONFIRMED = 3;
//Refunds
export const REFUND_PENDING = 0;
export const REFUND_ACCEPTED = 1;
export const REFUND_DECLINED = 2;
export const REFUND_COMPLETED = 3;
//Payments
export const PAYMENT_NOT_PAID = 0;
export const PAYMENT_PAID = 1;
//Contact methods
export const CONTACT_METHODS = [
  "Email",
  "Skype",
  "Discord",
  "Viber",
  "WhatsApp",
  "Facebook's Messenger",
  "Telegram"
];

//Order status
export const REGULAR_ORDER_STATUSES = [
  "Finding Player",
  "Preparing to start",
  "Order Started",
  "Order Finished",
  "Order Completed",
  "Order Cancel Requested",
  "Order Canceled",
];

export const REGULAR_ORDER_STATUSES_COLORS = [
  "#888888",
  "#ffab5a",
  "#1aa2f9",
  "#1aa2f9",
  "#22c58b",
  "#ec3844",
  "#ec3844",
];

export const STATUS_PENDING_USER = 0;
export const STATUS_CONFIRMED_USER = 1;
export const STATUS_DECLINED_USER = 2;
export const STATUS_RESENT_USER = 3;
export const STATUS_ADMIN_CONFIRMED_USER = 4;

//React-quill modules
export const REACT_QUILL_MODULES = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] , 'font': []  }],
      [{size: ['small', false, 'large', 'huge']}], 
      [{ 'color': [] }, { 'background': [] }],
      ["bold", "italic", "underline"],``
      [{'list': 'ordered'}, {'list': 'bullet'}, 
        {'indent': '-1'}, {'indent': '+1'}],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
      ['table']
    ]
    ,
    handlers: {
      table: function() {
        const quill = this.quill;
        const tableModule = quill.getModule('better-table');
        tableModule.insertTable(3, 3);  // Inserts a 3x3 table
      }
    }
    },
  
  // 'better-table': {
  //     operationMenu: {
  //       items: {
  //         insertRowBelow: true,
  //         insertRowAbove: true,
  //         insertColumnRight: true,
  //         insertColumnLeft: true,
  //         deleteRow: true,
  //         deleteColumn: true,
  //         deleteTable: true,
  //       },
  //       color: {
  //         colors: ['red', 'green', 'yellow', 'blue', 'white'],
  //         text: 'Cell color: ',
  //       }
  //     }
  //   }
    
};
