import { getApiClient } from "../client";

function offersApi() {
  return {
    getOffers: (gameId, serviceId, limit=20, offset, search='') => getApiClient().get(`/admin/game/${gameId}/service/${serviceId}/offers?limit=${limit}&offset=${offset}&search=${search}&ordering=-updated`),
    addOffer: (offerData) => getApiClient().post("/admin/offer", offerData),
    editOffer: (updatedOfferData, offerId) =>
      getApiClient().put(`/admin/offer/${offerId}`, updatedOfferData),
    getOffersList: () => getApiClient().get("/admin/offers"),
    getOfferDetails: (offerId) => getApiClient().get(`/admin/offer/${offerId}`),
    deleteOffer: (offerId) => getApiClient().delete(`/admin/offer/${offerId}`),
  };
}

export default offersApi;
