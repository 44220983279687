import { getApiClient } from "../client";

function authAPI() {
  return {
    login: (data) =>
      getApiClient().post("/auth/token", {
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        ...data,
      }),
    getRefreshToken: (data) =>
      getApiClient().post("/auth/token", {
        grant_type: "refresh_token",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        ...data,
      }),
  };
}

export default authAPI;
