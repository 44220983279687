import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  Tooltip,
} from "antd";
import ReactQuill from "react-quill"; // ES6
import styles from "../GameServiceDetails.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import servicesAPI from "../../../http/services";
import { notifySuccess } from "../../../utils/notifications";
import parseServerError from "../../../utils/parseServerError";
import categoriesAPI from "../../../http/categories";
import Title from "antd/es/typography/Title";
import productsAPI from "../../../http/products";
import { REACT_QUILL_MODULES } from "../../../utils/constants";

const { Item, useForm } = Form;
const { Group } = Checkbox;
const { Option } = Select;

const ModalAddProduct = ({
  modalState,
  closeModal,
  refreshData,
  gameId,
  serviceId,
}) => {
  const [form] = useForm();
  const formRef = useRef();
//  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [quillData, setQuillData] = useState("");

//  const loadServicesList = async () => {
//    try {
//      const res = await servicesAPI().getServicesList();
//      setServices(() => res.data);
//    } catch (e) {
//      console.dir(e);
//    }
//  };

  const onFinish = (values) => {
    if (modalState.item) {
      console.log("Edit");
      modifyProduct({ ...values, description: quillData });
    } else {
      if (!values.category) {
        values = {
          ...values,
          service: serviceId,
          category: null,
          game: gameId,
        };
      } else {
        values = { ...values, service: null };
      }
      addNewProduct({ ...values, description: quillData });
      debugger;
    }
  };

  const loadCategoriesList = async () => {
    try {
      const res = await categoriesAPI().getCategoryList(gameId, serviceId);
      setCategories(
        res.data
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
//    loadServicesList();
    loadCategoriesList();
  }, [modalState]);

  const formatDataForSelect = (categories) => {
    const result = categories.map((category) => {
      return {
        label: category.name,
        value: category.id,
      };
    });
    return result;
  };

  const memoizedCategories = useMemo(
    () => formatDataForSelect(categories),
    [categories]
  );

  const addNewProduct = async (productData) => {
    try {
      await productsAPI().addProduct(productData);
      refreshData();
      closeModal();
      notifySuccess("New product added.");
    } catch (e) {
      parseServerError(e.response.data);
    }
  };

  const modifyProduct = async (productData) => {
    try {
      await productsAPI().editProduct(productData, modalState?.item?.id);
      refreshData();
      closeModal();
      notifySuccess("Product modified.");
    } catch (e) {
      parseServerError(e.response.data);
    }
  };

  const handleDataFromQuill = (data) => {
    setQuillData(data);
  };

  useEffect(() => {
    form.resetFields();
    if (modalState?.item) {
      setQuillData(modalState?.item?.description);
    } else {
      setQuillData("");
    }
  }, [modalState]);

  let initialValues = {
    ...modalState.item,
    category: modalState?.item?.category?.id,
  };
  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState.title}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          {modalState?.item ? "Edit product" : "Add product"}
        </Button>,
      ]}
    >
      <Form
        initialValues={initialValues}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Title level={4}>
          {modalState?.item ? "Edit product" : "Add product"}
        </Title>
        <Item
          name="name"
          label="Product name"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input game name!" }]}
        >
          <Input placeholder="Enter product name" />
        </Item>
        <Item
          name="top_description_title"
          label="Main title"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Main title!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="top_description"
          label="Main text"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Main text!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="page_heading_title"
          label="Subtitle"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Subtitle!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="page_heading"
          label="Subtitle text"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Subtitle text!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item name="category" label="Choose category for this product">
          <Select optionFilterProp="children" showArrow={true}>
            {memoizedCategories.map((option) => {
              return <Option value={option.value}>{option.label}</Option>;
            })}
          </Select>
        </Item>
        <label>
          <Tooltip title={"This field is required"}>
            <b style={{ color: "red", fontSize: "12px" }}>*</b> Description
          </Tooltip>
        </label>
        <div className={styles.reactquill}>
          <ReactQuill
            value={quillData}
            onChange={handleDataFromQuill}
            theme="snow"
            modules={REACT_QUILL_MODULES}
          />
        </div>
        <Item
          name="section_card_text"
          label="Product description on category section cards"
        >
          <Input placeholder="Enter product description" />
        </Item>
        <Item name="sale" label="Product sale">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Enter sale amount"
            addonAfter="$"
            max={100}
          />
        </Item>
        <Item
          name="keywords"
          label="Keywords"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input keywords!" }]}
        >
          <Input placeholder="Enter keywords" />
        </Item>{" "}
        <Item
          name="seo_title"
          label="SEO Title"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input SEO title!" }]}
        >
          <Input placeholder="Enter SEO title" />
        </Item>{" "}
        <Item
          name="meta_description"
          label="Meta description"
          required
          tooltip="This is a required field"
          rules={[
            { required: true, message: "Please input meta description!" },
          ]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="status"
          label="Status"
          tooltip="This field is for product activation"
          initialValue={modalState?.item?.status}
        >
          <Switch defaultChecked={modalState?.item?.status}></Switch>
        </Item>
      </Form>
    </Modal>
  );
};

export default ModalAddProduct;
