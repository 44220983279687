import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Spin
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import gamesAPI from "../../../http/games";
import parseErrors from "../../../utils/parseServerError";
import usersAPI from "../../../http/users";
import servicesAPI from "../../../http/services";
import productsAPI from "../../../http/products";
import { PAYMENT_NOT_PAID, PAYMENT_PAID } from "../../../utils/constants";
import requestLobbyAPI from "../../../http/request-lobby";
import { notifySuccess } from "../../../utils/notifications";
import { CheckCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { debounce } from "lodash";

const { Item } = Form;

const ModalCustomOrder = ({ modalState, closeModal, refreshData }) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const [gameList, setGameList] = useState([]);
  const [buyersList, setBuyersList] = useState([]);
  const [servicesProducts, setServicesProducts] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_NOT_PAID);
  const [gameServices, setGameServices] = useState([]);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [value, setValue] = useState([]);
  const onFinish = (values) => {
    addOrder(values);
  };
   const [params, setParams] = useState({
    limit: 20,
    offset: 0,
  });

  const addOrder = async (reqData) => {
    try {
      await requestLobbyAPI().createRequest(reqData);
      refreshData();
      closeModal();
      notifySuccess("Request created.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const loadGameList = async (val) => {
    try {
      const res = await gamesAPI().getGameList(val);
      setGameList(res.data);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const loadGameServices = async (id) => {
    try {
      const res = await gamesAPI().getGameServices(id);
      setGameServices(res.data);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

//  const loadUsersList = async (state, searchKeyWord, role, params) => {
  const loadUsersList = async (searchKeyWord, role, params) => {
    try {
      const res = await usersAPI().getUsersList(searchKeyWord, undefined, false, params);
      return formatSelectUsers(res.data.results);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const loadServiceProductList = async (gameId, serviceId) => {
    try {
      const res = await productsAPI().getProductsList(gameId, serviceId);
      setServicesProducts(res.data);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const handlePaymentStatus = (value) => {
    setPaymentStatus(value);
  };

  const handleGameServices = (value) => {
    form.resetFields(["service", "product"]);
    loadGameServices(value);
  };

  const handleServiceProducts = (value) => {
    form.resetFields(["product"]);
    const gameId = form.getFieldValue("game");
    loadServiceProductList(gameId, value);
  };

  const formatSelectData = (items) => {
    const result = items.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    return result;
  };

  const formatSelectUsers = (items) => {
    const result = items.map((item) => {
      return {
        label: item.username.concat(" - ".concat(item?.email)),
        value: item.id,
      };
    });
    return result;
  };

  const memoizedGameList = useMemo(
    () => formatSelectData(gameList),
    [gameList]
  );

  const memoizedServiceList = useMemo(
    () => formatSelectData(gameServices),
    [gameServices]
  );
  const memoizedProductsList = useMemo(
    () => formatSelectData(servicesProducts),
    [servicesProducts]
  );

//  const memoizedBuyers = useMemo(
//    () => formatSelectUsers(value),
//    [buyersList]
//  );

  useEffect(() => {
    form.resetFields();
  }, [modalState]);

  useEffect(() => {
    loadGameList();
//    loadUsersList(searchKeyWord, 0, params);
  }, []);

  function DebounceSelect({ fetchOptions, debounceTimeout = 300, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
//      setOptions([]);
      setFetching(true);
      fetchOptions(value, 0, params).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

    if (options.length === 0) {
        fetchOptions("", 0, params).then((newOptions) => {
            setOptions(newOptions);
            setFetching(false);
          });
    }

  return (
    <Select
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

  return (
    <Modal
      width={1080}
      visible={modalState.isVisible}
      title={"Creating request"}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          Send invite
        </Button>,
      ]}
    >
      <Form ref={formRef} form={form} layout="vertical" onFinish={onFinish}>
        <Item
          name="buyer"
          label="Choose buyer"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please select service!" }]}
        >
        <DebounceSelect
          showSearch
          value={value}
          placeholder="Search users"
          fetchOptions={loadUsersList}
        />
        </Item>
        <Item
          name="payment_status"
          label="Payment status"
          initialValue={PAYMENT_NOT_PAID}
        >
          <Select
            defaultValue={PAYMENT_NOT_PAID}
            onChange={handlePaymentStatus}
          >
            <Select.Option value={PAYMENT_NOT_PAID}> Not paid</Select.Option>
            <Select.Option value={PAYMENT_PAID}> Paid</Select.Option>
          </Select>
        </Item>
        {paymentStatus == PAYMENT_PAID && (
          <Item
            required
            tooltip="This is a required field"
            rules={[{ required: true, message: "Please select service!" }]}
            name="transaction_id"
            label="Transaction ID/ Invoice number"
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Item>
        )}
        <Item
          shouldUpdate
          name="game"
          label="Game"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please select game!" }]}
        >
          <Select
          onChange={handleGameServices}
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {memoizedGameList.map((item) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
          </Select>
        </Item>{" "}
        <Item
          shouldUpdate
          name="service"
          label="Service"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please select service!" }]}
        >
          <Select
          onChange={handleServiceProducts}
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {memoizedServiceList.map((item) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item
          name="product"
          label="Product"
          // required
          // tooltip="This is a required field"
          // rules={[{ required: true, message: "Please select product!" }]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {memoizedProductsList.map((item) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item
          name="offer_name"
          label="Offer name"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input offer name!" }]}
        >
          <Input placeholder="Enter offer name" />
        </Item>
        <Item
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input comment!" }]}
          name="additional_comment"
          label="Additional comment"
        >
          <TextArea></TextArea>
        </Item>{" "}
      </Form>
    </Modal>
  );
};
export default ModalCustomOrder;

export const StyledSpace = styled(Space)`
  display: flex;
  flex: 1;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #e0e5ec;
    transform: scale(1.1);
  }
`;

export const selectAll = (form, fieldName, listOfItems) => {
  const allUsers = listOfItems.map((item) => item.value);
  form.setFieldsValue({
    [fieldName]: allUsers,
  });
};
