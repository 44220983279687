import { getApiClient } from "../client";

function productsAPI() {
  return {
    getProducts: (gameId, serviceId, limit=20, offset, search='') => getApiClient().get(`/admin/game/${gameId}/service/${serviceId}/products?limit=${limit}&offset=${offset}&search=${search}&ordering=-updated`),
    addProduct: (productData) =>
      getApiClient().post("/admin/product", productData),
    editProduct: (updatedProductData, productId) =>
      getApiClient().put(`/admin/product/${productId}`, updatedProductData),
    getProductsList: (gameId, serviceId) => getApiClient().get(`/admin/game/${gameId}/service/${serviceId}/products/list`),
    getProductDetails: (productId) =>
      getApiClient().get(`/admin/product/${productId}`),
    deleteProduct: (productId) =>
      getApiClient().delete(`/admin/product/${productId}`),
  };
}

export default productsAPI;
