import { Button, Input, Select, Space, Table, Tag, Typography } from "antd";
import { debounce } from "lodash";
import { EyeOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import ordersApi from "../../http/orders";
import moment from "moment";
import styles from "./Orders.module.css";
import ModalAddOrder from "./Modals/ModalAddOrder";
import ModalAllOrderInfos from "./Modals/ModalAllOrderInfos";
import ModalOrderInvitation from "./Modals/ModalOrderInvitation";
import { REGULAR_ORDER_STATUSES } from "../../utils/constants";
import { StyledTable } from "../Users/Users";

const { Option } = Select;
const { Search } = Input;
const { Text, Link } = Typography;

const initialModalState = {
  isVisible: false,
  item: null,
};

const WithdrawRequests = () => {
  const [orders, setOrders] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);
  const [modalStateInvitation, setModalStateInvitation] =
    useState(initialModalState);
  const [modalOrderState, setOrderModalState] = useState(initialModalState);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [activeView, setActiveView] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    defaultCurrent: 1,
    defaultPageSize: 5,
    pageSizeOptions: [5,10,20],
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    ordering: "-created",
  });

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPaginationSettings({ ...paginationSettings, current, pageSize });
    setParams({
      ...params,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    });
  };

  const getListOfOrders = async (searchKeyWord = "", params) => {
    try {
      setLoading(true);
      const res = await ordersApi().getOrdersList(searchKeyWord, params);
      setOrders(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      console.log(res, "od ordera");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.dir(e);
    }
  };
  console.log({ activeView });
  const handleActive = (status) => {
    let copyOfObject = { ...params };
    delete copyOfObject.status;
    delete copyOfObject.complaint;
    delete copyOfObject.abandoned;

    switch (status) {
      case null:
        setActiveView(null);
        setParams({ ...copyOfObject });
        break;
      case 1:
        setActiveView(1);
        setParams({ ...copyOfObject, status: 0 });
        break;
      case 2:
        setActiveView(2);
        setParams({ ...copyOfObject, status: [1, 2, 3] });
        break;
      case 3:
        setActiveView(3);
        setParams({ ...copyOfObject, complaint: true });
        break;
      case 4:
        setActiveView(4);
        setParams({ ...copyOfObject, abandoned: true });
        break;
      case 5:
        setActiveView(5);
        setParams({ ...copyOfObject, status: 4 });
        break;
      case 6:
        setActiveView(6);
        setParams({ ...copyOfObject, status: 6 });
        break;
      default:
        setActiveView(null);
        setParams({ ...copyOfObject });
    }
  };

  const openModalOrderInfo = (item) => {
    setModalState({ ...initialModalState, isVisible: true, item });
  };

  const openModalInvitation = (item, editing) => {
    setModalStateInvitation({
      ...initialModalState,
      isVisible: true,
      item,
      editing: editing,
    });
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };
  const closeModalInvitation = () => {
    setModalStateInvitation(initialModalState);
  };

  const changeHandler = (event) => {
    if (paginationSettings?.current != 1) {
      setPaginationSettings({ ...paginationSettings, current: 1 });
      setParams({
        ...params,
        offset: (paginationSettings.current - 1) * 5,
      });
    }
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        getListOfOrders(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  console.log({ orders });

  const columns = [
    {
      title: "Order info",
      key: "id",
      render: (data) => {
        const { id, offer_name, created } = data;
        return (
          <div className={styles.order_info}>
            <Space size="large" className={styles.order}>
              <span>
                Order ID: <b>{id}</b>
              </span>
              <Space size={5} onClick={() => openModalOrderInfo(data)}>
                <EyeOutlined style={{ color: "#1890ff" }} />
                <Link>View order</Link>
              </Space>
            </Space>{" "}
            <span>
              Offer : <b>{offer_name}</b>{" "}
            </span>
            <span>
              Order Date : <b>{moment(created).format("DD MMM YYYY")}</b>
            </span>
          </div>
        );
      },
    },
    {
      title: "Buyer",
      key: "buyer_name",
      dataIndex: "buyer_name",
      render: (data) => <p style={{ color: "#1aa2f9" }}>{data}</p>,
    },
    {
      title: "Booster",
      key: "buyer_name",
      render: (data) => {
        const { order_invitation } = data?.order_invitation;
        console.log({ order_invitation });

        if (
          data?.order_invitation?.status == "INVITE" ||
          data?.order_invitation?.status == "ABANDONED" ||
          data?.order_invitation?.status == "REJECTED"
        )
          return (
            <>
              <Button
                icon={<EyeOutlined />}
                onClick={() => openModalInvitation(data, false)}
                color="red"
              >
                INVITE
              </Button>
              {data?.order_invitation?.status == "REJECTED" && (
                <label style={{ color: "red" }}>Players declined</label>
              )}{" "}
              {data?.order_invitation?.status == "ABANDONED" && (
                <label style={{ color: "red" }}>Players abandoned</label>
              )}
            </>
          );

        if (data?.order_invitation?.status == "PENDING") {
          return (
            <Space direction={"vertical"}>
              <label style={{ color: "blue" }}>Boosters invited</label>
              <Button
                icon={<EyeOutlined />}
                onClick={() => openModalInvitation(data, true)}
                color="red"
              >
                Edit invitation
              </Button>
            </Space>
          );
        }
        return (
          <>
            {order_invitation.length > 0 ? (
              order_invitation.map((order) => {
                return <Tag color="green">{order.booster} </Tag>;
              })
            ) : (
              <Tag color="red">/</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Order status",
      render: (data) => {
        const { status } = data;

        switch (status) {
          case 0:
            return (
              <Space onClick={() => openModalInvitation(data)}>
                <EyeOutlined />
                <Text type="secondary">{REGULAR_ORDER_STATUSES[0]} </Text>
              </Space>
            );

          case 1:
            return (
              <Space>
                <EyeOutlined style={{ color: "green" }} />
                <Text type="success">{REGULAR_ORDER_STATUSES[1]} </Text>
              </Space>
            );
          case 2:
            return (
              <Space>
                <EyeOutlined style={{ color: "orange" }} />
                <Text type="warning">{REGULAR_ORDER_STATUSES[2]} </Text>
              </Space>
            );
          case 3:
            return (
              <Space>
                <EyeOutlined style={{ color: "red" }} />
                <Text type="danger">{REGULAR_ORDER_STATUSES[3]} </Text>
              </Space>
            );
          case 4:
            return (
              <Space>
                <EyeOutlined style={{ color: "red" }} />
                <Text type="danger">{REGULAR_ORDER_STATUSES[4]} </Text>
              </Space>
            );
          case 5:
            return (
              <Space>
                <EyeOutlined style={{ color: "red" }} />
                <Text type="danger">{REGULAR_ORDER_STATUSES[5]} </Text>
              </Space>
            );
          case 6:
            return (
              <Space>
                <EyeOutlined style={{ color: "red" }} />
                <Text type="danger">{REGULAR_ORDER_STATUSES[6]} </Text>
              </Space>
            );
          case 7:
            return (
              <Space>
                <EyeOutlined style={{ color: "red" }} />
                <Text type="danger">{REGULAR_ORDER_STATUSES[7]} </Text>
              </Space>
            );
          default:
            return (
              <Space>
                <EyeOutlined style={{ color: "red" }} />
                <Text type="danger">{REGULAR_ORDER_STATUSES[0]} </Text>
              </Space>
            );
            break;
        }
      },
    },

    {
      title: "Buyer price",
      dataIndex: "usd_price",
      render: (data) => <Tag color="green">{data} $</Tag>,
    },
    {
      title: "Booster price",
      render: (data) => {
        const { order_invitation } = data.order_invitation;
        return (
          <>
            {data.order_invitation.status == "ACCEPTED" &&
            order_invitation.length > 0 ? (
              <Tag color="green">
                {parseFloat(order_invitation[0].booster_price_usd).toFixed(2)}$
              </Tag>
            ) : (
              <Tag color="red">/</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "BR price",
      key: "br_price",
      render: (data) => {
        const { order_invitation } = data.order_invitation;
        return (
          <>
            {data.order_invitation.status == "ACCEPTED" &&
            order_invitation.length > 0 ? (
              <Tag color="green">
                {(
                  parseFloat(data?.usd_price) -
                  parseFloat(order_invitation[0].booster_price_usd)
                ).toFixed(2)}
                $
              </Tag>
            ) : (
              <Tag color="red">/</Tag>
            )}
          </>
        );
      },
    },
  ];

  const closeOrderModal = () => {
    setOrderModalState(initialModalState);
  };
  const openOrderModal = () => {
    setOrderModalState({ ...modalOrderState, isVisible: true });
  };

  return (
    <>
      {/*<Card className={styles.card}>*/}
      <ModalAddOrder
        modalState={modalOrderState}
        closeModal={closeOrderModal}
        refreshData={() => getListOfOrders(searchKeyWord, params)}
      />
      <Space className={styles.card} size={"large"} align={"center"}>
        <Button
          onClick={() => handleActive(null)}
          shape="round"
          type={activeView == null ? "primary" : "dashed"}
        >
          All
        </Button>{" "}
        <Button
          onClick={() => handleActive(1)}
          shape="round"
          type={activeView == 1 ? "primary" : "dashed"}
        >
          New orders
        </Button>
        <Button
          onClick={() => handleActive(2)}
          shape="round"
          type={activeView == 2 ? "primary" : "dashed"}
        >
          Assigned Orders
        </Button>{" "}
        {/*<Button*/}
        {/*  onClick={() => handleActive(5)}*/}
        {/*  shape="round"*/}
        {/*  type={activeView == 5 ? "primary" : "dashed"}*/}
        {/*>*/}
        {/*  Completed Orders*/}
        {/*</Button>{" "}*/}
        {/*<Button*/}
        {/*  onClick={() => handleActive(6)}*/}
        {/*  shape="round"*/}
        {/*  type={activeView == 6 ? "primary" : "dashed"}*/}
        {/*>*/}
        {/*  Canceled Orders*/}
        {/*</Button>*/}
        <Button
          onClick={() => handleActive(3)}
          shape="round"
          type={activeView == 3 ? "primary" : "dashed"}
        >
          Complaints
        </Button>
        <Button
          onClick={() => handleActive(4)}
          shape="round"
          type={activeView == 4 ? "danger" : "dashed"}
        >
          Abandoned
        </Button>{" "}
      </Space>
      {/*</Card>*/}
      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search orders list..."
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
        <Button onClick={openOrderModal} shape="round" type={"primary"}>
          Add Custom Order
        </Button>
      </div>
      <StyledTable
        pagination={paginationSettings}
        loading={isLoading}
        columns={columns}
        dataSource={orders}
        onChange={handleTableChange}
      />
      <ModalOrderInvitation
        modalState={modalStateInvitation}
        closeModal={closeModalInvitation}
        refreshData={() => getListOfOrders(searchKeyWord, params)}
      />
      <ModalAllOrderInfos
        refreshData={() => getListOfOrders(searchKeyWord, params)}
        closeModal={closeModal}
        modalState={modalState}
      />
    </>
  );
};

export default WithdrawRequests;
