import { Button, Form, Input, Modal, Select, Switch } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useRef } from "react";

const ModalAddOption = ({ modalState, closeModal, addOption }) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const onFinish = (values) => {
    addOption({ sub_options: [], ...values });
  };

  const INPUT_TYPES = [
    { value: 0, label: "Checkbox" },
    { value: 1, label: "Radio button" },
    { value: 2, label: "Dropdown" },
  ];

  useEffect(() => {
    form.resetFields();
  }, [modalState]);

  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState.title}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          {modalState?.item ? "Edit offer" : "Add option"}
        </Button>,
      ]}
    >
      <Form
        initialValues={modalState.item}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Title level={4}>Add new option</Title>

        <Form.Item
          label="Name of option"
          name="name"
          required
          tooltip="This is a required  field"
          rules={[
            {
              required: true,
              message: "Please input option name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name="input_type"
          initialValue={INPUT_TYPES[0].value}
        >
          <Select defaultValue={INPUT_TYPES[0].value}>
            {INPUT_TYPES.map((type) => {
              return (
                <Select.Option value={type.value}>{type.label}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="required"
          label="Required"
          tooltip="Is this option required? "
          initialValue={false}
        >
          <Switch defaultChecked={false}></Switch>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddOption;
