import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "./redux/auth/user";
import Login from "./views/Auth/Login";
import Dashboard from "./views/Dashboard";
import NotFound from "./views/NotFound";
import authAPI from "./http/auth";
import { ToastContainer } from "react-toastify";
import { notifySuccess } from "./utils/notifications";
import { Progress } from "antd";
import styles from "./App.module.css";
import parseErrors from "./utils/parseServerError";
import "../src/newStyle.css"

import history from "./utils/history";
import DeliveryOptions from "./components/WebsiteManagement/DeliveryOptions";

const App = () => {
  const [isLog, setIsLog] = useState(false);
  const [checkingProgress, setCheckingProgress] = useState(false);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const refresh_token_local = localStorage.getItem("refresh_token")
  const handleLogin = async (data1) => {
   data1 = {
      grant_type: "password",
            client_id: "FmRUItyRBKWRIx6ht9ZyDmTbWMuwYKSYlmRP3lQW",
            client_secret: "FGVmiSWjR0nLCpTygjrgQi4CfdSJoTovJOosOY9VbyEtKJ0GiLeObWBh12CmUwJFpbrEwKG3EvC2LrCyz8thFNwkpXIxUvlFDxKzHB6ncgMHSAZnqWwzwlxSxnfjWLOn",
            ...data1,
    };
   
    console.log("login ka data " , data1)
    try {
      setLoading(true);
      const res = await authAPI().login(data1);

      localStorage.setItem("token", res.data.access_token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      localStorage.setItem("expiration_date", res.data.expiration_date);
      setIsLog(true);
      notifySuccess("You are logged in.");
      dispatch(setUser(res.data));
      setLoading(false);
    } catch (e) {
      console.dir(e);
      setLoading(false);
      setIsLog(false);
      parseErrors(e?.response?.data);
    }
  };
  const checkIsUserLoggedIn = async (data2) => {
    data2 = {
      grant_type: "refresh_token",
            client_id: "FmRUItyRBKWRIx6ht9ZyDmTbWMuwYKSYlmRP3lQW",
            client_secret: "FGVmiSWjR0nLCpTygjrgQi4CfdSJoTovJOosOY9VbyEtKJ0GiLeObWBh12CmUwJFpbrEwKG3EvC2LrCyz8thFNwkpXIxUvlFDxKzHB6ncgMHSAZnqWwzwlxSxnfjWLOn",
            refresh_token : refresh_token_local,
    };
    if (token) {
      try {
        setCheckingProgress(true);
        const refresh_token = localStorage.getItem("refresh_token");
        const res = await authAPI().getRefreshToken(data2);
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("expiration_date", res.data.expiration_date);
        setCheckingProgress(false);
        setIsLog(true);
      } catch (e) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("expiration_date");
        setCheckingProgress(false);
        setIsLog(false);
      }
    } else {
      setCheckingProgress(false);
      setIsLog(false);
    }
  };

  useEffect(() => {
    checkIsUserLoggedIn();
  }, []);

  if (checkingProgress)
    return (
      <div className={styles.container}>
        <Progress
          type="circle"
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          percent={90}
        />
      </div>
    );

  return (
    // <BrowserRouter basename={"/admin"} history={history}>
    <BrowserRouter basename='/admin'>
      <ToastContainer />
      <Switch>
        {!isLog ? (
          <Route
            exact
            path="/"
            render={() => <Login handleLogin={handleLogin} loading={loading} />}
          />
          
        ) : (
          <Route path="/" component={Dashboard} />
        )}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
