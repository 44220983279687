import {
  Avatar,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Switch,
  Upload,
} from "antd";
import { useEffect, useRef, useState } from "react";
import servicesAPI from "../../../http/services";
import { notifySuccess } from "../../../utils/notifications";
import parseErrors from "../../../utils/parseServerError";
import { UploadOutlined } from "@ant-design/icons";
import methodsAPI from "../../../http/methods";
import styled from "@emotion/styled";
import ProfileImage from "../../Users/ProfileImage";

const { Item, useForm } = Form;

const ModalAddGame = ({ modalState, closeModal, refreshData }) => {
  const [form] = useForm();
  const formRef = useRef();

  const onFinish = (values) => {
    let requiredFields = values?.required_fields ?? "";
    requiredFields = requiredFields.split(",");
    let newObject = {};
    for (let key of requiredFields) {
      if (key == "") {
        return;
      } else {
        newObject[key.toString().trim()] = true;
      }
    }

    if (modalState.item) {
      editExistingGame(
        { ...values, required_fields: newObject },
        modalState?.item?.id
      );
    } else {
      addNewGame({ ...values, required_fields: { ...newObject } });
    }
  };

  const addNewGame = async (gameData) => {
    try {
      await methodsAPI().addMethod(gameData);
      refreshData();
      closeModal();
      notifySuccess("New method added.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };
  const editExistingGame = async (gameData) => {
    try {
      await methodsAPI().editMethod(gameData, modalState?.item?.id);
      refreshData();
      closeModal();
      notifySuccess("Method edited.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [modalState]);

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const props = {
    multiple: false,
  };

  let initialValues = {};
  if (modalState?.item) {
    initialValues = {
      ...modalState.item,
      required_fields: Object.keys(modalState?.item?.required_fields).join(","),
    };
  }
  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState?.item ? "Edit method" : "Add method"}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          {modalState?.item ? "Edit method" : "Add method"}
        </Button>,
      ]}
    >
      <Form
        initialValues={initialValues}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Item
          name="name"
          label="Method name"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input method name!" }]}
        >
          <Input placeholder="Enter method name" />
        </Item>

        <Item name="fee" label="Fee">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Enter fee"
            max={100}
          />
        </Item>

        <Item name="fixed_fee_usd" label="Fixed fee (USD)">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Enter fixed fee"
          />
        </Item>

        <Item name="min_withdraw_amount" label="Minimal withdraw amount">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Enter min amount"
          />
        </Item>
        <Item
          name="required_fields"
          label="Required fields"
          required
          tooltip="Enter required value, and divide them with comma (,)"
          rules={[{ required: true, message: "Please input method name!" }]}
        >
          <Input
            style={{ width: "100%" }}
            min={0}
            placeholder="Enter required fields"
          />
        </Item>

        <Form.Item
          label="Is active"
          tooltip="Is method active?"
          name={"status"}
          initialValue={modalState?.item?.status}
        >
          <Switch defaultChecked={modalState?.item?.status ?? false}></Switch>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddGame;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
`;
