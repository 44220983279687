import styles from "./RoomsPage.module.css";
import { Button, Input } from "antd";
import { useHistory } from "react-router-dom";

import { UploadOutlined } from "@ant-design/icons";
import roomsAPI from "../../http/rooms";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { StyledTable } from "../Users/Users";

const { Search } = Input;

const RoomsPage = () => {
  const history = useHistory();

  const [pageListData, setPageListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
    defaultCurrent: 1,
    defaultPageSize: 5,
    pageSizeOptions: [5,10,20],
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    ordering: "-created",
  });

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPaginationSettings({ ...paginationSettings, current, pageSize });
    setParams({
      ...params,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    });
  };

  const getListOfRooms = async () => {
    try {
      setIsLoading(true);
      const res = await roomsAPI().getRooms(searchKeyWord, params);
      setPageListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
    getListOfRooms(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "Order Id",
      dataIndex: "order",
      key: "order-id",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (buyer) => <p>{buyer.username}</p>,
    },
    {
      title: "Booster",
      dataIndex: "booster",
      key: "booster",
      render: (booster) => <p>{booster.username}</p>,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "view",
      render: (id) => {
        return (
          <Button onClick={() => history.push(`/rooms/${id}`)}>
            <UploadOutlined /> View room
          </Button>
        );
      },
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Rooms Page</h1>
      <div className={styles.uploadSitemap}></div>
      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search rooms"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
      </div>
      <StyledTable
        pagination={paginationSettings}
        dataSource={pageListData}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default RoomsPage;
