import styles from "./TypeOfService.module.css";
import {
  Button,
  Input,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useHistory } from "react-router-dom";

import {
  DeleteOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import gamesAPI from "../../http/games";
import moment from "moment";
import { debounce } from "lodash";
import { notifySuccess } from "../../utils/notifications";
import parseErrors from "../../utils/parseServerError";
import { StyledTable } from "../Users/Users";

const { Title } = Typography;
const { Search } = Input;

const initialModalState = { visible: false, item: null, title: "Add new game" };

const PopularGames = () => {
  const history = useHistory();
  const [modalState, setModalState] = useState(initialModalState);
  const [gamesListData, setGamesListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
    console.log("Desava se", current);
  };

  const getListOfGames = async (searchKeyWord, params) => {
    try {
      setIsLoading(true);
      const res = await gamesAPI().getPopularGames(searchKeyWord, params);
      setGamesListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  const viewDetails = (game) => {
    console.log(game);
    return history.push("/wb/brands/" + game.id);
  };

  const handlePopularGame = async (data) => {
    try {
      await gamesAPI().editGame({ ...data, popular: !data.popular }, data.id);
      getListOfGames(searchKeyWord, params);
      notifySuccess(`${data.name} is updated.`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
    getListOfGames(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "Game name",
      render: (data) => {
        return (
          <Tooltip title={"View details"}>
            <Space
              style={{ cursor: "pointer" }}
              onClick={() => viewDetails(data)}
            >
              <EyeOutlined></EyeOutlined>
              <b>{data?.name ?? "/"}</b>
            </Space>
          </Tooltip>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created",
      key: "created",
      render: (created) => {
        return (
          <Tag color={"green"}>{moment(created).format("DD MM YYYY")}</Tag>
        );
      },
    },
    {
      title: "Updated at",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => {
        return <Tag color={"blue"}>{moment(updated).format("DD MM YYYY")}</Tag>;
      },
    },
    {
      title: "Action",
      key: "edit",
      render: (game) => {
        return (
          <Popconfirm
            title={
              "Are you sure to remove " + game.name + " from popular games?"
            }
            icon={
              <QuestionCircleOutlined
                style={{ color: "red", transform: "scale(1.3)" }}
              />
            }
            onConfirm={() => handlePopularGame(game)}
            okText="Yes"
            cancelText="No"
            size={"large"}
          >
            <Button icon={<DeleteOutlined />} danger color={"red"}>
              Remove from popular{" "}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Popular Games </h1>

      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search popular games"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
      </div>
      <StyledTable
        pagination={paginationSettings}
        dataSource={gamesListData}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default PopularGames;
