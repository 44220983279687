import React, { useState } from "react";
import { Button, Space } from "antd";
import styled from "@emotion/styled";
import RequestLobby from "./RequestLobby";
import RequestLobbySentOffers from "./RequestLobbySentOffers";

const MainRequestLobby = ({ userId }) => {
  const [activeView, setActiveView] = useState(0);

  const handleViewChange = (view) => {
    if (view == activeView) {
      return;
    }
    setActiveView(view);
  };
  return (
    <>
      <Space>
        <Button
          type={"round"}
          disabled={activeView == 0}
          onClick={() => handleViewChange(0)}
        >
          Active{" "}
        </Button>
        <Button
          type={"round"}
          disabled={activeView == 1}
          onClick={() => handleViewChange(1)}
        >
          Sent offers{" "}
        </Button>
      </Space>

      {activeView == 0 && <RequestLobby userId={userId} />}
      {activeView == 1 && <RequestLobbySentOffers userId={userId} />}
    </>
  );
};
export default MainRequestLobby;

const StyledSpace = styled(Space)`
  margin-bottom: 10px;
`;
