import styles from "./GameServiceDetails.module.css";
import { Button, PageHeader, Radio } from "antd";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import CategoryTable from "./CategoryTable";
import gamesAPI from "../../http/games";
import ProductTable from "./ProductTable";
import OffersTable from "./OffersTable";
import Title from "antd/es/typography/Title";
import { PlusCircleOutlined } from "@ant-design/icons";
import ModalEditServiceByGame from "./Modals/ModalEditServiceByGame";
import offersApi from "../../http/offers";
import categoriesAPI from "../../http/categories";
import productsAPI from "../../http/products";

const modalState = { visible: false, title: "Edit service" };
const GameServiceDetails = (props) => {
  const router = useHistory();
  const {
    match: {
      params: { gameId, serviceId },
    },
  } = props;

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState(undefined);
  const [offers, setOffers] = useState();
  const [products, setProducts] = useState();
  const [productsSimple, setProductsSimple] = useState();
  const [categories, setCategories] = useState();
  const [servicesData, setServicesData] = useState(null);
  const [selectedView, setSelectedView] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [editService, setEditService] = useState(modalState);
  const redirectToGame = (id) => {
    router.push(`/wb/brands/${id}`);
  };

  const getOffers = async (search) => {
      const res = await offersApi().getOffers(gameId, serviceId, 20, offset, search);
      setOffers(res.data);
      setIsLoading(false);
  }

  const getProducts = async (search) => {
      const res = await productsAPI().getProducts(gameId, serviceId, 20, offset, search);
      setProducts(res.data);
      setIsLoading(false);
  }

   const getProductsSimple = async () => {
      const res = await productsAPI().getProductsList(gameId, serviceId);
      setProductsSimple(res.data);
      setIsLoading(false);
  }

  const getCategories = async (search) => {
      const res = await categoriesAPI().getCategories(gameId, serviceId, 20, offset, search);
      setCategories(res.data);
      setIsLoading(false);
  }

  const getServiceDetails = async () => {
    try {
      const res = await gamesAPI().getGameServices(gameId);
      const serviceDetails = res.data.find((item) => item.id == serviceId);
      setServicesData(serviceDetails);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
      setIsLoading(true);

      if(selectedView == 1) {
          getCategories(search);
      } else if(selectedView == 2) {
          getProducts(search);
      } else if(selectedView == 3) {
          getOffers(search);
          getProductsSimple();
      }
  }, [search, offset, selectedView]);

  useEffect(() => {
    getServiceDetails();
  }, [serviceId]);

  const changeView = (e) => {
    setOffset(0);
    setSearch(undefined);
    setSelectedView(e.target.value);
  };

  return (
    <div className={styles.container}>
      <ModalEditServiceByGame
        modalState={editService}
        closeModal={() => {
          console.log("Close modal");
          setEditService(modalState);
        }}
        refreshData={() => {}}
        serviceId={serviceId}
        gameId={gameId}
      />{" "}
      <PageHeader
        className="site-page-header"
        onBack={() => redirectToGame(gameId)}
        title={"Back to game details"}
      />
      <div className={styles.title}>
        <Title level={2}>{servicesData?.name}</Title>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Radio.Group
          value={selectedView}
          onChange={changeView}
          style={{ marginBottom: 16 }}
        >
          <Radio.Button disabled={servicesData?.type_of_service == 1} value={1}>
            Categories
          </Radio.Button>
          <Radio.Button disabled={servicesData?.type_of_service == 1} value={2}>
            Products
          </Radio.Button>
          <Radio.Button value={3}>Offers</Radio.Button>
        </Radio.Group>
        <Button
          type="primary"
          shape="round"
          icon={<PlusCircleOutlined />}
          onClick={() =>
            setEditService({ ...modalState, visible: !editService.visible })
          }
        >
          {"Edit " + servicesData?.name ?? "Edit service"}
        </Button>
      </div>
      {selectedView == 1 && (
        <CategoryTable
          refreshData={getCategories}
          categories={categories?.results ?? []}
          total={categories?.count ?? 0}
          onSearch={(search) => {
              setOffset(0);
              setSearch(search);
          }}
          loading={isLoading}
          serviceId={serviceId}
          gameId={gameId}
        />
      )}
      {selectedView == 2 && (
        <ProductTable
          refreshData={getProducts}
          products={products?.results ?? []}
          total={products?.count ?? 0}
          onSearch={(search) => {
              setOffset(0);
              setSearch(search);
          }}
          loading={isLoading}
          setOffset={setOffset}
          gameId={gameId}
          serviceId={serviceId}
        />
      )}
      {selectedView == 3 && (
        <OffersTable
          products={productsSimple ?? []}
          serviceId={serviceId}
          onSearch={(search) => {
              setOffset(0);
              setSearch(search);
          }}
          setOffset={setOffset}
          refreshData={getOffers}
          offers={offers?.results ?? []}
          total={offers?.count ?? 0}
          loading={isLoading}
          serviceType={servicesData?.type_of_service}
          gameId={gameId}
        />
      )}
    </div>
  );
};

export default GameServiceDetails;
