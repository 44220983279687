import { Button, Form, Input, InputNumber, Modal } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useRef } from "react";

const ModalAddSubOption = ({ modalState, closeModal, addSubOption }) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const onFinish = (values) => {
    addSubOption(modalState.index, values);
  };

  useEffect(() => {
    form.resetFields();
  }, [modalState]);

  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState.title}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current?.submit()}>
          {modalState?.item ? "Edit offer" : "Add sub-option"}
        </Button>,
      ]}
    >
      <Form
        initialValues={modalState.item}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Title level={4}>Add sub-option</Title>

        <Form.Item
          label="Name of sub-option"
          name="name"
          required
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              message: "Please input option name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Price for sub-option"
          name="additional_price_usd"
          tooltip="Price for sub-option"
        >
          <InputNumber style={{ width: "100%" }} min={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddSubOption;
