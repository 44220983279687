import styles from "./RefundRequest.module.css";
import RequestLobbyComponent from "../../components/Sales/RequestLobby.js";

const RequestLobby = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Request Lobby </h1>
      <RequestLobbyComponent />
    </div>
  );
};

export default RequestLobby;
