import React from 'react';
import ReactQuill from 'react-quill';

const TextEditor = ({value, onChange, placeholder}) => {
    return (
        <ReactQuill
            value={value || ''}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
};

export default TextEditor;
