import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Spin,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import gamesAPI from "../../../http/games";
import parseErrors from "../../../utils/parseServerError";
import usersAPI from "../../../http/users";
import servicesAPI from "../../../http/services";
import productsAPI from "../../../http/products";
import ordersAPI from "../../../http/orders";
import { notifySuccess } from "../../../utils/notifications";
import { selectAll, StyledSpace } from "./ModalCustomOrder";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Item } = Form;

const ModalAddOrder = ({ modalState, closeModal, refreshData }) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const [gameList, setGameList] = useState([]);
  const [playersList, setPlayersList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isServiceLoading, setServiceLoading] = useState(false);
  const [isProductLoading, setProductLoading] = useState(false);
  const [isPlayersLoading, setPlayersLoading] = useState(false);
  const [servicesProducts, setServicesProducts] = useState([]);
  const [gameServices, setGameServices] = useState([]);

  const onFinish = (values) => {
    createOrder(values);
  };

  const createOrder = async (orderData) => {
    try {
      const res = await ordersAPI().createOrder(orderData);
      refreshData();
      notifySuccess("Order created.");
      closeModal();
    } catch (e) {
      parseErrors(e);
    }
  };

  const loadGameList = async (val) => {
    try {
      setLoading(true);
      const res = await gamesAPI().getGameList(val);
      setGameList(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      parseErrors(e.response.data);
    }
  };

  const loadGameServices = async (id) => {
    try {
      setServiceLoading(true);
      const res = await gamesAPI().getGameServices(id);
      setServicesProducts([]);
      setGameServices(res.data);
      setServiceLoading(false);
    } catch (e) {
      setServiceLoading(false);

      parseErrors(e.response.data);
    }
  };

  const loadUsersList = async (state, gameId, serviceId) => {
    try {
      setPlayersLoading(true);
      const res = await usersAPI().invitationList(gameId, serviceId);
      state(res.data);
      setPlayersLoading(false);
    } catch (e) {
      setPlayersLoading(false);
      parseErrors(e.response.data);
    }
  };

  const loadServiceProductList = async (gameId, serviceId) => {
    try {
      setProductLoading(true);
      const res = await productsAPI().getProductsList(gameId, serviceId);
      setServicesProducts(res.data);
      setProductLoading(false);
    } catch (e) {
      setProductLoading(false);
      parseErrors(e.response.data);
    }
  };

  const handleGameServices = (value) => {
    setGameServices([]);
    form.resetFields(["service", "product"]);
    loadGameServices(value);
  };

  const handleServiceProducts = (value) => {
    form.resetFields(["product"]);
    const gameId = form.getFieldValue("game");
    console.log({ gameId });
    loadUsersList(setPlayersList, gameId, value);
    loadServiceProductList(gameId, value);
  };

  const formatSelectData = (items) => {
    const result = items.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    return result;
  };

  const formatSelectUsers = (items) => {
    const result = items.map((item) => {
      return {
        label: item.username,
        value: item.id,
      };
    });
    return result;
  };

  const memoizedGameList = useMemo(
    () => formatSelectData(gameList),
    [gameList]
  );

  const memoizedServiceList = useMemo(
    () => formatSelectData(gameServices),
    [gameServices]
  );
  const memoizedProductsList = useMemo(
    () => formatSelectData(servicesProducts),
    [servicesProducts]
  );

  const memoizedPlayers = useMemo(
    () => formatSelectUsers(playersList),
    [playersList]
  );

  useEffect(() => {
    form.resetFields();
  }, [modalState]);

  useEffect(() => {
    loadGameList();
  }, []);

  return (
    <Modal
      width={1080}
      visible={modalState.isVisible}
      title={"Adding custom order"}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          Send invite
        </Button>,
      ]}
    >
      <Form ref={formRef} form={form} layout="vertical" onFinish={onFinish}>
        <Item
          shouldUpdate
          name="game"
          label="Game"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please select Brand!" }]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            onChange={handleGameServices}
            notFoundContent={
              isLoading ? (
                <Spin size="small" />
              ) : (
                <Select.Option disabled={true}>No results found</Select.Option>
              )
            }
          >
            {memoizedGameList.map((item) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
          </Select>
        </Item>{" "}
        <Item
          shouldUpdate
          name="service"
          label="Service"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please select service!" }]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            notFoundContent={
              isServiceLoading ? (
                <Spin size="small" />
              ) : (
                <Select.Option disabled={true}>No results found</Select.Option>
              )
            }
            onChange={handleServiceProducts}
          >
            {memoizedServiceList.map((item) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item name="product" label="Product">
          <Select
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            notFoundContent={isProductLoading ? <Spin size="small" /> : null}
          >
            {memoizedProductsList.map((item) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item
          name="offer_name"
          label="Offer name"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input offer name!" }]}
        >
          <Input placeholder="Enter offer name" />
        </Item>
        <Item
          name="boosters"
          label="Invite player"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please select player!" }]}
        >
          <Select
            mode="multiple"
            notFoundContent={
              isPlayersLoading ? (
                <Spin size="small" />
              ) : (
                <Select.Option disabled={true}>No results found</Select.Option>
              )
            }
            dropdownRender={(menu) => (
              <div>
                {menu}
                {memoizedPlayers.length > 1 && (
                  <>
                    <Divider style={{ margin: "4px 0" }} />
                    <StyledSpace
                      size={"small"}
                      align="center"
                      onClick={() =>
                        selectAll(form, "boosters", memoizedPlayers)
                      }
                    >
                      <CheckCircleOutlined />
                      <b>Select all</b>
                    </StyledSpace>
                  </>
                )}
              </div>
            )}
          >
            {memoizedPlayers.map((item) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please select service!" }]}
          name="transaction_id"
          label="Transaction ID/ Invoice number"
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Item>
        <Space>
          <Item
            name="usd_price"
            label="Buyers price"
            initialValue={0}
            required
            tooltip="This is a required field"
            rules={[{ required: true, message: "Please input value!" }]}
          >
            <InputNumber min={0} defaultValue={0} style={{ width: "100%" }} />
          </Item>
          <Item
            name="boostroom_usd_price"
            label="Boostrooms share"
            initialValue={0}
            required
            tooltip="This is a required field"
            rules={[{ required: true, message: "Please input value!" }]}
          >
            <InputNumber min={0} defaultValue={0} style={{ width: "100%" }} />
          </Item>
        </Space>
        <Item name="additional_comment" label="Additional comment">
          <TextArea></TextArea>
        </Item>{" "}
        <Item name="admin_note" label="Admin note">
          <TextArea></TextArea>
        </Item>
      </Form>
    </Modal>
  );
};
export default ModalAddOrder;
