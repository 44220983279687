import { Button, Form, Input, Modal, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import blogAPI from "../../../http/blogs";
import { notifySuccess } from "../../../utils/notifications";
import parseErrors from "../../../utils/parseServerError";
import lodash, { toInteger } from "lodash";
import ReactQuill from "react-quill";
import { REACT_QUILL_MODULES } from "../../../utils/constants";

const { Item, useForm } = Form;

const ModalAddEditPage = ({ modalState, closeModal, refreshData }) => {
  const [form] = useForm();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [quillData, setQuillData] = useState(
    modalState?.item?.description ?? "Nema ga"
  );

  let initialValues = {};

  if (modalState?.item) {
    initialValues = {
      ...modalState.item,
    };
  }

  const onFinish = (values) => {
    if (modalState.item) {
      editBlog(
        {
          ...values,
          content: quillData,
          popular_games: lodash.intersection(
            values.games,
            values.popular_games
          ),
        },
        modalState?.item?.id
      );
    } else {
      addNewPage({
        ...values,
        popular_games: lodash.intersection(values.games, values.popular_games),
        content: quillData,
        categories: [],
        offers: [],
      });
    }
  };

  const handleDataFromQuill = (data) => {
    setQuillData(data);
  };

  const addNewPage = async (blogData) => {
    try {
      setLoading(true);
      await blogAPI().addBlog(blogData);
      refreshData();
      closeModal();
      notifySuccess("New page added.");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      parseErrors(e.response.data);
    }
  };

  const editBlog = async (updatedBlogData, blogId) => {
    try {
      setLoading(true);
      await blogAPI().editBlog(updatedBlogData, blogId);
      refreshData();
      closeModal();
      notifySuccess(`${updatedBlogData.top_description_title} is updated.`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    form.resetFields();
    if (modalState?.item) {
      setQuillData(modalState?.item?.content ?? "");
    } else {
      setQuillData("");
    }
  }, [modalState]);

  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState.title}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={loading}
          loading={loading}
          onClick={() => formRef.current.submit()}
        >
          {modalState?.item ? "Edit page" : "Add page"}
        </Button>,
      ]}
    >
      <Form
        initialValues={initialValues}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Item
          name="top_description_title"
          label="Main title"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Main title!" }]}
        >
          <Input placeholder="Enter main title" />
        </Item>
        <Item
          name="top_description"
          label="Main text"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Main text!" }]}
        >
          <Input placeholder="Enter main text" />
        </Item>
        <Item
          name="seo_title"
          label="Seo title"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input seo title!" }]}
        >
          <Input placeholder="Enter seo title" />
        </Item>
        <Item
          name="meta_description"
          label="Meta description"
          required
          tooltip="This is a required field"
          rules={[
            { required: true, message: "Please input meta description!" },
          ]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="keywords"
          label="Keywords"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input keywords!" }]}
        >
          <Input placeholder="Enter keywords" />
        </Item>
        <Item
          name="summary"
          label="Summary"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input summary!" }]}
        >
          <Input placeholder="Enter summary" />
        </Item>
        <label>
          <Tooltip title={"This field is required"}>
            <b style={{ color: "red", fontSize: "12px" }}>*</b> Description
          </Tooltip>
        </label>
        <div style={{ margin: "8px 0" }}>
          <ReactQuill
            value={quillData}
            onChange={handleDataFromQuill}
            theme="snow"
            modules={REACT_QUILL_MODULES}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddEditPage;
