import { Button, Empty, List, Modal } from "antd";
import moment from "moment";

const ModalOffers = ({ modalState, closeModal, selectOffer }) => {
  console.log({ modalState: modalState.item });

  if (modalState.item == null) return <></>;

  const { booster_offers } = modalState.item;
  const cardStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexDirection: "row",
  };

  const listItemStyle = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };

  console.log({ booster_offers });

  return (
    <Modal
      width={1080}
      footer={[
        <Button type="round" key="back" onClick={closeModal}>
          Close
        </Button>,
      ]}
      title={"Offers"}
      onCancel={closeModal}
      visible={modalState.isVisible}
      width={"60vw"}
      destroyOnClose
    >
      {booster_offers.length < 1 && <Empty />}
      <List size={"large"}>
        {booster_offers.map((offer) => {
          const { booster, start_date, usd_price, delivery_time } = offer;
          return (
            <List.Item>
              <div
                style={{
                  margin: "5px 0",
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div style={listItemStyle}>
                  <b>
                    {booster?.first_name ?? "/" + booster?.last_name ?? "/"}
                  </b>
                </div>
                <div style={listItemStyle}>
                  <span>Starts at</span>
                  <b>{moment(start_date).format("DD MMM YYYY")}</b>
                </div>
                <div style={listItemStyle}>
                  <span>Price</span>
                  <b style={{ color: "green" }}>{usd_price}$</b>
                </div>
                <Button type="primary" onClick={() => selectOffer(offer)}>
                  Select offer
                </Button>
              </div>
            </List.Item>
          );
        })}
      </List>
    </Modal>
  );
};
export default ModalOffers;
