import React from 'react'

const HomePage = () => {
  return (
    <div className=' h-[50vh] w-full flex justify-center items-center text-white text-4xl'>
      Welcome to Masterloot's admin panel.
    </div>
  )
}

export default HomePage
