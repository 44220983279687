import { Route, Switch , Routes } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import styles from "./Dashboard.module.css";
import Discounts from "./Discounts.js";
import SellerRequest from "./SellerRequest.js";
import WithdrawRequests from "./WithdrawRequests.js";
import RefundRequests from "./RefundRequests.js";
import RequestLobby from "./RequestLobby.js";
import Orders from "./Orders";
import Users from "./Users.js";
import UserDetails from "./UserDetails.js";
import NavLinks from "../../components/Dashboard/NavLinks";
import TypeOfService from "../../components/WebsiteManagement/TypeOfService";
import Games from "../../components/WebsiteManagement/Games";
import Methods from "../../components/Methods/Methods";
import PopularGames from "../../components/WebsiteManagement/PopularGames";
import GameDetails from "../../components/WebsiteManagement/GameDetails";
import GameServiceDetails from "../../components/WebsiteManagement/GameServiceDetails";
import InfoPages from "../../components/Info/InfoPages";
import RoomsPage from "../../components/Rooms/RoomsPage";
import RoomPage from "../../components/Rooms/RoomPage/RoomPage";
import DeliveryOptions from "../../components/WebsiteManagement/DeliveryOptions.jsx";
import Sidebar from "./Sidebar.jsx";
import logo from "../../assets/icons/logo.png"
import AddNewWebServicePage from "../../components/WebsiteManagement/AddNewWebServicePage.jsx";
import WebServicePage from "../../components/WebsiteManagement/WebServicePage.jsx";
import EditNewWebServicePage from "../../components/WebsiteManagement/EditWebServicePage.jsx";
import OfferType from "../../components/WebsiteManagement/OfferType.jsx";
import Filter from "../../components/WebsiteManagement/Filter.jsx";
import HomePage from "../../components/WebsiteManagement/HomePage.jsx";

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logout = () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("expiration_date");
      window.location.href = process.env.PUBLIC_URL + "/";
    } catch (e) {
      console.error(e);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="w-full flex flex-row">
    {/* <Layout className="sticky w-1/5"> */}
      {/* <Sider className={styles.slider} trigger={null}>
        <NavLinks />
      </Sider> */}
      <div className="w-[15%]">
        <div className="bg-[#08303f] h-[80px] flex items-center justify-center"><img src={logo} alt="logo"></img></div>
      <Sidebar logout_btn ={logout}/>
      </div>
      <div className="w-[85%] bg-[#052634]">
      <Layout>
        <Header className={styles.header}>
          <div className={styles.headerContainer}>
            {/* <Button onClick={logout} icon={<UserOutlined />}>
              Logout
            </Button> */}
          </div>
        </Header>
        <Content style={{ overflowY: "auto" }}>
          <Switch>
            {/* <Route exact path={`/`} component={TypeOfService} /> */}
            <Route exact path={`/wb/services`} component={TypeOfService} />
            <Route exact path={`/wb/brands`} component={Games} />
            <Route exact path={`/wb/popular/games`} component={PopularGames} />
            <Route exact path={`/wb/brands/:gameId`} component={GameDetails} />
            <Route exact path={`/wb/delivery-options`} component={DeliveryOptions} />
            <Route exact path={`/wb/offer-type`} component={OfferType} />
            <Route
              exact
              path={`/wb/brands/:gameId/services/:serviceId`}
              component={GameServiceDetails}
            />
            <Route exact path={`/users`} component={Users} />
            <Route exact path={`/users/:userId`} component={UserDetails} />
            <Route path={`/discounts`} component={Discounts} />
            <Route path={`/seller-request`} component={SellerRequest} />
            <Route exact path={"/info"} component={InfoPages} />
            <Route exact path={"/rooms"} component={RoomsPage} />
            <Route exact path={"/rooms/:roomId"} component={RoomPage} />
            <Route
              exact
              path={`/sales/withdrawal-requests`}
              component={WithdrawRequests}
            />{" "}
            <Route
              exact
              path={`/sales/withdrawal/methods`}
              component={Methods}
            />
            <Route
              exact
              path={`/sales/refund-list`}
              component={RefundRequests}
            />
            <Route
              exact
              path={`/sales/request-lobby`}
              component={RequestLobby}
            />
            <Route exact path={`/sales/orders`} component={Orders} />
            <Route exact path={`/add-web-service-page`} component={AddNewWebServicePage} />
            <Route exact path={`/web-service-page`} component={WebServicePage} />
            <Route exact path={`/edit-web-service-page`} component={EditNewWebServicePage} />
            <Route exact path={`/offer-type`} component={OfferType} />
            <Route exact path={`/filter`} component={Filter} />
            <Route exact path={`/`} component={HomePage} />
          </Switch>
        </Content>
      </Layout>
      </div>
    </Layout>
  );
};

export default Dashboard;
