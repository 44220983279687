import { Button, Input, List, Tag } from "antd";
import parseErrors from "../../../utils/parseServerError";
import { notifySuccess } from "../../../utils/notifications";
import { useState } from "react";
import ordersAPI from "../../../http/orders";

const { TextArea } = Input;
const ComplaintMessages = ({ messages, item, refreshData }) => {
  const [resolving, setResolving] = useState({});

  const handleResolve = async (message, index) => {
    try {
      setResolving({ ...resolving, [index]: true });

      const res = await ordersAPI().editOrder(
        {
          complaint: message.id,
          complaint_status: 1,
        },
        item.id
      );
      refreshData();
      notifySuccess("Message resolved");
    } catch (e) {
      setResolving({ ...resolving, [index]: false });
      parseErrors(e);
    }
  };

  return (
    <List
      size="large"
      header={<h3>Complaints Messages</h3>}
      style={{ width: "100%" }}
    >
      {messages.length < 1 && <List.Item>No complaints.</List.Item>}
      {messages.map((message, index) => (
        <List.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
              backgroundColor: "dirtywhite",
              margin: "10px 0",
            }}
          >
            <label>
              <span>Message from {message.user?.role} </span>
              <Tag color="green">{message.user?.name ?? "/"} </Tag>
            </label>
            <TextArea disabled value={message.message}></TextArea>
            <Button
              disabled={resolving[index]}
              onClick={() => handleResolve(message, index)}
            >
              {resolving[index] ? "Resolved" : "Resolve"}
            </Button>
          </div>
        </List.Item>
      ))}
    </List>
  );
};

export default ComplaintMessages;
