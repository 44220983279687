import React, { useState } from "react";
import transactionApi from "../../../http/transactions/";
import { notifyError, notifySuccess } from "../../../utils/notifications";
import { Input, Modal } from "antd";
import { WITHDRAW_DECLINED } from "../../../utils/constants.js";
import parseErrors from "../../../utils/parseServerError";

const { TextArea } = Input;

const AdminReason = ({ closeModal, modalState }) => {
  const [reason, setReason] = useState(null);

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    if (!reason || reason == "") return notifyError("Please enter reason");
    try {
      const { item } = modalState;
      const updatedWithdrawData = {
        ...item,
        status: WITHDRAW_DECLINED,
        admin_note: reason,
      };
      const res = await transactionApi().editWithdraw(
        updatedWithdrawData,
        item?.id
      );
      modalState.refresh();
      notifySuccess(`Status for withdraw ${item?.id} is declined.`);
      closeModal();
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  return (
    <Modal
      width={1080}
      title="Declining withdraw"
      visible={modalState.visible}
      onOk={handleSubmit}
      onCancel={closeModal}
    >
      <TextArea
        required
        placeholder="Write reason.."
        showCount
        maxLength={200}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default AdminReason;
