import styles from "../WebsiteManagement/TypeOfService.module.css";
import { Button, Input, Popconfirm, Space, Switch, Typography } from "antd";
import { useHistory } from "react-router-dom";

import {
  AlipayCircleFilled,
  DeleteOutlined,
  DollarCircleFilled,
  PercentageOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import ModalAddGame from "./Modals/ModalAddMethod";
import { debounce } from "lodash";
import { notifySuccess } from "../../utils/notifications";
import parseErrors from "../../utils/parseServerError";
import { StyledTable } from "../Users/Users";
import methodsAPI from "../../http/methods";
import styled from "@emotion/styled";
import ProfileImage from "../Users/ProfileImage";

const { Title } = Typography;
const { Search } = Input;

const initialModalState = { visible: false, item: null, title: "Add new game" };

const Games = () => {
  const history = useHistory();
  const [modalState, setModalState] = useState(initialModalState);
  const [gamesListData, setGamesListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };

  const getListOfGames = async (searchKeyWord, params) => {
    try {
      setIsLoading(true);
      const res = await methodsAPI().getMethodList(searchKeyWord, params);
      setGamesListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };

  const openModal = (game) => {
    setModalState({ ...initialModalState, visible: true, item: game });
  };

  const viewDetails = (game) => {
    return history.push("/wb/brands/" + game.id);
  };

  const handlePopularGame = async (data) => {
    try {
      await methodsAPI().editMethod({ status: !data.status }, data.id);
      getListOfGames(searchKeyWord, params);
      notifySuccess(`${data.name} is updated.`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    getListOfGames(searchKeyWord, params);
  }, [searchKeyWord, params]);

  console.log({ gamesListData });

  const columns = [
    {
      title: "Logo",
      render: (data) => {
        return (
          <Space
            align={"center"}
            direction={"vertical"}
            style={{ marginBottom: 20, width: 100 }}
          >
            {data?.image ? (
              <StyledImage src={data?.image ?? ""} />
            ) : (
              <AlipayCircleFilled />
            )}
            <ProfileImage
              reload={() => getListOfGames(searchKeyWord, params)}
              editing={true}
              methodID={data?.id}
            />
          </Space>
        );
      },
    },
    {
      title: "Method name",
      dataIndex: "name",
    },

    {
      title: "Active",
      render: (data) => {
        return (
          <Switch
            checked={data?.status ?? false}
            onChange={() => handlePopularGame(data)}
          />
        );
      },
    },
    {
      title: "Fee",
      render: (data) => {
        return (
          <div>
            <b>{data?.fee ?? 0}</b>
            <PercentageOutlined />
          </div>
        );
      },
    },
    {
      title: "Fixed fee ",
      render: (data) => {
        return (
          <Space>
            <b>{data?.fixed_fee_usd ?? 0}</b>
            <DollarCircleFilled />
          </Space>
        );
      },
    },
    {
      title: "Minimal withdraw amount ",
      render: (data) => {
        return (
          <Space>
            <b>{data?.min_withdraw_amount ?? 0}</b>
            <DollarCircleFilled />
          </Space>
        );
      },
    },

    {
      title: "Edit",
      key: "edit",
      render: (game) => {
        return (
          <Space>
            <Button onClick={() => openModal(game)}>Edit method</Button>
            <Popconfirm
              title={"Are you sure to delete " + game.name + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => handleDelete(game)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  const handleDelete = async (item) => {
    try {
      const { id } = item;
      await methodsAPI().deleteMethod(id);
      getListOfGames(searchKeyWord, params);
      notifySuccess(`${item?.name}  is deleted.`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  return (
    <div className={styles.container}>
      <ModalAddGame
        refreshData={() => getListOfGames(searchKeyWord, params)}
        modalState={modalState}
        closeModal={closeModal}
      />
      <h1 className={styles.header}>Withdrawal Methods </h1>

      <div className={styles.addNewContainer}>
        {/*<Search*/}
        {/*  placeholder="Search methods"*/}
        {/*  size="large"*/}
        {/*  allowClear*/}
        {/*  loading={false}*/}
        {/*  onChange={debouncedChangeHandler}*/}
        {/*/>*/}
        <div></div>
        <Button
          shape={"round"}
          onClick={() => openModal()}
          size={"large"}
          icon={<PlusSquareOutlined />}
        >
          Add new method
        </Button>
      </div>
      <StyledTable
        pagination={paginationSettings}
        dataSource={gamesListData}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Games;

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;
