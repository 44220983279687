import usersAPI from "../../../http/users";
import { notifyError, notifySuccess } from "../../../utils/notifications";
import { useEffect, useMemo, useState } from "react";
import { Button, List, Popconfirm, Space, Table } from "antd";
import Search from "antd/es/input/Search";
import { debounce } from "lodash";
import moment from "moment";
import {
  ClockCircleFilled,
  DollarCircleFilled,
  EyeOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  REGULAR_ORDER_STATUSES,
  REGULAR_ORDER_STATUSES_COLORS,
} from "../../../utils/constants";
import { StyledTable } from "../Users";
import { OfferContainer, StyledInfoCard, StyledInfos } from "./NewOrders";
import ModalCreateOffer from "./Modals/CreateOffer";
import ModalOrderInfo from "../../Sales/Modals/ModalOrderInfo";
import parseServerError from "../../../utils/parseServerError";
import { StyledClickableText } from "./AcceptedOrders";
// import PurchaseReviewModal from "./Modals/PurchaseReviewModal";

const RequestLobbySentOffers = ({ userId }) => {
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState({});

  const [modalState, setModalState] = useState({
    isVisible: false,
    item: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const getList = async () => {
    try {
      setIsLoading(true);
      const res = await usersAPI().getRequestLoobySentOrders(
        userId,
        searchKeyWord,
        params
      );
      setData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      console.log({ res }, "regular orders");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notifyError(e?.response?.data);
    }
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  const declineOrder = async (data) => {
    try {
      const res = await usersAPI().cancelRequestOffer(userId, data?.id, {
        status: 2,
      });
      getList();
      notifySuccess("Order canceled");
    } catch (e) {
      parseServerError(e);
    }
  };

  useEffect(() => {
    getList();
  }, [searchKeyWord, params]);

  const viewDetailsModal = (data) => {
    setModalState({ ...modalState, isVisible: true, item: data });
  };

  const closeModal = (data) => {
    setModalState({ isVisible: false });
  };
  const mouseHandle = (id, type) => {
    if (type == 0) {
      setShowDetails({ ...showDetails, [id]: true });
    } else {
      setShowDetails({ ...showDetails, [id]: false });
    }
  };

  const columns = [
    {
      title: "Order info",
      key: "name",
      width: 280,

      render: (data) => {
        return (
          <Space direction="vertical">
            <Space>
              <b>Game:</b>
              <span> {data?.order?.game?.name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b> Date:</b>
              <span> {moment(data.created).format("DD MMM YYYY")}</span>
            </Space>{" "}
            <Space>
              <b> Service:</b>
              <span> {data?.order?.service?.name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b> By:</b>
              <span>
                {" "}
                {data?.order?.user?.first_name +
                  " " +
                  data?.order?.user?.last_name ?? "/"}
              </span>
            </Space>{" "}
          </Space>
        );
      },
    },
    {
      title: "Offer",
      key: "offer",
      render: (data) => {
        return (
          <OfferContainer
            onMouseEnter={() => mouseHandle(data?.id, 0)}
            onMouseLeave={() => mouseHandle(data?.id, 1)}
          >
            <StyledInfos>
              {!showDetails[data?.id] && (
                <div>
                  <b style={{ marginRight: 5 }}>Offer: </b>{" "}
                  {data?.order?.offer_name ?? "/"}{" "}
                </div>
              )}
              {showDetails[data?.id] && (
                <StyledInfoCard>
                  <Space>
                    <Button onClick={() => viewDetailsModal(data)}>
                      Order details{" "}
                    </Button>
                  </Space>
                </StyledInfoCard>
              )}
            </StyledInfos>
            <Popconfirm
              title="Are you sure to decline this order?"
              onConfirm={() => declineOrder(data)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>Cancel offer</Button>
            </Popconfirm>
          </OfferContainer>
        );
      },
    },
  ];

  return (
    <Container>
      {/*<PurchaseReviewModal modalState={modalState} closeModal={closeModal} />*/}
      <ModalOrderInfo modalState={modalState} closeModal={closeModal} />

      <StyledSearch
        placeholder="Search orders"
        size="large"
        allowClear
        loading={false}
        onChange={debouncedChangeHandler}
      />

      <StyledTable
        pagination={paginationSettings}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Container>
  );
};

export default RequestLobbySentOffers;

const StyledSearch = styled(Search)`
  width: 300px;
  margin: 10px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
