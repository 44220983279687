import styles from "./TypeOfService.module.css";
import {
  Button,
  Input,
  Popconfirm,
  Skeleton,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";

import {
  CloudFilled,
  DeleteOutlined,
  EditOutlined,
  PercentageOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import servicesAPI from "../../http/services";
import { useEffect, useMemo, useState } from "react";
import ModalAddService from "./Modals/ModalAddService";
import { notifySuccess } from "../../utils/notifications";
import { debounce } from "lodash";
import parseErrors from "../../utils/parseServerError";
import { StyledTable } from "../Users/Users";
import service_type from "../../assets/icons/servise-type.png";
import add_new_service from "../../assets/icons/ADD-NEW-SERVICE.png";
import service_type_inner from "../../assets/icons/Service-Test-2.png";
import edit_Service from "../../assets/icons/EDIT-SERVICE.png";
import delete_service from "../../assets/icons/DELETE-SERVICE.png";
import DeletePopup from "../DeletePopup/DeletePopup";

const { Title } = Typography;
const { Search } = Input;

const initialModalState = {
  title: "Add New Service",
  visible: false,
  item: null,
};

const TypeOfService = () => {
  const [servicesListData, setServicesListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const [modalStateAddService, setModalStateAddService] =
    useState(initialModalState);

  const addNewService = () =>
    setModalStateAddService({ ...initialModalState, visible: true });

  const editService = (item) =>
    setModalStateAddService({
      ...initialModalState,
      title: "Edit service",
      item,
      visible: true,
    });

  const closeModal = () => setModalStateAddService({ ...initialModalState });

  const getListOfServices = async () => {
    try {
      setIsLoading(true);
      const res = await servicesAPI().getServicesList(searchKeyWord, params);
      setServicesListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      if(res.data.results){
console.log("service details" , res.data.results)
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  const changeStatusOfService = async (servis) => {
    try {
      const { status, id } = servis;
      await servicesAPI().editService({ status: !status }, id);
      getListOfServices();
      notifySuccess(`Status for  ${servis?.name}  changed`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const deleteServis = async (servis) => {
    try {
      const { id } = servis;
      await servicesAPI().deleteService(id);
      notifySuccess(`${servis.name} is deleted.`);
      getListOfServices();
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getListOfServices(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "Service",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Games added",
      dataIndex: "games",
      key: "games",
      render: (game) => {
        return <Tag>{game.length}</Tag>;
      },
    },
    {
      title: "Sale",
      render: (data) => {
        return (
          <div>
            <b>{data?.sale ?? 0}</b>
            <PercentageOutlined />
          </div>
        );
      },
    },
    {
      title: "Popular games",
      dataIndex: "popular_games",
      key: "popular_games",
      render: (popular_games) => {
        return <Tag>{popular_games.length}</Tag>;
      },
    },
    {
      title: "Status",
      render: (data) => {
        return (
          <Switch
            checked={data.status}
            onChange={() => changeStatusOfService(data)}
          />
        );
      },
    },
    {
      title: "Edit",
      key: "edit",
      render: (item) => {
        return (
          <Space>
            <Button
              type={"primary"}
              icon={<EditOutlined />}
              onClick={() => editService(item)}
            >
              Edit
            </Button>
            <Popconfirm
              title={"Are you sure to delete " + item.name + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => deleteServis(item)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );
  console.log("service data", servicesListData);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    // Convert true/false to 1/0
    const value = isChecked ? 1 : 0;
    console.log("value", value);

    // Trigger the API call with the value
    // triggerApi(value);
  };
  const [popupDelete, setPopupDelete] = useState(false);
  const onPopup = (state) => {
    setPopupDelete(state);
  };
  return (
    <div id="service_page_main" className={styles.container}>
      <ModalAddService
        modalState={modalStateAddService}
        closeModal={closeModal}
        refreshData={getListOfServices}
      />
      <div className="flex items-center gap-5">
        <img
          src={service_type}
          alt="service_type"
          className="w-[30px] h-[30px]"
        ></img>
        <h1 className={styles.header}>Service Type </h1>
      </div>

      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search services"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
        {/* <div > */}
        {/* <input class="search_bar" type="text" placeholder="search" loading={false}
          onChange={debouncedChangeHandler}></input> */}
        {/* </div> */}

        {/* <Button
          shape={"round"}
          size={"large"}
          icon={<PlusSquareOutlined />}
          onClick={addNewService}
        > */}
        <button
          className="flex items-center gap-4 new_btn"
          onClick={addNewService}
        >
          <img src={add_new_service}></img>
          Add New service
        </button>
        {/* </Button> */}
      </div>
      {/* <StyledTable
        pagination={paginationSettings}
        dataSource={servicesListData}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      /> */}
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {servicesListData.map((item, index) => (
              <div
                className="flex items-center gap-5 bg-[#03364a] w-[100%] h-[8vw] rounded-lg"
                key={index}
              >
                <div className="flex items-center justify-center left-panel w-4/5 gap-2">
                  <div className="bg-[#052634] p-4 flex justify-center items-center rounded-lg">
                    <img
                      className=""
                      src={service_type_inner}
                      alt="service_type_inner"
                    ></img>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-2">
                    <h1 className="text-white font-bold text-2xl text-left w-full">
                      {item.name}
                    </h1>

                    <div className="flex items-center gap-5">
                      <button
                        className="new_btn_service text-sm font-bold flex items-center gap-1"
                        onClick={() => editService(item)}
                      >
                        <img src={edit_Service} alt="editService"></img>Edit
                        Service
                      </button>

                      {popupDelete ? (
                        <DeletePopup
                          id="deletepopup"
                          style={{ color: "red", transform: "scale(1.3)" }}
                          onDelete={(item) => deleteServis(item)}
                          handleCancel={onPopup}
                          serviceName={item.name}
                          serviceId={item.id}
                          item={item}
                        />
                      ) : null}
                      {/* <Popconfirm
                      title={"Are you sure to delete " + item.name + "?"}
                      icon={
                        <QuestionCircleOutlined
                          style={{ color: "red", transform: "scale(1.3)" }}
                        />
                      }
                      onConfirm={() => deleteServis(item)}
                      okText="Yes"
                      cancelText="No"
                      size={"large"}
                    > */}
                      <button
                        className="danger_btn text-sm font-bold flex items-center gap-1 "
                        onClick={onPopup}
                      >
                        <img src={delete_service} alt="editService"></img>Delete
                        Service
                      </button>
                      {/* </Popconfirm> */}
                    </div>
                  </div>
                </div>
                <div className="flex items-start justify-end right-panel h-full pt-3 gap-3 pr-2 w-2/5 ">
                  <span className="text-[#b1b6b9] font-bold">
                    Service Status
                  </span>
                  <div class="toggle-switch">
                    <input type="checkbox" id="toggle" class="toggle-input" />
                    <label for="toggle" class="toggle-label"></label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      
    </div>
  );
};

export default TypeOfService;
