import { Button, Input, Select, Space, Table } from "antd";
import { debounce } from "lodash";
import { PlusCircleFilled } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import styles from "./RefundRequests.module.css";
import requestLobbyAPI from "../../http/request-lobby";
import ModalCustomOrder from "./Modals/ModalCustomOrder";
import moment from "moment";
import ModalOrderInfo from "./Modals/ModalOrderInfo";
import ModalOffers from "./Modals/ModalOffers";
import ModalOfferDetails from "./Modals/ModalOfferDetails";
import ModalActiveOrderInfo from "./Modals/ModalActiveOrderInfo";
import { StyledTable } from "../Users/Users";

const { Option } = Select;
const { Search } = Input;

const ACTIVE_VIEW_LOBBY = 0;
const REQUEST_VIEW_LOBBY = 1;

const initialModalState = {
  isVisible: false,
  item: null,
};

const WithdrawRequests = () => {
  const [activeListData, setActiveListData] = useState([]);
  const [logListData, setLogListData] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);
  const [orderInfoModal, setOrderInfoModal] = useState(initialModalState);
  const [offersListModal, setOffersListModal] = useState(initialModalState);
  const [offerDetailsModal, setOffersDetailsModal] =
    useState(initialModalState);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [activeView, setActiveView] = useState(ACTIVE_VIEW_LOBBY);
  const [isLoading, setLoading] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    ordering: "-created",
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };

  const getList = async () => {
    try {
      setLoading(true);
      const res = await requestLobbyAPI().getList(searchKeyWord, params);
      setLogListData(res.data.results);
      // console.log(res, "iz refund liste aaa");
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      // parseErrors(e.response.data);
      console.dir(e);
    }
  };

  const getActiveList = async () => {
    try {
      setLoading(true);
      const res = await requestLobbyAPI().getActiveList(searchKeyWord, params);
      setActiveListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });

      console.log({ res }, "Amer");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.dir(e);
    }
  };

  const handleActive = (status) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({ ...params, offset: 0 });
    setActiveView(status);
  };

  const openModal = () => {
    setModalState({ ...modalState, isVisible: true });
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };

  const closeOrderInfoModal = () => {
    setOrderInfoModal(initialModalState);
  };

  const closeOffersListModal = () => {
    setOffersListModal(initialModalState);
  };

  const closeOfferDetailsModal = () => {
    setOffersDetailsModal(initialModalState);
  };

  const closeParentModalAndRefreshData = () => {
    closeOffersListModal();
    if (activeView == ACTIVE_VIEW_LOBBY) {
      getActiveList(searchKeyWord, params);
    } else {
      getList(searchKeyWord, params);
    }
  };

  const openOrderInfoModal = (item) => {
    if (activeView == ACTIVE_VIEW_LOBBY) {
      return setOrderInfoModal({
        ...orderInfoModal,
        isVisible: true,
        item,
        fromActive: true,
      });
    }
    setOrderInfoModal({ ...orderInfoModal, isVisible: true, item });
  };
  const openOffersListModal = (item) => {
    setOffersListModal({ ...offersListModal, isVisible: true, item });
  };
  const openOfferDetailsModal = (item) => {
    setOffersDetailsModal({ ...offerDetailsModal, isVisible: true, item });
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
    if (activeView == ACTIVE_VIEW_LOBBY) {
      getActiveList();
    } else {
      getList();
    }
  }, [searchKeyWord, params]);

  const active_columns = [
    {
      title: "Request info",
      key: "id",
      render: (data) => {
        const { game, service, created } = data;
        return (
          <div className={styles.order_info}>
            <span>
              Game: <b>{game?.name ?? "/"}</b>
            </span>
            <span>
              Service: <b>{service?.name ?? "/"}</b>
            </span>
            <span>
              Date: <b>{moment(created).format("DD MMM YYYY")}</b>
            </span>
          </div>
        );
      },
    },
    {
      title: "Request",
      key: "order_price",
      render: (data) => {
        console.log({ data });
        const { offer_name } = data;
        return (
          <div className={styles.order_info}>
            <span>
              Offer: <b>{offer_name}</b>{" "}
            </span>
            <Space style={{ marginTop: "10px" }}>
              <Button onClick={() => openOrderInfoModal(data)}>
                Order info
              </Button>
              <Button type="primary" onClick={() => openOffersListModal(data)}>
                View offers
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const request_log_columns = [
    {
      title: "Request info",
      key: "id",
      render: (data) => {
        const { order, booster } = data;
        const { game, service, created, buyer } = order;
        console.log({ order });
        return (
          <div className={styles.order_info}>
            <span>
              Game: <b>{game.name}</b>
            </span>
            <span>
              Service: <b>{service ? service : "/"}</b>
            </span>
            <span>
              Date: <b>{moment(created).format("DD MMM YYYY")}</b>
            </span>{" "}
            <span>
              Buyer:{" "}
              <b> {buyer?.first_name.concat(" ").concat(buyer?.last_name)} </b>
            </span>{" "}
            <span>
              Booster:{" "}
              <b>
                {" "}
                {booster?.first_name
                  .concat(" ")
                  .concat(booster?.last_name)}{" "}
              </b>
            </span>
          </div>
        );
      },
    },
    {
      title: "Request",
      key: "order_price",
      render: (data) => {
        const { order } = data;
        const { offer_name } = order;
        return (
          <div className={styles.order_info}>
            <span>
              Offer: <b>{offer_name}</b>{" "}
            </span>
            <Button
              style={{ marginTop: "10px" }}
              onClick={() => openOrderInfoModal(data)}
            >
              Order info
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search request lobby..."
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />

        <Button
          shape="round"
          type="primary"
          onClick={openModal}
          icon={<PlusCircleFilled />}
        >
          Create request
        </Button>
      </div>
      <div className={styles.addNewContainer}>
        <Space>
          <Button
            onClick={() => handleActive(ACTIVE_VIEW_LOBBY)}
            shape="round"
            type={activeView == ACTIVE_VIEW_LOBBY ? "primary" : "dashed"}
          >
            Active
          </Button>
          <Button
            onClick={() => handleActive(REQUEST_VIEW_LOBBY)}
            shape="round"
            type={activeView == REQUEST_VIEW_LOBBY ? "primary" : "dashed"}
          >
            Request log
          </Button>
        </Space>
      </div>
      <StyledTable
        pagination={paginationSettings}
        onChange={handleTableChange}
        loading={isLoading}
        columns={
          activeView == ACTIVE_VIEW_LOBBY ? active_columns : request_log_columns
        }
        dataSource={
          activeView == ACTIVE_VIEW_LOBBY ? activeListData : logListData
        }
      />
      <ModalCustomOrder
        modalState={modalState}
        closeModal={closeModal}
        refreshData={() => {
          if (activeView == ACTIVE_VIEW_LOBBY) {
            getActiveList(searchKeyWord, params);
          } else {
            getList(searchKeyWord, params);
          }
        }}
      />

      {activeView == ACTIVE_VIEW_LOBBY ? (
        <ModalActiveOrderInfo
          modalState={orderInfoModal}
          closeModal={closeOrderInfoModal}
        />
      ) : (
        <ModalOrderInfo
          modalState={orderInfoModal}
          closeModal={closeOrderInfoModal}
        />
      )}

      <ModalOffers
        modalState={offersListModal}
        closeModal={closeOffersListModal}
        selectOffer={openOfferDetailsModal}
      />

      <ModalOfferDetails
        modalState={offerDetailsModal}
        closeModal={closeOfferDetailsModal}
        closeParent={closeParentModalAndRefreshData}
      />
    </>
  );
};

export default WithdrawRequests;
