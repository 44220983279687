import { List } from "antd";
import moment from "moment";
import lodash from "lodash";
import TextArea from "antd/es/input/TextArea";
import { CONTACT_METHODS } from "../../../utils/constants";

const InvitationInfo = ({ item }) => {
  return (
    <div>
      <List header={<h3>1. Order info</h3>} size="large">
        <List.Item>
          <b>Order ID:</b>
          <span>{item?.id ?? ""}</span>
        </List.Item>
        <List.Item>
          <b>Order date:</b>
          <span>
            {item?.created ? moment(item.created).format("DD MMM YYYY") : ""}
          </span>
        </List.Item>{" "}
        <List.Item>
          <b>Game:</b>
          <span>{item?.game_name ?? "/"}</span>
        </List.Item>{" "}
        <List.Item>
          <b>Service:</b>
          <span>{item?.service_name ?? "/"}</span>
        </List.Item>{" "}
        <List.Item>
          <b>Category:</b>
          <span>{item?.category_name ?? "/"}</span>
        </List.Item>{" "}
        <List.Item>
          <b>Product:</b>
          <span>{item?.product_name ?? "/"}</span>
        </List.Item>{" "}
        <List.Item>
          <b>Offer:</b>
          <span>{item?.offer_name ?? "/"}</span>
        </List.Item>{" "}
        <List.Item>
          <b>Seller:</b>
          <span>{item?.seller_name ?? "/"}</span>
        </List.Item>{" "}
      </List>
      <List header={<h3>2. Offer Details</h3>} size="large">
        <List.Item>
          <b>Additional Message:</b>
          <span>
            <TextArea value={item?.additional_comment ?? "/"}></TextArea>
          </span>
        </List.Item>
      </List>{" "}
      {item?.transaction && (
        <List header={<h3>3. Billing Information</h3>} size="large">
          <List.Item>
            <b>First name:</b>
            <span>{item?.transaction?.billing_info?.first_name ?? "/"}</span>
          </List.Item>{" "}
          <List.Item>
            <b>Last name:</b>
            <span>{item?.transaction?.billing_info?.last_name ?? "/"}</span>
          </List.Item>{" "}
          <List.Item>
            <b>Email address:</b>
            <span>{item?.transaction?.billing_info?.email ?? "/"}</span>
          </List.Item>{" "}
          <List.Item>
            <b>Phone:</b>
            <span>{item?.transaction?.billing_info?.phone ?? "/"}</span>
          </List.Item>{" "}
          <List.Item>
            <b>Country:</b>
            <span>{item?.transaction?.billing_info?.country ?? "/"}</span>
          </List.Item>{" "}
          {item?.transaction?.billing_info?.contact_method.length > 0 &&
            item?.transaction?.billing_info?.contact_method.map(
              (contactMethod) => {
                return (
                  <List.Item>
                    <b>{CONTACT_METHODS[contactMethod?.method] ?? "/"}</b>

                    <span>{contactMethod?.info ?? "/"}</span>
                  </List.Item>
                );
              }
            )}
        </List>
      )}
      <List header={<h3>4. Payment method</h3>} size="large">
        <List.Item>
          <b>Payment:</b>
          <span>{item?.transaction?.method?.toUpperCase() ?? "/"}</span>
        </List.Item>{" "}
        <List.Item>
          <b>Price:</b>
          <span>{item?.usd_price ?? ""}</span>
        </List.Item>
      </List>
    </div>
  );
};

export default InvitationInfo;
