import styles from "./SellerRequest.module.css";
import SellerRequestComponent from "../../components/SellerRequest/SellerRequest.js";

const SellerRequest = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Basic Seller Request </h1>
      <SellerRequestComponent />
    </div>
  );
};

export default SellerRequest;
