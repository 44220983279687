import styles from "./WithdrawRequests.module.css";
import WithdrawRequestsComponent from "../../components/Sales/WithdrawRequests.js";

const WithdrawRequests = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Withdraw Requests </h1>
      <WithdrawRequestsComponent />
    </div>
  );
};

export default WithdrawRequests;
