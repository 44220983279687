// Sidebar.js
import React from "react";
import "./Sidebar.css"; // Make sure to import the CSS file
import img from "../../assets/login_pic.png";
import service_type from "../../assets/icons/servise-type.png";
import Brands from "../../assets/icons/Brands.png";
import Offers_Type from "../../assets/icons/offers-type.png";
import Delivery_Options from "../../assets/icons/Delivery Options.png";
import Orders from "../../assets/icons/Orders.png";
import Chats from "../../assets/icons/chat.png";
import Refund_List from "../../assets/icons/Refund-List.png";
import Add_Custom_Order from "../../assets/icons/Add-Custom-Order.png";
import Request_Lobby from "../../assets/icons/Request-Lobby.png";
import Withdrawal_Request from "../../assets/icons/withdraw.png";
import Withdrawal_Methods from "../../assets/icons/withdraw.png";
import All_Users from "../../assets/icons/All-Users.png";
import Buyers from "../../assets/icons/Buyers.png";
import Sellers from "../../assets/icons/Sellers.png";
import Suspended_Users from "../../assets/icons/Suspended-Users.png";
import Seller_Applications from "../../assets/icons/Seller-Applications.png";
import sitemap from "../../assets/icons/sitemap.png";
import Blog_Pages from "../../assets/icons/Blog-Pages.png";
import Help_Center from "../../assets/icons/Help-Center.png";
import Rules from "../../assets/icons/Rules.png";
import Account_Settings from "../../assets/icons/Account-Settings.png";
import logout from "../../assets/icons/logout.png";
import { Link } from "react-router-dom";

const Sidebar = ({ logout_btn }) => {
  document.querySelectorAll(".sidebar__list-item").forEach((item) => {
    item.addEventListener("click", function () {
      document
        .querySelectorAll(".sidebar__list-item")
        .forEach((i) => i.classList.remove("active"));
      this.classList.add("active");
    });
  });

  return (
    <div className="sidebar">
   <div className="sidebar__profile">
   <Link to ="/">  <img src={img} alt="Profile" className="sidebar__profile-img" /></Link>
        <h2 className="sidebar__profile-name">masterloot</h2>
        <p className="sidebar__profile-role">Main Administrator Account</p>
      </div>

      <div className="sidebar__section">
        <h3 className="sidebar__section-title">Categories / Offers</h3>
        <ul className="sidebar__list">
          <Link to="/web-service-page">
            <li className="sidebar__list-item">
              <img src={service_type} alt="service_type"></img>Web Service Page
            </li>
          </Link>
          <Link to="/wb/services">
            <li className="sidebar__list-item">
              <img src={service_type} alt="service_type"></img>Service Type
            </li>
          </Link>
          
          <Link to="/offer-type">
            <li className="sidebar__list-item">
              <img src={Offers_Type} alt="Offers_Type"></img>Offers Type
            </li>
          </Link>
          <Link to="/wb/delivery-options">
            <li className="sidebar__list-item">
              <img src={Delivery_Options} alt="Delivery_Options"></img>Delivery
              Options
            </li>
          </Link>
          <Link to="/wb/brands">
            <li className="sidebar__list-item">
              <img src={Brands} alt="Brands"></img>Brands
            </li>
          </Link>
          <Link to="/filter">
            <li className="sidebar__list-item">
              <img src={Brands} alt="Brands"></img>Filter
            </li>
          </Link>
        </ul>
      </div>

      <div className="sidebar__section">
        <h3 className="sidebar__section-title">Sales / Money Management</h3>
        <ul className="sidebar__list">
          <Link to="/sales/orders">
            <li className="sidebar__list-item">
              <img src={Orders} alt="Orders"></img>Orders
            </li>
          </Link>
          <Link to="/">
            <li className="sidebar__list-item">
              <img src={Chats} alt="Chats"></img>Chats
            </li>
          </Link>
          <Link to="/sales/refund-list">
            <li className="sidebar__list-item">
              <img src={Refund_List} alt="Refund_List"></img>Refund List
            </li>
          </Link>
          <Link to="/">
            <li className="sidebar__list-item">
              <img src={Add_Custom_Order} alt="Add_Custom_Order"></img>Add
              Custom Order
            </li>
          </Link>
          <Link to="/sales/request-lobby">
            <li className="sidebar__list-item">
              <img src={Request_Lobby} alt="Request_Lobby"></img>Request Lobby
            </li>
          </Link>
          <Link to="/sales/withdrawal-requests">
            <li className="sidebar__list-item">
              <img src={Withdrawal_Request} alt="Withdrawal_Request"></img>
              Withdrawal Request
            </li>
          </Link>
          <Link to="/sales/withdrawal/methods">
            <li className="sidebar__list-item">
              <img src={Withdrawal_Methods} alt="Withdrawal_Methods"></img>
              Withdrawal Methods
            </li>
          </Link>
        </ul>
      </div>

      <div className="sidebar__section">
        <h3 className="sidebar__section-title">User Control</h3>
        <ul className="sidebar__list">
          <Link to="/users">
            <li className="sidebar__list-item">
              <img src={All_Users} alt="All_Users"></img>All Users
            </li>
          </Link>
          <Link to="/users">
            <li className="sidebar__list-item">
              <img src={Buyers} alt="Buyers"></img>Buyers
            </li>
          </Link>
          <Link to="/users">
            <li className="sidebar__list-item">
              <img src={Sellers} alt="Sellers"></img>Sellers
            </li>
          </Link>
          <Link to="/users">
            <li className="sidebar__list-item">
              <img src={Suspended_Users} alt="Suspended_Users"></img>Suspended
              Users
            </li>
          </Link>
          <Link to="/seller-request">
            <li className="sidebar__list-item">
              <img src={Seller_Applications} alt="Seller_Applications"></img>
              Seller Applications
            </li>
          </Link>
        </ul>
      </div>

      <div className="sidebar__section">
        <h3 className="sidebar__section-title">Web Content / SEO</h3>
        <ul className="sidebar__list">
          <Link to="/info">
            <li className="sidebar__list-item">
              <img src={sitemap} alt="sitemap"></img>Sitemap Upload
            </li>
          </Link>
          <Link to="/">
            <li className="sidebar__list-item">
              <img src={Blog_Pages} alt="Blog_Pages"></img>Blog Pages
            </li>
          </Link>
        </ul>
      </div>

      <div className="sidebar__section">
        <h3 className="sidebar__section-title">Others</h3>
        <ul className="sidebar__list">
          <Link to="/">
            <li className="sidebar__list-item">
              <img src={Help_Center} alt="Help_Center"></img>Help Center
            </li>
          </Link>
          <Link to="/">
            <li className="sidebar__list-item">
              <img src={Rules} alt="Rules"></img>Rules
            </li>
          </Link>
          <Link to="/">
            <li className="sidebar__list-item">
              <img src={Account_Settings} alt="Account_Settings"></img>Account
              Settings
            </li>
          </Link>
        </ul>
      </div>

      <div className="sidebar__section">
        <ul className="sidebar__list">
          <li className="sidebar__list-item" onClick={logout_btn}>
            <img src={logout} alt="logout"></img>Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
