import { getApiClient } from "../client";

function blogAPI() {
  return {
    addBlog: (blogData) =>
      getApiClient().post("/admin/blog", blogData),
    editBlog: (updatedBlogData, serviceId) =>
      getApiClient().put(`/admin/blog/${serviceId}`, updatedBlogData),
    getBlogs: (search, rules) =>
      getApiClient().get("/admin/blogs", { params: { search, ...rules } }),
    deleteBlog: (blogId) =>
      getApiClient().delete(`/admin/blog/${blogId}`),
    uploadBlogPhoto: (blogId, formData) =>
      getApiClient().put(`/admin/blog/${blogId}`, formData, {
          headers: {
              "content-type": "multipart/form-data",
          },
      }),
    uploadSitemap: (formData) =>
        getApiClient().post(`/admin/upload/file`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        }),
  };
}

export default blogAPI;
