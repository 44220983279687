import { getApiClient } from "../client";

function servicesAPI() {
  return {
    addService: (serviceData) =>
      getApiClient().post("/admin/service", serviceData),
    editService: (updatedServiceData, serviceId) =>
      getApiClient().put(`/admin/service/${serviceId}`, updatedServiceData),
    getServicesList: (search, rules) =>
      getApiClient().get("/admin/services", { params: { search, ...rules } }),
    getServicesProductList: (serviceId) =>
      getApiClient().get(`/admin/service/${serviceId}/products`),
    getServiceDetails: (serviceId) =>
      getApiClient().get(`/admin/service/${serviceId}`),
    deleteService: (serviceId) =>
      getApiClient().delete(`/admin/service/${serviceId}`),
  };
}

export default servicesAPI;
