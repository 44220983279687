import { Tabs } from "antd";
import { useState } from "react";
import CustomOrders from "./CustomOrders";
import RegularOrders from "./RegularOrders";

const { TabPane } = Tabs;

const Orders = ({ userId }) => {
  const [activeTab, setActiveTab] = useState("regular");
  return (
    <>
      <Tabs
        onChange={(tab) => setActiveTab(tab)}
        type="card"
        defaultActiveKey="profile"
      >
        <TabPane tab="Regular orders" key="regular" />
        <TabPane tab="Custom orders" key="custom" />
      </Tabs>
      {activeTab == "regular" && <RegularOrders userId={userId} />}
      {activeTab == "custom" && <CustomOrders userId={userId} />}
    </>
  );
};

export default Orders;
