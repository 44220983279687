import styles from "./Users.module.css";
import {
  Avatar,
  Badge,
  Button,
  Form,
  Input,
  List,
  Popconfirm,
  Select,
  Space,
  Spin,
} from "antd";
import {
  DeleteOutlined,
  MailOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Radio from "antd/es/radio/radio";
import { useEffect, useRef, useState } from "react";
import usersAPI from "../../http/users";
import { notifySuccess } from "../../utils/notifications";
import parseErrors from "../../utils/parseServerError";
import { CONTACT_METHODS } from "../../utils/constants";
import { useHistory } from "react-router";
import ModalSendEmail from "./Modals/ModalSendEmail";
import ProfileImage from "./ProfileImage";
import styled from "@emotion/styled";

const ManageProfile = ({ user, refreshData, loading }) => {
  const [accountStatus, setAccountStatus] = useState(user?.is_active);
  const [modalState, setModalState] = useState({ isVisible: false });
  const [form] = Form.useForm();
  const formRef = useRef();
  const router = useHistory();

  const onFinish = async (values) => {
    try {
      const res = await usersAPI().editUser(values, user?.id);
      refreshData();
      notifySuccess("User account was edited.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const handleAccountStatus = async (status) => {
    setAccountStatus(status);
    try {
      const res = await usersAPI().editUser({ is_active: status }, user?.id);
      refreshData();

      notifySuccess("User account status was changed.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const handleDelete = (user) => {
    try {
      const res = usersAPI().deleteUser(user.id);
      notifySuccess(`User ${user?.first_name} is deleted.`);
      router.push("/users");
    } catch (e) {
      parseErrors(e?.response?.data);
    }
  };

  const openModalEmail = () => {
    setModalState({ ...modalState, isVisible: true, item: user });
  };
  const closeModal = () => setModalState({ isVisible: false });

  useEffect(() => {
    setAccountStatus(user?.is_active);
  }, [user]);

  if (!user) return <></>;
  return (
    <div className={styles["manage-profile-container"]}>
      {loading && <Spin size="large" />}
      {!loading && (
        <>
          <div className={styles["user-container"]}>
            <div className={styles["user-container-infos"]}>
              <Space direction={"vertical"} style={{ marginBottom: 20 }}>
                {/*<Avatar*/}
                {/*  style={{*/}
                {/*    width: "100px",*/}
                {/*    height: "100px",*/}
                {/*    objectFit: "contain !important",*/}
                {/*    border: "1px solid black",*/}
                {/*  }}*/}
                {/*  src={user?.profile_picture?.url ?? ""}*/}
                {/*/>*/}
                {user?.profile_picture?.url ? (
                  <StyledImage src={user?.profile_picture?.url} />
                ) : (
                  <Avatar shape="square" size="large" icon={<UserOutlined />} />
                )}

                <ProfileImage reload={refreshData} userId={user?.id} />
              </Space>
              <b>
                {user?.first_name ?? ""} {user?.last_name ?? ""}
              </b>
              <Space>
                <span>Email</span>
                <b>{user?.email ?? ""}</b>
              </Space>{" "}
              <Space>
                <span>Username</span>
                <b>{user?.username ?? ""}</b>
              </Space>
              <Space>
                <Button icon={<MailOutlined />} onClick={openModalEmail}>
                  Send email
                </Button>
                <Popconfirm
                  title={"Are you sure to delete " + user.first_name + "?"}
                  // title={"Are you sure to delete " + "?"}
                  icon={
                    <QuestionCircleOutlined
                      style={{ color: "red", transform: "scale(1.3)" }}
                    />
                  }
                  onConfirm={() => handleDelete(user)}
                  okText="Delete"
                  cancelText="No"
                  size={"large"}
                >
                  <Button icon={<DeleteOutlined />} danger color={"red"}>
                    Delete user
                  </Button>
                </Popconfirm>
              </Space>
            </div>
            <div className={styles["user-container-infos"]}>
              <b>Boosting stats</b>
              <div className={styles["user-success-rate"]}>
                <Space direction="vertical">
                  <span>Success rate</span>
                  <b
                    style={{ color: "violetblue" }}
                    className={styles["info-value"]}
                  >
                    {user?.success_rate ?? 0}%
                  </b>
                </Space>{" "}
                <Space direction="vertical">
                  <span>Positive feedbacks</span>
                  <b
                    style={{ color: "green" }}
                    className={styles["info-value"]}
                  >
                    {user?.positive_feedback ?? 0}
                  </b>
                </Space>{" "}
                <Space direction="vertical">
                  <span>Negative feedbacks </span>
                  <b style={{ color: "red" }} className={styles["info-value"]}>
                    {user?.negative_feedback ?? 0}
                  </b>
                </Space>
              </div>
            </div>
          </div>
          <div className={styles["user-roles"]}>
            <List size={"large"} header={<h3>Website settings for user</h3>}>
              <List.Item>
                <label>Account status</label>
                <Select
                  onChange={handleAccountStatus}
                  value={accountStatus}
                  style={{ width: "30%" }}
                >
                  <Select.Option value={false}>Suspended</Select.Option>
                  <Select.Option value={true}>Active</Select.Option>
                </Select>
              </List.Item>
            </List>
            <List size={"large"} header={<h3>User role permissions</h3>}>
              <Form
                ref={formRef}
                layout="vertical"
                onFinish={onFinish}
                form={form}
                initialValues={user}
              >
                <List.Item>
                  <label>Roles </label>
                  <Form.Item name={"role"} label={"Roles"}>
                    <Radio.Group value={user?.role}>
                      <Radio value={null}>Admin</Radio>
                      <Radio value={0}>Buyer</Radio>
                      <Radio value={1}>Booster</Radio>
                    </Radio.Group>
                  </Form.Item>
                </List.Item>
                <List.Item>
                  <label>Admin comment </label>
                  <Form.Item name={"admin_comment"} label={"Admin comment"}>
                    <Input.TextArea></Input.TextArea>
                  </Form.Item>
                </List.Item>
                <Form.Item>
                  <List.Item>
                    <label></label>
                    <Button type="primary" htmlType="submit">
                      Save changes
                    </Button>
                  </List.Item>
                </Form.Item>
              </Form>
            </List>
            <List size={"large"} header={<h3>User info</h3>}>
              <Form
                ref={formRef}
                layout="vertical"
                onFinish={onFinish}
                form={form}
                initialValues={{ ...user, birthday: new Date(user?.birthday) }}
              >
                <List.Item>
                  <Form.Item label="First name" name={"first_name"}>
                    <Input />
                  </Form.Item>{" "}
                  <Form.Item label="Last name" name={"last_name"}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Country " name={"country"}>
                    <Input />
                  </Form.Item>{" "}
                  <Form.Item label="Address" name={"address"}>
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save changes
                    </Button>
                  </Form.Item>
                </List.Item>
              </Form>
            </List>
            <List size="large" header={<h3>Contacts</h3>}>
              <Form
                name="contact_method"
                onFinish={onFinish}
                form={form}
                autoComplete="off"
                initialValues={user}
              >
                <Form.List name="contact_method" style={{ width: "100%" }}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            margin: "8px 0",
                            width: "100%",
                            justifyContent: "space-between",
                            gap: "10px",
                            alignItems: "baseline",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "method"]}
                            fieldKey={[fieldKey, "method"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing method name",
                              },
                            ]}
                            style={{ width: "100%" }}
                          >
                            {/*<Input*/}
                            {/*  placeholder="First Name"*/}
                            {/*  style={{ width: "100%" }}*/}
                            {/*/>*/}
                            <Select>
                              <Select.Option value={0}>
                                {CONTACT_METHODS[0]}
                              </Select.Option>
                              <Select.Option value={1}>
                                {CONTACT_METHODS[1]}
                              </Select.Option>
                              <Select.Option value={2}>
                                {CONTACT_METHODS[2]}
                              </Select.Option>
                              <Select.Option value={3}>
                                {CONTACT_METHODS[3]}
                              </Select.Option>
                              <Select.Option value={4}>
                                {CONTACT_METHODS[4]}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "info"]}
                            fieldKey={[fieldKey, "info"]}
                            rules={[
                              { required: true, message: "Missing info" },
                            ]}
                            style={{ width: "100%" }}
                          >
                            <Input
                              placeholder="Info.."
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add contact
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <List.Item>
                  <label></label>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save changes
                    </Button>
                  </Form.Item>
                </List.Item>
              </Form>
            </List>
          </div>
        </>
      )}
      <ModalSendEmail closeModal={closeModal} modalState={modalState} />
    </div>
  );
};

export default ManageProfile;

const StyledImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
`;
