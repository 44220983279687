import usersAPI from "../../../http/users";
import { notifyError } from "../../../utils/notifications";
import { useEffect, useMemo, useState } from "react";
import { Button, Space, Table } from "antd";
import Search from "antd/es/input/Search";
import { debounce } from "lodash";
import moment from "moment";
import {
  ClockCircleFilled,
  DollarCircleFilled,
  EyeOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  REGULAR_ORDER_STATUSES,
  REGULAR_ORDER_STATUSES_COLORS,
} from "../../../utils/constants";
import ModalOrderInfo from "../../Sales/Modals/ModalOrderInfo";
import ModalRefundListOrderInfo from "../../Sales/Modals/ModalRefundListOrderInfo";
import ModalActiveOrderInfo from "../../Sales/Modals/ModalActiveOrderInfo";
import ModalAllOrderInfos from "../../Sales/Modals/ModalAllOrderInfos";
import { StyledTable } from "../Users";

const AcceptedOrders = ({ userId }) => {
  const [data, setData] = useState([]);
  const [modalState, setModalState] = useState({
    isVisible: false,
    item: null,
  });
  const [showDetails, setShowDetails] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const getList = async () => {
    try {
      setIsLoading(true);
      const res = await usersAPI().accepterOrders(
        userId,
        searchKeyWord,
        params
      );
      setData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      console.log({ res }, "regular orders");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notifyError(e?.response?.data);
    }
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
    getList();
  }, [searchKeyWord, params]);

  const viewDetailsModal = (data) => {
    setModalState({ ...modalState, isVisible: true, item: data });
  };

  const closeModal = (data) => {
    setModalState({ isVisible: false });
  };

  const mouseHandle = (id, type) => {
    if (type == 0) {
      setShowDetails({ ...showDetails, [id]: true });
    } else {
      setShowDetails({ ...showDetails, [id]: false });
    }
  };

  const columns = [
    {
      title: "Order info",
      key: "name",
      width: 280,

      render: (data) => {
        return (
          <Space direction="vertical">
            <Space>
              <b> Order ID:</b>
              <span> {data?.id ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b>Game:</b>
              <span> {data?.game_name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b> Order date:</b>
              <span> {moment(data.created).format("DD MMM YYYY")}</span>
            </Space>{" "}
            <Space>
              <b> Seller:</b>
              <span> {data?.seller_name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b> Buyer:</b>
              <span> {data.buyer_name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b> Price:</b>
              <Space>
                {" "}
                <DollarCircleFilled />
                <span style={{ color: "#22c58b" }}>
                  {data?.booster_price_usd ?? "/"}
                </span>
              </Space>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Offer",
      key: "offer",
      render: (data) => {
        return (
          <OfferStyledContainer
            onMouseEnter={() => mouseHandle(data?.id, 0)}
            onMouseLeave={() => mouseHandle(data?.id, 1)}
          >
            <Space direction={"vertical"} style={{ width: "100%" }}>
              <Space>
                <b>Offer: </b>
                <span>{data?.offer_name ?? "/"}</span>
              </Space>
              <StatusContainer
                style={{
                  // background: REGULAR_ORDER_STATUSES_COLORS[data?.status ?? 0],
                  border: `1px solid ${
                    showDetails[data?.id]
                      ? "#1aa2f9"
                      : REGULAR_ORDER_STATUSES_COLORS[data?.status ?? 0]
                  } `,
                }}
              >
                {!showDetails[data?.id] && (
                  <Space>
                    {data?.status == 0 ? (
                      <EyeOutlined
                        style={{
                          color:
                            REGULAR_ORDER_STATUSES_COLORS[data?.status ?? 0],
                        }}
                      ></EyeOutlined>
                    ) : data?.status > 2 ? (
                      <FileDoneOutlined
                        style={{
                          color:
                            REGULAR_ORDER_STATUSES_COLORS[data?.status ?? 0],
                        }}
                      />
                    ) : (
                      <ClockCircleFilled
                        style={{
                          color:
                            REGULAR_ORDER_STATUSES_COLORS[data?.status ?? 0],
                        }}
                      />
                    )}
                    <b
                      style={{
                        color: REGULAR_ORDER_STATUSES_COLORS[data?.status ?? 0],
                      }}
                    >
                      {" "}
                      {REGULAR_ORDER_STATUSES[data?.status ?? 0]}
                    </b>
                  </Space>
                )}

                {showDetails[data?.id] && (
                  <StyledClickableText onClick={() => viewDetailsModal(data)}>
                    View order info
                  </StyledClickableText>
                )}
              </StatusContainer>
            </Space>
          </OfferStyledContainer>
        );
      },
    },
  ];

  return (
    <>
      <ModalAllOrderInfos
        modalState={modalState}
        closeModal={closeModal}
        refreshData={getList}
      />
      <StyledSearch
        placeholder="Search orders"
        size="large"
        allowClear
        loading={false}
        onChange={debouncedChangeHandler}
      />

      <StyledTable
        pagination={paginationSettings}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AcceptedOrders;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #f1f1f6;
  border: solid 1px #e0e5ec;
  border-radius: 6px;
  height: 58px;
  width: 100%;
`;

const StyledSearch = styled(Search)`
  width: 300px;
  margin: 10px 0;
`;

export const OfferStyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledClickableText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  color: #1aa2f9;
  font-size: 14px;
  font-weight: 800;
`;
