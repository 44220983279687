import { getApiClient } from "../client";

function discountsAPI() {
  return {
    addDiscount: (discountData) =>
      getApiClient().post("/admin/discount", discountData),
    editDiscount: (updatedDiscountData, discountId) =>
      getApiClient().put(`/admin/discount/${discountId}`, updatedDiscountData),
    getDiscountsList: (search, rules) =>
      getApiClient().get("/admin/discounts", {
        params: {
          search,
          ...rules,
        },
      }),
    getDiscountDetails: (discountId) =>
      getApiClient().get(`/admin/discount/${discountId}`),
    deleteDiscount: (discountId) =>
      getApiClient().delete(`/admin/discount/${discountId}`),
  };
}

export default discountsAPI;
