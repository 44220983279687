import { getApiClient } from "../client";

function categoriesAPI() {
  return {
    getCategories: (gameId, serviceId, limit=20, offset, search='') => getApiClient().get(`/admin/game/${gameId}/service/${serviceId}/categories?limit=${limit}&offset=${offset}&search=${search}`),
    addCategory: (categoryData) =>
      getApiClient().post("/admin/category", categoryData),
    editCategory: (updatedCategoryData, categoryId) =>
      getApiClient().put(`/admin/category/${categoryId}`, updatedCategoryData),
    getCategoryList: (gameId, serviceId, extra) => getApiClient().get(`/admin/categories?status=true&game=${gameId}&service=${serviceId}`, {
        params: {
          ...extra
        },
      }),
    getCategoryDetails: (categoryId) =>
      getApiClient().get(`/admin/category/${categoryId}`),
    deleteCategory: (categoryId) =>
      getApiClient().delete(`/admin/category/${categoryId}`),
  };
}

export default categoriesAPI;
