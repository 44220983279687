import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Switch
} from "antd";
import { useEffect, useMemo, useRef } from "react";
import discountAPI from "../../../http/discounts";
import { notifySuccess } from "../../../utils/notifications";
import moment from "moment";
import parseErrors from "../../../utils/parseServerError";

const { Item, useForm } = Form;
const { Group } = Radio;
const { RangePicker } = DatePicker;

const ModalModifyDiscount = ({ modalState, closeModal, refreshData }) => {
  const [form] = useForm();
  const formRef = useRef();

  const options = [
    { name: "Use percentage", id: 0 },
    { name: "Use specific price", id: 1 },
  ];

  const onFinish = (values) => {
    if (modalState.item) {
      console.log("Edit", modalState.item);
      editExistingDiscount(
        { ...modalState.item, ...values },
        modalState?.item?.discount_code
      );
    } else {
      const discountData = {
        ...values,
        start_date: values.dates[0],
        end_date: values.dates[1],
      };
      delete discountData?.dates;
      addNewDiscount(discountData);
    }
  };

  const formatOptions = () => {
    const result = options.map((option) => {
      return {
        label: option.name,
        value: option.id,
      };
    });
    return result;
  };

  const memoizedOptions = useMemo(() => formatOptions(), [options]);

  const editExistingDiscount = async (discountData, id) => {
    try {
      await discountAPI().editDiscount(
        {
          ...discountData,
          start_date: moment(discountData.dates[0]),
          end_date: moment(discountData.dates[1]),
        },
        id
      );
      refreshData();
      closeModal();
      notifySuccess("Discount modified.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const addNewDiscount = async (discountData) => {
    try {
      await discountAPI().addDiscount(discountData);
      refreshData();
      closeModal();
      notifySuccess("New discount added.");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  function disabledDate(current) {
    return current && current < moment().endOf("day");
  }

  useEffect(() => {
    form.resetFields();
  }, [modalState]);

  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState.title}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          {modalState?.item ? "Edit discount" : "Add discount"}
        </Button>,
      ]}
    >
      <Form
        initialValues={modalState.item}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Item
          name="name"
          label="Discount name"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input discount name!" }]}
        >
          <Input placeholder="Enter discount name" />
        </Item>

        <Item
          name="discount_type"
          label="Discounting"
          rules={[{ required: true, message: "Please select discount type!" }]}
        >
          <Group
            checked={modalState?.item?.discount_type}
            options={memoizedOptions}
          />
        </Item>
        <Item shouldUpdate>
          {() =>
            form.getFieldValue("discount_type") == 1 ? (
              <Form.Item
                name="discount_value"
                label="Discount amount"
                type="number"
                required
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: "Please input discount amount in USD ($)!",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  defaultValue={0}
                  placeholder="Please input discount amount in USD ($)!"
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="discount_value"
                label="Discount amount"
                type="number"
                required
                tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message: "Please input discount amount!",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  defaultValue={0}
                  placeholder="Enter discount amount in percentage %"
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              </Form.Item>
            )
          }
        </Item>
        <Item
          name="dates"
          label="Discount time"
          required
          initialValue={[
            moment(modalState?.item?.start_date),
            moment(modalState?.item?.end_date),
          ]}
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              message: "Please select discount start date and end date!",
            },
          ]}
        >
          <RangePicker style={{ width: "100%" }} disabledDate={disabledDate} />
        </Item>

        <Item
          name="max_limit"
          label="Max using limit"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input game name!" }]}
        >
          <Input placeholder="Enter game name" />
        </Item>
        <Item
          name="active"
          label="Active"
          tooltip="This field is for discount activation"
          valuePropName="checked"
        >
          <Switch />
        </Item>
      </Form>
    </Modal>
  );
};

export default ModalModifyDiscount;
