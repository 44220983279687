import styles from "./RefundRequest.module.css";
import RefundRequestsComponent from "../../components/Sales/RefundRequests.js";

const RefundRequests = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Refund Requests List </h1>
      <RefundRequestsComponent />
    </div>
  );
};

export default RefundRequests;
