import { Divider, Form, InputNumber, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import usersAPI from "../../../http/users";
import parseErrors from "../../../utils/parseServerError";
import TextArea from "antd/es/input/TextArea";
import { notifyError, notifySuccess } from "../../../utils/notifications";
import ordersAPI from "../../../http/orders";
import { CheckCircleOutlined, DollarCircleFilled } from "@ant-design/icons";
import { selectAll, StyledSpace } from "./ModalCustomOrder";

const { Item } = Form;

const InvitationForm = ({
  storedValues,
  saveValues,
  item,
  closeModal,
  formRef,
  refreshData,
  editing,
}) => {
  const [buyersList, setBuyersList] = useState([]);
  const [formChanges, setFormChanges] = useState(null);
  const [extraValue, setExtraValue] = useState(storedValues?.boostrooms_share);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (editing) {
      resendOrderInvitation(values);
    } else {
      sendInvitation(values);
    }
  };

  const handlePrice = (value) => {
    setExtraValue(value);
  };

  const sendInvitation = async () => {
    try {
      if (!storedValues?.booster_price_usd)
        return notifyError(
          "Please fill all fields correctly,price should be a number."
        );
      const res = await ordersAPI().createOrderInvitation({
        order: item?.id,
        ...storedValues,
        booster_price_usd: parseFloat(item?.usd_price) - parseFloat(extraValue),
      });
      refreshData();
      closeModal();
      saveValues(null);
      notifySuccess("Send invitation");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };
  const resendOrderInvitation = async (values) => {
    try {
      const res = await ordersAPI().editOrderInvitation({
        order: item.id,
        boosters: values.boosters,
        booster_price_usd:
          item?.order_invitation?.order_invitation[0]?.booster_price_usd ?? 0,
        ...values,
      });
      refreshData();
      closeModal();
      saveValues(null);
      notifySuccess("Invitation resend");
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  console.log({ item });

  const formatSelectUsers = (items) => {
    const result = items.map((item) => {
      return {
        label: item.username,
        value: item.id,
      };
    });
    return result;
  };

  const loadUsersList = async (state) => {
    try {
      const res = await usersAPI().invitationList(item?.game, item?.service);
      state(res.data);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    loadUsersList(setBuyersList);
  }, []);

  const memoizedBuyers = useMemo(
    () => formatSelectUsers(buyersList),
    [buyersList]
  );

  useEffect(() => {
    saveValues(form.getFieldsValue());
  }, [formChanges]);

  let initialValues = {};

  const handleFormChanges = (values) => {
    setFormChanges(values);
  };

  if (storedValues) {
    initialValues = storedValues;
  }

  if (editing) {
    initialValues = item;
  }

  return (
    <Form
      initialValues={{
        ...item,
        boosters: item?.order_invitation?.order_invitation.map(
          (item) => item.booster_id
        ),
      }}
      ref={formRef}
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onChange={handleFormChanges}
      shouldUpdate
    >
      <Item
        name="boosters"
        label="Invite booster"
        required
        tooltip="This is a required field"
        rules={[{ required: true, message: "Please select service!" }]}
      >
        <Select
          mode="multiple"
          dropdownRender={(menu) => (
            <div>
              {menu}
              {memoizedBuyers.length > 1 && (
                <>
                  <Divider style={{ margin: "4px 0" }} />
                  <StyledSpace
                    size={"small"}
                    align="center"
                    onClick={() => selectAll(form, "boosters", memoizedBuyers)}
                  >
                    <CheckCircleOutlined />
                    <b>Select all</b>
                  </StyledSpace>
                </>
              )}
            </div>
          )}
        >
          {memoizedBuyers.map((item) => {
            return (
              <Select.Option value={item.value}>{item.label}</Select.Option>
            );
          })}
        </Select>
      </Item>
      {!editing && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Space direction={"vertical"}>
            <label>Order price</label>
            <b>${item?.usd_price ?? 0}</b>
          </Space>
          <Item
            name="booster_price_usd"
            label="Boostrooms share"
            required
            rules={[{ required: true, message: "Please enter valid amount!" }]}
          >
            <InputNumber min={0} onChange={handlePrice} />
          </Item>{" "}
          <Space direction={"vertical"}>
            <label>Boosters share</label>
            <b>
              $
              {(parseFloat(item?.usd_price) - parseFloat(extraValue)).toFixed(
                2
              )}
            </b>
          </Space>{" "}
        </div>
      )}
      {!editing && (
        <Item name="admin_note" label="Admin note">
          <TextArea></TextArea>
        </Item>
      )}
      {editing && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Space direction={"vertical"}>
            <b>Order price</b>
            <Space>
              <DollarCircleFilled />
              <b>{item.usd_price}</b>
            </Space>
          </Space>
          <Space direction={"vertical"}>
            <b>Boostrooms share</b>
            <Space>
              <DollarCircleFilled />
              <b>
                {(
                  parseFloat(item.usd_price) -
                  parseFloat(
                    item.order_invitation?.order_invitation[0]
                      ?.booster_price_usd
                  )
                ).toFixed(2)}
              </b>
            </Space>{" "}
          </Space>
          <Space direction={"vertical"}>
            <b>Boosters price</b>
            <Space>
              <DollarCircleFilled />
              <b>
                {parseFloat(
                  item.order_invitation?.order_invitation[0]?.booster_price_usd
                ).toFixed(2)}
              </b>
            </Space>
          </Space>
        </div>
      )}
    </Form>
  );
};

export default InvitationForm;
