import { Button, Input, Space, Table, Tag, Tooltip } from "antd";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DollarOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import usersApi from "../../http/users/";
import moment from "moment";
import styles from "./Users.module.css";
import styled from "@emotion/styled";

const { Search } = Input;

const Users = () => {
  const router = useHistory();
  const [users, setUsers] = useState([]);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeView, setActiveView] = useState(null);
  const [is_suspended, setIsSuspended] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    defaultCurrent: 1,
    defaultPageSize: 5,
    pageSizeOptions: [5,10,20,50,100],
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPaginationSettings({ ...paginationSettings, current, pageSize });
    setParams({
      ...params,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    });
  };

  const getListOfUsers = async (
    searchKeyWord = "",
    activeView,
    is_suspended,
    params
  ) => {
    try {
      setLoading(true);
      const res = await usersApi().getUsersList(
        searchKeyWord,
        activeView,
        is_suspended,
        params
      );
      setUsers(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setLoading(false);
    } catch (e) {
      console.dir(e);
      setLoading(false);
    }
  };

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1),
    });
    setSearchKeyWord(event.target.value);
  };

  const handleActive = (type) => {
    if (type == 2) {
      setIsSuspended(!is_suspended);
    } else {
      setActiveView(type);
    }
  };

  const editUser = (userId) => {
    router.push(`/users/${userId}`);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
        getListOfUsers(searchKeyWord, activeView, is_suspended, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, activeView, is_suspended, params]);

  const columns = [
    {
      title: "Account ID",
      key: "username",
      render: (data) => {
        const { is_suspended, id } = data;

        if (is_suspended)
          return (
            <span>
              #{id} <span style={{ color: "red" }}>(Suspended)</span>
            </span>
          );
        return (
          <span>
            #{id} <span style={{ color: "green" }}>(Active)</span>
          </span>
        );
      },
    },
    {
      title: "Username",
      key: "username",
      dataIndex: "username",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      render: (role) => {
        if (role == 0) {
          return <Tag color="#f50">Buyer </Tag>;
        } else if (role == 1) {
          return <Tag color="#87d068">Booster </Tag>;
        } else {
          return <Tag color="#108ee9">Admin </Tag>;
        }
      },
    },
    {
      title: "Registered on",
      key: "",
      dataIndex: "date_joined",
      render: (date) => {
        return (
          <span className={styles.start_date}>
            {moment(date).format("D MMM YYYY")}{" "}
          </span>
        );
      },
    },
    {
      title: "Last activity",
      key: "last_activity",
      dataIndex: "last_login",
      render: (date) => {
        if (date)
          return (
            <span className={styles.last_activity}>
              {moment(date).format("D MMM YYYY")}{" "}
            </span>
          );
        return <span className={styles.unknown_date}>Unknown</span>;
      },
    },
    {
      title: "Money earned",
      key: "money_earned",
      dataIndex: "money_earned",
      render: (data) => {
        return (
          <Space>
            <ArrowUpOutlined style={{ color: "green" }} />
            <span className={styles.money_earned}>${data}</span>
          </Space>
        );
      },
    },
    {
      title: "Money spent",
      key: "money_spent",
      dataIndex: "money_spent",
      render: (data) => {
        return (
          <Space>
            <ArrowDownOutlined style={{ color: "red" }} />
            <span className={styles.money_spent}>${data}</span>
          </Space>
        );
      },
    },

    {
      title: "Actions",
      key: "edit",
      render: (data) => {
        return (
          <Tooltip title="Edit user">
            <Button
              onClick={() => editUser(data.id)}
              shape="circle"
              icon={<UserSwitchOutlined />}
            />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search users"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
        <Space>
          <Button
            onClick={() => handleActive(null)}
            shape="round"
            type={activeView == null ? "primary" : "dashed"}
          >
            All{" "}
          </Button>
          <Button
            onClick={() => handleActive(0)}
            shape="round"
            type={activeView == 0 ? "primary" : "dashed"}
          >
            Buyers
          </Button>
          <Button
            onClick={() => handleActive(1)}
            shape="round"
            type={activeView == 1 ? "primary" : "dashed"}
          >
            Sellers
          </Button>
          <Button
            onClick={() => handleActive(2)}
            shape="round"
            type={is_suspended ? "danger" : "dashed"}
          >
            {activeView == 1 && "Suspended sellers"}
            {activeView == 0 && "Suspended buyers"}
            {activeView == null && "Suspension list"}
          </Button>
        </Space>
      </div>
      <StyledTable
        pagination={paginationSettings}
        onChange={handleTableChange}
        columns={columns}
        loading={loading}
        dataSource={users}
      />
    </>
  );
};

export default Users;

export const StyledTable = styled(Table)`
  .ant-table-tbody {
    width: 100% !important;
    background: white !important;
  }
  .ant-table-content {
    overflow-x: auto !important;
  }
`;
