import { Button, Input, Select, Space, Table, Tag, Tooltip } from "antd";
import { debounce } from "lodash";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import styles from "./SellerRequest.module.css";
import boosterRequestsAPI from "../../http/booster-offers";
import RequestInfo from "./Modals/RequestInfo";
import { notifySuccess } from "../../utils/notifications";
import parseErrors from "../../utils/parseServerError";
import { StyledTable } from "../Users/Users";

const { Option } = Select;
const { Search } = Input;

const initialModalState = {
  isVisible: false,
  item: null,
};

const SellerRequest = () => {
  const [data, setData] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [loading, setLoading] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    defaultCurrent: 1,
    defaultPageSize: 5,
    pageSizeOptions: [5,10,20,50,100],
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPaginationSettings({ ...paginationSettings, current, pageSize });
    setParams({
      ...params,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    });
  };

  const getList = async (searchKeyWord = "", params) => {
    try {
      setLoading(true);
      const res = await boosterRequestsAPI().getList(searchKeyWord, params);
      setData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.dir(e);
    }
  };

  const handleStatusChange = async (value, item) => {
    try {
      const res = await boosterRequestsAPI().edit(
        { ...item, status: value },
        item.id
      );
      getList(searchKeyWord, params);

      notifySuccess("Status is changed.");
    } catch (e) {
      console.dir(e);
      parseErrors(e.response.data);
    }
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };

  const openModal = (item) => {
    setModalState({ ...initialModalState, isVisible: true, item });
  };

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1),
    });
    setSearchKeyWord(event.target.value);
  };
  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );
  useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
    getList(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "User",
      key: "user",
      render: (data) => {
        const { user } = data;
        return (
          <span>
            {user?.first_name ?? ""} {user?.last_name ?? ""}{" "}
          </span>
        );
      },
    },
    {
      title: "Username",
      key: "username",
      render: (data) => {
        const { user } = data;

        return <span style={{ color: "green" }}>{user?.username} </span>;
      },
    },
    {
      title: "Email Address",
      key: "",
      render: (data) => {
        const { user } = data;

        return <span>{user?.email ?? "/"} </span>;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (data) => {
        const { status } = data;
        if (status == 1) {
          return (
            <Tag color="green">
              <Space>
                <b>Approved</b>
              </Space>
            </Tag>
          );
        }

        if (status == 2) {
          return (
            <Tag color="red">
              <Space>
                <CloseOutlined /> <b>Declined</b>{" "}
              </Space>{" "}
            </Tag>
          );
        }
        return (
          <Select
            value={status}
            onChange={(value) => handleStatusChange(value, data)}
          >
            <Option style={{ color: "orange" }} value={0}>
              Pending
            </Option>
            <Option style={{ color: "green" }} value={1}>
              Approved
            </Option>
            <Option style={{ color: "red" }} value={2}>
              Declined
            </Option>
          </Select>
        );
      },
    },

    {
      title: "Actions",
      key: "edit",
      render: (data) => {
        return (
          <Tooltip title="View request">
            <Button
              onClick={() => openModal(data)}
              shape="circle"
              icon={<EyeOutlined />}
            />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.searchContainer}>
        <Search
          placeholder="Search"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />
      </div>
      <RequestInfo
        changeStatus={handleStatusChange}
        modalState={modalState}
        closeModal={closeModal}
      />
      <StyledTable
        pagination={paginationSettings}
        onChange={handleTableChange}
        loading={loading}
        columns={columns}
        dataSource={data}
      />
    </>
  );
};

export default SellerRequest;
