import { Button, List, Modal, Space } from "antd";
import { DollarCircleFilled } from "@ant-design/icons";

const PurchaseReviewModal = ({ modalState, closeModal }) => {
  if (modalState.item == null) return <></>;

  return (
    <Modal
      width={1080}
      footer={[
        <Button type="round" key="back" onClick={closeModal}>
          Close details
        </Button>,
      ]}
      onCancel={closeModal}
      visible={modalState.isVisible}
      title={"Viewing details"}
    >
      <List size={"large"}>
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Order ID:</b>
            <span>{modalState?.item?.id ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Game:</b>
            <span>{modalState?.item?.game_name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Service:</b>
            <span>{modalState?.item?.service_name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Offer:</b>
            <span>{modalState?.item?.offer_name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Seller:</b>
            <span>
              {modalState?.item?.seller_name ?? "/modalState?.item?."}{" "}
            </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Buyer:</b>
            <span>{modalState?.item?.buyer_name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Price:</b>
            <Space>
              <DollarCircleFilled />
              <span>{modalState?.item?.transaction?.amount ?? "/"} </span>
            </Space>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Payment method:</b>
            <span>{modalState?.item?.transaction?.method ?? "/"} </span>
          </div>
        </List.Item>{" "}
      </List>
    </Modal>
  );
};
export default PurchaseReviewModal;
