import { Button, List, Modal, Space } from "antd";
import { DollarCircleFilled } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import {
  REFUND_ACCEPTED,
  REFUND_DECLINED,
  REFUND_PENDING,
} from "../../../utils/constants";

const ModalRefundListOrderInfo = ({ modalState, closeModal }) => {
  if (modalState.item == null) return <></>;

  console.log({ modalState });
  const { game, offer_name, service, user, id } = modalState.item;
  return (
    <Modal
      width={1080}
      footer={[
        <Button type="round" key="back" onClick={closeModal}>
          Close order details
        </Button>,
      ]}
      onCancel={closeModal}
      visible={modalState.isVisible}
      title={"Viewing order"}
    >
      <List size="large">
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Order ID:</b>
            <span>{id} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Created:</b>
            <span>
              {moment(modalState?.item?.created).format("DD MMM YYYY")}{" "}
            </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Buyer name:</b>
            <span>{modalState?.item?.buyer_name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Buyer refund price:</b>
            <Space>
              {modalState?.item?.buyer_refund_price ?? "/"}
              <DollarCircleFilled />
            </Space>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Order price:</b>
            <Space>
              {modalState?.item?.order_price ?? "/"}
              <DollarCircleFilled />
            </Space>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Admin refund price:</b>
            <Space>
              {modalState?.item?.admin_refund_price ?? "/"}
              <DollarCircleFilled />
            </Space>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Booster refund price:</b>
            <Space>
              {modalState?.item?.booster_refund_price ?? "/"}
              <DollarCircleFilled />
            </Space>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Player name:</b>
            <span>{modalState?.item?.player_name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Message:</b>
            <TextArea
              style={{ width: "50%" }}
              value={modalState?.item?.message ?? "No message."}
            >
              {service?.name ?? "/"}{" "}
            </TextArea>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Approval:</b>
            {modalState?.item?.status == REFUND_PENDING && (
              <span style={{ color: "grey" }}> Refund pending</span>
            )}{" "}
            {modalState?.item?.status == REFUND_ACCEPTED && (
              <span style={{ color: "green" }}> Refund accepted</span>
            )}{" "}
            {modalState?.item?.status == REFUND_DECLINED && (
              <span style={{ color: "red" }}> Refund declined</span>
            )}
          </div>
        </List.Item>{" "}
      </List>
    </Modal>
  );
};
export default ModalRefundListOrderInfo;
