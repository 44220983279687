import { Button, Form, Input, InputNumber, Modal } from "antd";
import { useRef, useState } from "react";
import requestLobbyAPI from "../../../http/request-lobby";
import { notifyError, notifySuccess } from "../../../utils/notifications";

const { Item } = Form;
const { TextArea } = Input;

const ModalOfferDetails = ({ modalState, closeModal, closeParent }) => {
  console.log({ offerDetails: modalState.item });
  const [form] = Form.useForm();
  const formRef = useRef();
  const [share, setShare] = useState(0);
  if (modalState.item == null) return <></>;

  const onFinish = async (values) => {
    console.log({ values });
    try {
      const data = {
        buyer_price_usd: parseFloat(usd_price) + parseFloat(share),
        buyer_note: values?.buyer_note ?? "",
        status: 1,
      };
      const res = await requestLobbyAPI().acceptOffer(
        modalState?.item?.id,
        data
      );
      closeModal();
      closeParent();
      notifySuccess("Offer accepted.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };

  const { usd_price } = modalState.item;

  const calculatePrice = (value) => {
    setShare(value);
  };

  return (
    <Modal
      width={1080}
      footer={[
        <Button key="back" onClick={closeModal}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          Accept offer
        </Button>,
      ]}
      title={"Selecting boosters offer"}
      onCancel={closeModal}
      visible={modalState.isVisible}
    >
      <Form ref={formRef} form={form} layout="vertical" onFinish={onFinish}>
        <Item name="buyer_note" label="Note for buyer">
          <TextArea />
        </Item>
        <Item
          name="share"
          label="Boostroom share"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input value!" }]}
          style={{ width: "100%" }}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            defaultValue={0}
            onChange={calculatePrice}
          />
        </Item>
      </Form>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly ",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Booster price</span>
          <b style={{ color: "green" }}>{usd_price} $</b>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Buyers price</span>
          <b style={{ color: "green" }}>
            {(parseFloat(usd_price) + parseFloat(share)).toFixed(2)} $
          </b>
        </div>
      </div>
    </Modal>
  );
};
export default ModalOfferDetails;
