import { Button, List, Modal } from "antd";

const ModalPaymentMethodInfo = ({ modalState, closeModal }) => {
  if (!modalState.item) return <></>;
      console.log(modalState.item);

  return (
    <Modal
      width={1080}
      footer={[
        <Button key="back" onClick={closeModal}>
          Close details
        </Button>,
      ]}
      title={"Payment info details"}
      onCancel={closeModal}
      visible={modalState.visible}
    >
      {modalState?.item?.method && (
        <h3 style={{ textAlign: "center" }}>
          {modalState?.item?.method?.toString().toUpperCase()}
        </h3>
      )}

      {modalState?.item?.billing_info ? (
        <List size={"large"}>
          {Object.keys(modalState?.item?.billing_info ?? {}).map((key) => {
            return (
              <List.Item>
                <b> {key.toString().toUpperCase()}</b>
                <span>{modalState?.item?.billing_info[key] ?? ""} </span>
              </List.Item>
            );
          })}
        </List>
      ) : (
        <List size={"large"}>
          <List.Item> No data provided.</List.Item>
        </List>
      )}
    </Modal>
  );
};
export default ModalPaymentMethodInfo;
