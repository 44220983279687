import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.module.css";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";

import App from "./App";

import store from "./redux/store.js";
import { Provider } from "react-redux";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
