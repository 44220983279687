import { Form, Input, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import parseErrors from "../../../utils/parseServerError";
import usersAPI from "../../../http/users";
import { notifyError, notifySuccess } from "../../../utils/notifications";

const ModalSendEmail = ({ modalState, closeModal }) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const [quillData, setQuillData] = useState("");

  const handleQuill = (values) => {
    setQuillData(values);
  };

  const onFinish = async (values) => {
    try {
      if (quillData == "" || !quillData)
        return notifyError("Email content is required");
      const res = await usersAPI().sendEmail({
        ...values,
        user_id: modalState?.item?.id,
        content: quillData,
      });
      closeModal();
      notifySuccess("Email is sent.");
    } catch (e) {
      parseErrors(e?.response?.data);
    }
  };

  useEffect(() => {
    form.resetFields();
    setQuillData("");
  }, [modalState]);

  return (
    <Modal
      width={1080}
      visible={modalState?.isVisible}
      onCancel={closeModal}
      okText={"Send email"}
      onOk={() => formRef?.current?.submit()}
      title={"Send email to user"}
    >
      <Form ref={formRef} form={form} onFinish={onFinish} layout={"vertical"}>
        <Form.Item label={"Subject"} required name={"subject"}>
          <Input placeholder={"Enter subject"}></Input>
        </Form.Item>
        <label
          style={{
            padding: "0 0 8px",
            lineHeight: " 1.5715",
            whiteSpace: "initial",
            textAlign: "left",
          }}
        >
          <sup style={{ color: "red" }}>*</sup> Email
        </label>
        <div style={{ margin: "10px 0" }}>
          <ReactQuill value={quillData} onChange={handleQuill} />
        </div>
      </Form>
    </Modal>
  );
};

export default ModalSendEmail;
