import { Button, Card, List, Modal } from "antd";
import { PAYMENT_PAID } from "../../../utils/constants";
import moment from "moment";

const ModalOrderInfo = ({ modalState, closeModal }) => {
  if (modalState.item == null) return <></>;

  const { order, booster, buyer_note, buyer_price_usd, usd_price, start_date } =
    modalState.item;
  const {
    buyer,
    game,
    offer_name,
    product,
    service,
    user,
    payment_status,
    additional_comment,
  } = order;
  return (
    <Modal
      width={1080}
      footer={[
        <Button type="round" key="back" onClick={closeModal}>
          Close order details
        </Button>,
      ]}
      onCancel={closeModal}
      visible={modalState.isVisible}
      title={"Viewing order details"}
    >
      <List size={"large"}>
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Order ID:</b>
            <span>{order.id} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Game:</b>
            <span>{game?.name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Service:</b>
            <span>{service?.name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Offer:</b>
            <span>{offer_name ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Seller:</b>
            <span>
              {user?.first_name.concat(" ".concat(user?.last_name ?? ""))}
            </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Buyer:</b>
            <span>
              {buyer?.first_name.concat(" ").concat(buyer?.last_name)}{" "}
            </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Booster:</b>
            <span>
              {booster?.first_name.concat(" ").concat(booster?.last_name)}{" "}
            </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Seller share:</b>
            <span>{buyer_price_usd ?? 0 - usd_price ?? 0} $ </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Buyers price:</b>
            <span>{buyer_price_usd ?? 0} $ </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Booster price:</b>
            <span>{usd_price ?? 0} $ </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Payment status:</b>
            <span>
              {payment_status == PAYMENT_PAID ? (
                <b style={{ color: "green" }}>Paid</b>
              ) : (
                <b style={{ color: "red" }}>Not paid</b>
              )}{" "}
            </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Transaction ID:</b>
            <span>{order?.transaction?.id ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Payment method:</b>
            <span>{order?.transaction?.payment_method ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <b>Starts at:</b>
            <span>{moment(start_date).format("DD MMMM YYYY")} </span>
          </div>
        </List.Item>
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <b>Additional Message:</b>
            <span>{additional_comment ?? "/"} </span>
          </div>
        </List.Item>{" "}
        <List.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <b>Note for buyer:</b>
            <span>{buyer_note ?? "/"} </span>
          </div>
        </List.Item>
      </List>
    </Modal>
  );
};
export default ModalOrderInfo;
