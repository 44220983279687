import React, { useState } from 'react';
import axios from 'axios'; 
import addService from '../../assets/icons/ADD-NEW-SERVICE.png'
import ImageUploadButton from '../Button/ImageUploadButton';
import { Link } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import TextArea from "antd/es/input/TextArea";
import { REACT_QUILL_MODULES } from "../../utils/constants";
import "react-quill/dist/quill.snow.css";
import QuillBetterTable from "quill-better-table";
import "quill-better-table/dist/quill-better-table.css";
import "../../QuillCustom.css"


Quill.register(
  {
    "modules/better-table": QuillBetterTable,
  },
  true
);
const EditNewWebServicePage = ({ modalState, closeModal, refreshData }) => {
  // State to hold form values
  const [formData, setFormData] = useState({
    serviceName: '',
    focusKeyword: '',
    seoTitle: '',
    metaDescription: '',
    pageDescription: '',
    serviceImage: null, // for image file upload
  });


  // State for loading and error handling
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [quillData, setQuillData] = useState(
    modalState?.item?.description ?? ""
  );
  const handleDataFromQuill = (data) => {
    setQuillData(data);
  };
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle image file input
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      serviceImage: e.target.files[0],
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Create form data object to send file as multipart/form-data
    const data = new FormData();
    data.append('serviceName', formData.serviceName);
    data.append('focusKeyword', formData.focusKeyword);
    data.append('seoTitle', formData.seoTitle);
    data.append('metaDescription', formData.metaDescription);
    data.append('pageDescription', formData.pageDescription);
    if (formData.serviceImage) {
      data.append('serviceImage', formData.serviceImage);
    }

    try {
      // API call using axios (replace with your API endpoint)
      const response = await axios.post('https://your-api-endpoint.com/services', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Service Added:', response.data);

      // Reset form after successful submission
      setFormData({
        serviceName: '',
        focusKeyword: '',
        seoTitle: '',
        metaDescription: '',
        pageDescription: '',
        serviceImage: null,
      });
    } catch (error) {
      alert("API not Connected")
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#042534] flex flex-col justify-center items-center">
        <div className='flex justify-start w-11/12 gap-5 ml-5'>
<img src={addService} alt="" className='w-10 h-10' />
        <h1 className="text-4xl font-bold text-white mb-8 ">Edit Web Service Page</h1>
        </div>
      <div className="w-11/12 mx-10 bg-[#04354b] p-8 rounded-lg">

        {/* Service Form */}
        <form className="space-y-6" onSubmit={handleSubmit}>
          {/* Service Name */}
          <div className="w-full">
            <label className="block text-white text-xl mb-2" htmlFor="serviceName">
              Service Name <span className="text-red-500">*</span>
            </label>
            <input
              id="serviceName"
              name="serviceName"
              type="text"
              placeholder="Service name"
              value={formData.serviceName}
              onChange={handleInputChange}
              className="w-full p-3 text-white bg-[#042534] border border-gray-500 rounded-md outline-none"
              required
            />
          </div>

          {/* Focus Keyword */}
          <div className="w-full">
            <label className="block text-white text-xl mb-2" htmlFor="focusKeyword">
              Focus Keyword <span className="text-red-500">*</span>
            </label>
            <input
              id="focusKeyword"
              name="focusKeyword"
              type="text"
              placeholder="Focus keyword"
              value={formData.focusKeyword}
              onChange={handleInputChange}
              className="w-full p-3 text-white bg-[#042534] border border-gray-500 rounded-md outline-none"
              required
            />
          </div>

          {/* SEO Title */}
          <div className="w-full">
            <label className="block text-white text-xl mb-2" htmlFor="seoTitle">
              SEO Title <span className="text-red-500">*</span>
            </label>
            <input
              id="seoTitle"
              name="seoTitle"
              type="text"
              placeholder="SEO title"
              value={formData.seoTitle}
              onChange={handleInputChange}
              className="w-full p-3 text-white bg-[#042534] border border-gray-500 rounded-md outline-none"
              required
            />
          </div>

          {/* Meta Description */}
          <div className="w-full">
            <label className="block text-white text-xl mb-2" htmlFor="metaDescription">
              Meta Description <span className="text-red-500">*</span>
            </label>
            <input
              id="metaDescription"
              name="metaDescription"
              type="text"
              placeholder="Meta description"
              value={formData.metaDescription}
              onChange={handleInputChange}
              className="w-full p-3 text-white bg-[#042534] border border-gray-500 rounded-md outline-none"
              required
            />
          </div>

          {/* Page Description */}
          <div className="w-full">
            <label className="block text-white text-xl mb-2" htmlFor="pageDescription">
              Page Description <span className="text-red-500">*</span>
            </label>
            <ReactQuill
              value={quillData}
              onChange={handleDataFromQuill}
              theme="snow"
              modules={REACT_QUILL_MODULES}
               placeholder="Page Description"
            />
            {/* <textarea
              id="pageDescription"
              name="pageDescription"
              placeholder="Page Description"
              value={formData.pageDescription}
              onChange={handleInputChange}
              className="w-full p-3 text-white bg-[#042534] border border-gray-500 rounded-md outline-none h-[12rem]"
              required
            ></textarea> */}
          
          </div>

          {/* Service Image */}
          <div className="w-full">
            <label className="block text-white text-xl mb-2" htmlFor="serviceImage">
              Service Image
            </label>
            {/* <input
              id="serviceImage"
              name="serviceImage"
              type="file"
              onChange={handleFileChange}
              className="block w-full text-white bg-[#042534] border border-gray-500 rounded-md outline-none"
            /> */}
            <ImageUploadButton/>
          </div>

          {/* Display Error Message if exists */}
          {error && <p className="text-red-500">{error}</p>}

          {/* Buttons */}
          <div className="flex justify-end space-x-4">
          <Link to="/web-service-page">
            <button
              type="button"
              className="px-5 py-3 text-lg font-semibold bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition"
            >
              Cancel
            </button>
            </Link>
            <button
              type="submit"
              className="px-5 py-3 text-lg font-semibold bg-[#077d7a] border-[#0bd6aa] text-white hover:bg-[#008974] transition"
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Edit New Service'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditNewWebServicePage;
