import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { useEffect, useRef, useState } from "react";
import servicesAPI from "../../../http/services";
import { notifySuccess } from "../../../utils/notifications";
import parseErrors from "../../../utils/parseServerError";
import ReactQuill from "react-quill";
import { REACT_QUILL_MODULES } from "../../../utils/constants";
import gamesAPI from "../../../http/games";
import add_service from "../../../assets/icons/edit-file.png"
import ImageUploadButton from "../../Button/ImageUploadButton";

const { Item, useForm } = Form;
const ModalEditServiceByGame = ({
  modalState,
  closeModal,
  gameId,
  serviceId,
}) => {
  const [form] = useForm();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [quillData, setQuillData] = useState("");
  const [dataGame, setDataGame] = useState(null);
  const [image, setImage] = useState(null);
  const uploadImage = (img) => {
    setImage(img);
  };
  const onFinish = async (values) => {
    console.log({ ...values, description: quillData });
    try {
      setLoading(true);
      const res = await gamesAPI().editServiceInGameDetails(gameId, serviceId, {
        ...values,
        description: quillData,
      });
      closeModal();
      notifySuccess(`Service is updated.`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      parseErrors(e.response.data);
    }
  };

  const handleDataFromQuill = (data) => {
    setQuillData(data);
  };

  const getData = async () => {
    try {
      const res = await gamesAPI().getServiceInGameDetails(gameId, serviceId);
      const { data } = res;
      setDataGame(data);
      setQuillData(data.description ?? "");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    form.resetFields();
    getData();
    return () => setQuillData("");
  }, []);

  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState.title}
      onCancel={closeModal}
      footer={[
        <div className="flex justify-end">
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={loading}
          loading={loading}
          onClick={() => formRef.current.submit()}
        >
          <img src={add_service} alt="add_service"></img>
          Edit service
        </Button>,
        </div>
      ]}
    >
      <Form
        initialValues={dataGame}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Item
          name="keywords"
          label="Keywords"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input keywords!" }]}
        >
          <Input placeholder="Enter keywords" />
        </Item>
        <Item
          name="seo_title"
          label="SEO title"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input SEO title!" }]}
        >
          <Input placeholder="Enter SEO title" />
        </Item>
        <Item
          name="meta_description"
          label="Meta description"
          required
          tooltip="This is a required field"
          rules={[
            { required: true, message: "Please input meta description!" },
          ]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="top_description_title"
          label="Main title"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Main title!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="top_description"
          label="Main text"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Main text!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="page_heading_title"
          label="Subtitle"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Subtitle!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="page_heading"
          label="Subtitle text"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Subtitle text!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="popularity"
          label="Popularity"
          tooltip="This field is for service popularity"
          valuePropName="checked"
        >
          <Switch />
        </Item>

        <label>
          <Tooltip title={"This field is required"}>
            <b style={{ color: "red", fontSize: "12px" }}>*</b> Description
          </Tooltip>
        </label>
        <div style={{ margin: "8px 0" }}>
          <ReactQuill
            value={quillData}
            onChange={handleDataFromQuill}
            theme="snow"
            modules={REACT_QUILL_MODULES}
          />
        </div>
        <Item name="service_img" label="Service Image">
            {/* <input type="file" placeholder={"Meta Description"} /> */}
            
            <ImageUploadButton upload_image={uploadImage} />
          </Item>
      </Form>
    </Modal>
  );
};

export default ModalEditServiceByGame;
