import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tooltip,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import gamesAPI from "../../../http/games";
import servicesAPI from "../../../http/services";
import { notifySuccess } from "../../../utils/notifications";
import parseErrors from "../../../utils/parseServerError";
import lodash, { toInteger } from "lodash";
import ReactQuill, { Quill } from "react-quill";
import TextArea from "antd/es/input/TextArea";
import { REACT_QUILL_MODULES } from "../../../utils/constants";
import "react-quill/dist/quill.snow.css";
import QuillBetterTable from "quill-better-table";
import "quill-better-table/dist/quill-better-table.css";
import cancel_btn from "../../../assets/icons/ADD-NEW-SERVICE.png";
import edit_new_service_btn from "../../../assets/icons/edit-file.png";
import add_new_service_btn from "../../../assets/icons/plus-icon.png";
import ImageUploadButton from "../../Button/ImageUploadButton";

Quill.register(
  {
    "modules/better-table": QuillBetterTable,
  },
  true
);

const { Item, useForm } = Form;
const { Option } = Select;
const { Group } = Checkbox;

const ModalAddService = ({ modalState, closeModal, refreshData }) => {
  const [form] = useForm();
  const formRef = useRef();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listOfSelectedGames, setListOfSelectedGames] = useState([]);
  const [quillData, setQuillData] = useState(
    modalState?.item?.description ?? "Nema ga"
  );
  const [image, setImage] = useState(null);
  const uploadImage = (img) => {
    setImage(img);
  };
  const loadGamesList = async () => {
    try {
      const res = await gamesAPI().getGameList();
      setGames(() => res.data);
    } catch (e) {
      console.dir(e);
    }
  };

  let initialValues = {};

  if (modalState?.item) {
    initialValues = {
      ...modalState.item,
      games: modalState.item.games.map((game) => game),
      popular_games: modalState.item.popular_games.map((game) => game),
      sale: toInteger(modalState.item.sale),
    };
  }

  const onFinish = (values) => {
    if (modalState.item) {
      editService(
        {
          ...values,
          description: quillData,
          popular_games: lodash.intersection(
            values.games,
            values.popular_games
          ),
        },
        modalState?.item?.id
      );
    } else {
      addNewService({
        ...values,
        popular_games: lodash.intersection(values.games, values.popular_games),
        description: quillData,
        categories: [],
        offers: [],
      });
    }
  };

  const initialValueForPopularGame = () => {
    if (modalState.item) {
      const currentSelectedGames = modalState.item.games;

      const mostPopularGamesOptions = currentSelectedGames.map((game) => {
        return games.find((item) => item.id == game);
      });

      setListOfSelectedGames(
        mostPopularGamesOptions.map((game) => {
          return {
            label: game.name,
            value: game.id,
          };
        })
      );
      console.log("popular games", currentSelectedGames);
    }
  };
  useEffect(() => {
    loadGamesList();
  }, []);

  useEffect(() => {
    initialValueForPopularGame();
  }, [modalState?.item]);

  const handleDataFromQuill = (data) => {
    setQuillData(data);
  };

  const formatGames = (games) => {
    const result = games.map((game) => {
      return {
        label: game.name,
        value: game.id,
      };
    });
    return result;
  };

  const memoizedGames = useMemo(() => formatGames(games), [games]);

  const addNewService = async (serviceData) => {
    try {
      setLoading(true);
      await servicesAPI().addService(serviceData);
      refreshData();
      closeModal();
      notifySuccess("New service added.");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      parseErrors(e.response.data);
    }
  };

  const editService = async (updatedServiceData, serviceId) => {
    try {
      setLoading(true);
      await servicesAPI().editService(updatedServiceData, serviceId);
      refreshData();
      closeModal();
      notifySuccess(`${updatedServiceData.name} is updated.`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      parseErrors(e.response.data);
    }
  };

  const takeValueFromGames = () => {
    const selectedGames = form.getFieldValue("games");
    const mostPopularGamesOptions = selectedGames.map((game) => {
      return games.find((item) => item.id == game);
    });
    setListOfSelectedGames(formatGames(mostPopularGamesOptions));
  };

  useEffect(() => {
    form.resetFields();
    if (modalState?.item) {
      setQuillData(modalState?.item?.description ?? "");
    } else {
      setQuillData("");
    }
  }, [modalState]);
  console.log("image", image);
  return (
    <div>
      <Modal
        width={1080}
        visible={modalState.visible}
        title={modalState.title}
        onCancel={closeModal}
        footer={[
          <Button
            className="flex items-center gap-2 cancel_service"
            key="back"
            onClick={closeModal}
          >
            <img
              width={20}
              src={add_new_service_btn}
              alt="add_new_service_btn"
            ></img>{" "}
            Cancel
          </Button>,
          <Button
            className="flex items-center gap-2 text-bold"
            key="submit"
            type="primary"
            disabled={loading}
            loading={loading}
            onClick={() => formRef.current.submit()}
          >
            {modalState?.item ? (
              <img
                width={20}
                src={edit_new_service_btn}
                alt="add_new_service_btn"
              ></img>
            ) : (
              <img
                width={20}
                src={add_new_service_btn}
                alt="add_new_service_btn"
              ></img>
            )}

            {modalState?.item ? "Edit service" : "Add New Service"}
          </Button>,
        ]}
      >
        <Form
          initialValues={initialValues}
          ref={formRef}
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Item
            name="name"
            label="Service Name"
            // tooltip="This is a required field"
            // rules={[{ required: true, message: "Please input service name!" }]}
            required
          >
            <TextArea placeholder="Enter service name" />
          </Item>
          {/* <Item
          required
          name="type_of_service"
          label="Choose type of this service"
          // tooltip="This is a required field"
          // rules={[{ required: true, message: "Please enter service sale!" }]}
        >
          <Select allowClear>
            <Option value={0}>Regular Offers</Option>
            <Option value={1}>
              Big Stock Offers
            </Option>
            <Option value={2}>
              Key Offers
            </Option>
            <Option value={3}>
             Fixed Option Offers
            </Option>
          </Select>
        </Item>  */}
          {/* {/* <Item name="sale" label="Service sale">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Enter sale amount"
            addonAfter="$"
            max={100}
          />
        </Item> */}

          {/* <Item name="games" label="Select Brands">
            <Select
              optionFilterProp="children"
              showArrow={true}
              allowClear
              mode="multiple"
              onChange={takeValueFromGames}
            >
              {memoizedGames.map((option) => {
                return <Option value={option.value}>{option.label}</Option>;
              })}
            </Select>
          </Item> */}
          {/* <Item name="popular_games" label="Select Popular Brands">
          <Select
            optionFilterProp="children"
            showArrow={true}
            allowClear
            mode="multiple"
            onChange={takeValueFromGames}

          >
            {listOfSelectedGames.map((option) => {
              return <Option value={option.value}>{option.label}</Option>;
            })}
          </Select>
        </Item> */}

          <Item name="focus_keywords" label="Focus Keywords">
            <TextArea
              placeholder={"Focus Keywords"}
              mode="multiple"
              allowClear
              showArrow={true}
              optionFilterProp="children"
            />
          </Item>
          <Item name="seo_title" label="SEO Title">
            <TextArea placeholder={"Enter SEO Title"} />
          </Item>
          <Item name="meta_description" label="Meta Description">
            <TextArea placeholder={"Meta Description"} />
          </Item>
          <label className="text-white font-bold">
            <Tooltip title={"This field is required"}>
              Page Description{" "}
              <b style={{ color: "red", fontSize: "12px" }}>*</b>
            </Tooltip>
          </label>
          <div style={{ margin: "8px 0" }}>
            <ReactQuill
              value={quillData}
              onChange={handleDataFromQuill}
              theme="snow"
              modules={REACT_QUILL_MODULES}
            />
          </div>
          <Item name="service_img" label="Service Image">
            {/* <input type="file" placeholder={"Meta Description"} /> */}
            <ImageUploadButton upload_image={uploadImage} />
          </Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ModalAddService;
