import { getApiClient } from "../client";

function usersAPI() {
  return {
    addUser: (userData) => getApiClient().post("/admin/user", userData),
    creatingOffer: (boosterId, offerData) =>
      getApiClient().post(`/admin/booster/${boosterId}/offer`, offerData),
    editUser: (updatedUserData, userId) =>
      getApiClient().put(`/admin/user/${userId}`, updatedUserData),
    editSettingsServices: (boosterId, updatedData) =>
      getApiClient().put(`/admin/booster/${boosterId}/settings`, updatedData),
    cancelRequestOffer: (boosterId, offerID, data) =>
      getApiClient().put(`/admin/booster/${boosterId}/offer/${offerID}`, data),
    getUsersList: (searchKeyWord, role, is_suspended = false, rules) =>
      getApiClient().get("/admin/users", {
        params: {
          search: searchKeyWord,
          role: role,
          is_suspended: is_suspended,
          ...rules,
        },
      }),
    getUserDetails: (userId) => getApiClient().get(`/admin/user/${userId}`),
    getCashInfo: (userId) =>
      getApiClient().get(`/admin/booster/${userId}/withdraws/info`),
    getTransactions: (userId, search, params) =>
      getApiClient().get(`/admin/booster/${userId}/transactions`, {
        params: { search, ...params },
      }),
    deleteUser: (userId) => getApiClient().delete(`/admin/user/${userId}`),
    sendEmail: (emailData) =>
      getApiClient().post(`/admin/send/email`, emailData),
    purchasedRegularOrders: (boosterID, searchKeyWord, rules) =>
      getApiClient().get(`/admin/booster/${boosterID}/orders/purchased`, {
        params: {
          search: searchKeyWord,
          ...rules,
        },
      }),
    accepterOrders: (boosterID, searchKeyWord, rules) =>
      getApiClient().get(`/admin/booster/${boosterID}/orders/accepted`, {
        params: {
          search: searchKeyWord,
          ...rules,
        },
      }),
    purchasedCustomOrders: (boosterID, searchKeyWord, rules) =>
      getApiClient().get(`/admin/booster/${boosterID}/custom-orders`, {
        params: {
          search: searchKeyWord,
          ...rules,
        },
      }),
    uploadAvatar: (userId, formData) =>
      getApiClient().put(`/currentuser/${userId}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    uploadAdminAvatar: (userId, formData) =>
      getApiClient().put(`/admin/user/${userId}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    handleCustomOrder: (buyerId, orderId, data) =>
      getApiClient().put(
        `/admin/buyer/${buyerId}/custom-order/${orderId}`,
        data
      ),
    handleNewOrder: (buyerId, orderId, data) =>
      getApiClient().put(`/admin/booster/${buyerId}/order/${orderId} `, data),
    handleRequestLobbyItem: (boosterId, offerID, data) =>
      getApiClient().put(`/admin/booster/${boosterId}/offer/${offerID} `, data),
    getNewOrders: (boosterID, searchKeyWord, rules) =>
      getApiClient().get(`/admin/booster/${boosterID}/orders/new`, {
        params: {
          search: searchKeyWord,
          ...rules,
        },
      }),
    getSettingsRequests: (boosterID, searchKeyWord, rules) =>
      getApiClient().get(`/admin/booster/${boosterID}/settings/requests`, {
        params: {
          search: searchKeyWord,
          ...rules,
        },
      }),
    getSettingsServices: (boosterID, searchKeyWord, rules) =>
      getApiClient().get(`/admin/booster/${boosterID}/settings/services`, {
        params: {
          search: searchKeyWord,
          ...rules,
        },
      }),
    getRequestLoobyActiveOrders: (boosterID, searchKeyWord, rules) =>
      getApiClient().get(`/admin/booster/${boosterID}/request-lobby`, {
        params: {
          search: searchKeyWord,
          ...rules,
        },
      }),
    getRequestLoobySentOrders: (boosterID, searchKeyWord, rules) =>
      getApiClient().get(`/admin/booster/${boosterID}/offer`, {
        params: {
          search: searchKeyWord,
          ...rules,
        },
      }),
    invitationList: (gameId, serviceId) =>
      getApiClient().get(`/admin/game/${gameId}/service/${serviceId}/users`),
  };
}

export default usersAPI;
