import { useEffect, useState } from "react";
import { Button, Card, Tooltip } from "antd";
import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";

import styles from "./AddOfferOption.module.css";
import ModalAddOption from "./ModalAddOption";
import ModalAddSubOption from "./ModalAddSubOption";

const AddOfferOption = ({ options = [], setOptions }) => {
  const [modalState, setModalState] = useState({
    visible: false,
  });
  const [modalStateAddItem, setModalStateAddItem] = useState({
    visible: false,
    index: null,
  });

  const INPUT_TYPES = [
    { value: 0, label: "Checkbox" },
    { value: 1, label: "Radio button" },
    { value: 2, label: "Dropdown" },
  ];

  const openAddOption = () => {
    setModalState({ ...modalState, visible: true });
  };

  const openAddItems = (index) => {
    setModalStateAddItem({ ...modalState, visible: true, index });
  };

  const closeAddOption = () => {
    setModalState({ ...modalState, visible: false });
  };
  const closeAddItem = () => {
    setModalStateAddItem({ ...modalState, visible: false, index: null });
  };

  const addOption = (option) => {
    let copyOfOptions = [...options];
    copyOfOptions.push(option);
    setOptions(copyOfOptions);
    closeAddOption();
  };

  const deleteOption = (index) => {
    let copyOfOptions = [...options];
    copyOfOptions.splice(index, 1);
    setOptions(copyOfOptions);
  };

  const deleteSubOption = (indexOfOption, indexOfSubOption) => {
    let copyOfOptions = [...options];
    copyOfOptions[indexOfOption].sub_options.splice(indexOfSubOption, 1);
    setOptions(copyOfOptions);
  };

  const addSubOption = (index, data) => {
    let copyOfOptions = [...options];
    copyOfOptions[index].sub_options.push(data);
    setOptions(copyOfOptions);
    closeAddItem();
  };

  return (
    <div>
      <ModalAddOption
        closeModal={closeAddOption}
        modalState={modalState}
        addOption={addOption}
      />

      <ModalAddSubOption
        closeModal={closeAddItem}
        modalState={modalStateAddItem}
        addSubOption={addSubOption}
      />

      {options?.map((mainOption, index) => {
        return (
          <Card style={{ margin: "10px 0" }}>
            <div className={styles.optionContainerWrapper}>
              <div className={styles.optionContainer}>
                <label>Name of option </label>
                <b>{mainOption.name}</b>
              </div>{" "}
              <div className={styles.optionContainer}>
                <label>Type of option </label>
                <b>{INPUT_TYPES[mainOption.input_type].label}</b>
              </div>{" "}
              <div className={styles.optionContainer}>
                <label>Required </label>
                <b>{mainOption.required ? "Required" : "Not required"}</b>
              </div>{" "}
            </div>

            {mainOption.sub_options && mainOption.sub_options.length > 0 && (
              <div className={styles.subOptionsContainer}>
                <div className={styles.subTitle}>
                  {" "}
                  <b>Sub-options</b>
                </div>
                {mainOption.sub_options.map((subOption, subOptionIndex) => {
                  return (
                    <div className={styles.subOption}>
                      <div
                        className={styles.subOptionWrapper}
                        style={{
                          width: "65%",
                          overflow: "hidden",
                          "max-width": "65%",
                        }}
                      >
                        <label>Name</label>
                        <b>{subOption.name}</b>
                      </div>{" "}
                      <div className={styles.subOptionWrapper}>
                        <label>Price</label>
                        <b>{subOption.additional_price_usd}</b>
                      </div>{" "}
                      <div className={styles.subOptionWrapper}>
                        <Tooltip title="Delete item">
                          <DeleteOutlined
                            onClick={() =>
                              deleteSubOption(index, subOptionIndex)
                            }
                          />
                        </Tooltip>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className={styles.optionFooter}>
              <Button
                primary
                type="round"
                icon={<PlusCircleFilled />}
                onClick={() => openAddItems(index)}
              >
                Add sub-option
              </Button>
              <Button
                danger
                type="round"
                icon={<DeleteOutlined />}
                onClick={() => deleteOption(index)}
              >
                {" "}
                Delete option
              </Button>
            </div>
          </Card>
        );
      })}

      <Button
        primary
        icon={<PlusCircleFilled />}
        className={styles.addNewOption}
        onClick={openAddOption}
      >
        Add new option
      </Button>
    </div>
  );
};

export default AddOfferOption;
