import Discount from "../../components/Discounts/Discounts.js";
import styles from "./Discount.module.css";

const Discounts = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Discounts </h1>
      <Discount />
    </div>
  );
};

export default Discounts;
