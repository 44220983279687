import {Button, Image, List, Select, Space} from "antd";
import {FileImageFilled} from "@ant-design/icons";
import {notifyError, notifySuccess} from "../../../utils/notifications";
import ordersAPI from "../../../http/orders";

const Settings = ({item, refreshData, closeModal}) => {
  const statuses = [
    "FINDING PLAYER",
    "PREPARING TO START",
    "ORDER STARTED",
    "ORDER FINISHED",
    "ORDER COMPLETED",
    "ORDER CANCEL REQUESTED",
    "ORDER CANCELED ",
  ];


  const handleStatusChange = async (value) => {
    try {
      const res = await ordersAPI().editOrder({status: value}, item.id)
      refreshData()
      notifySuccess("Status changed")
      closeModal()
    } catch (e) {
      notifyError(e?.response?.reason)
    }
  };


  return (
      <div style={containerStyle}>
        <List size={"large"} header={<h3>Order editing</h3>}>
          <List.Item>
            <div style={{...containerStyle, gap: "10px", margin: "10px 0"}}>
              <b>Status</b>
              <Select defaultValue={item.status} onChange={handleStatusChange}>
                {statuses.map((option, index) => {
                  return <Select.Option value={index}>{option}</Select.Option>;
                })}
              </Select>
            </div>
            <Space direction="vertical">
              <span>Current status:</span>
              <b>{statuses[item?.status ?? 0]}</b>
            </Space>
          </List.Item>
        </List>
        <List size={"large"} header={<h3>Screenshots</h3>}>
          {item.images.map((image, key) => {
            return (
                <List.Item>
                  <Space>
                    <Image
                        width={200}
                        src={image.url}
                        preview={{
                    src: image.url,
                  }}
                />
                {/*<Button onClick={() => downloadImage(image)}>*/}
                {/*  Download image*/}
                {/*</Button>*/}
              </Space>
            </List.Item>
          );
        })}

          <List.Item>
            <div>
              <Space>
                <FileImageFilled></FileImageFilled>
                <b>Not yet uploaded</b>
              </Space>
            </div>
          </List.Item>
        </List>
        {
          item?.status != 6 &&
          <List size="large" header={<h3>Canceling order</h3>}>
            <List.Item>
              <Button danger onClick={() => handleStatusChange(6)}> Cancel order</Button>
            </List.Item>
          </List>
          }
      </div>
  );
};
export default Settings;

const containerStyle = {
  display: "flex",
  flexDirection: "column",
};
