import React, { useState } from "react";
import "./DeletePopup.css";
import delete_service from "../../assets/icons/DELETE-SERVICE.png";

const DeletePopup = ({ serviceName, onDelete, onCancel , handleCancel ,serviceId , item}) => {
  const [metaDescription, setMetaDescription] = useState("");

  const handleDelete = () => {
    if (metaDescription) {
      onDelete(metaDescription);
    } else {
      alert("Please provide a valid link to redirect.");
    }
  };
  console.log("service name" , item)

  return (
    <div className="delete-popup z-30">
      <div className="delete-popup-content">
        <h3 className="text-white text-3xl font-bold text-left py-3 flex gap-2 items-center">
        <img className="w-[25px] h-[25px]" src={delete_service} alt="editService"></img>Delete "{serviceName}" Service
        </h3>
        <p className="text-left py-1">Are you sure you want to delete "{serviceName}" service?</p>
        <label className="text-left text-[#c2c4c5] font-bold">
          Please insert a valid new link to which the old page will redirect to{" "}
          <span className="required">*</span>
        </label>
        <textarea
          placeholder="Meta description"
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
        ></textarea>
        <div className="delete-popup-actions flex justify-end gap-2">
          <button className="cancel_service" onClick={()=>handleCancel(false)}>
            Cancel
          </button>
          <button className="danger_btn flex items-center gap-1" onClick={()=>onDelete(item)}>
          <img src={delete_service} alt="editService"></img> Delete Service
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
