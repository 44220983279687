import {Button, Input, Popconfirm, Space, Switch, Table} from "antd";
import { notifySuccess } from "../../utils/notifications";
import {
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import parseErrors from "../../utils/parseServerError";
import styles from "./GameServiceDetails.module.css";
import ModalAddProduct from "./Modals/ModalAddProduct";
import { useState } from "react";
import productsAPI from "../../http/products";
import { StyledTable } from "../Users/Users";

const ProductTable = ({
  products,
  onSearch,
  setOffset,
  total,
  refreshData,
  loading,
  gameId,
  serviceId,
}) => {
  const changeStatusOfProduct = async (product) => {
    try {
      const { status, id } = product;
      await productsAPI().editProduct({ status: !status }, id);
      refreshData();
      notifySuccess(`Status for  ${product?.name}  changed`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const handleDelete = async (item) => {
    try {
      const { id } = item;
      await productsAPI().deleteProduct(id);
      refreshData();
      notifySuccess(`${item?.name}  is deleted.`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const columns = [
    {
      title: "Product name",
      key: "product",
      dataIndex: "name",
      render: (data) => {
        return <b>{data}</b>;
      },
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
      render: (data) => {
        return data?.name ?? "/";
      },
    },
    {
      title: "Status",
      render: (data) => {
        return (
          <Switch
            checked={data.status}
            onChange={() => changeStatusOfProduct(data)}
          />
        );
      },
    },
    {
      title: "Sale",
      render: (data) => {
        return (
          <div>
            <b>{data?.sale ?? 0}</b>
            <PercentageOutlined />
          </div>
        );
      },
    },
    {
      title: "Starting price",
      key: "starting_price",
      dataIndex: "starting_price_usd",
      render: (data) => {
        return (
          <Space>
            {" "}
            <ArrowUpOutlined style={{ color: "green" }} />
            <b>${data}</b>{" "}
          </Space>
        );
      },
    },
    {
      title: "Actions",
      key: "edit",
      render: (item) => {
        return (
          <Space>
            <Button
              type={"primary"}
              onClick={() => openModal(item)}
              icon={<EditOutlined />}
            >
              Edit{" "}
            </Button>
            <Popconfirm
              title={"Are you sure to delete " + item.name + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => handleDelete(item)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const [modalState, setModalState] = useState({ isVisible: false });

  const closeModal = () => {
    setModalState({ ...modalState, visible: false, item: undefined });
  };
  const openModal = (item) => {
    setModalState({ ...modalState, visible: true, item });
  };

  return (
    <>
      <ModalAddProduct
        modalState={modalState}
        closeModal={closeModal}
        gameId={gameId}
        refreshData={refreshData}
        serviceId={serviceId}
      />
      <div className={styles.addingButton}>
        <Input.Search allowClear={true} enterButton={true} onSearch={onSearch}/>
        <Button
          onClick={() => openModal()}
          type="primary"
          shape="round"
          icon={<PlusCircleOutlined />}
        >
          Add product
        </Button>
      </div>
      <StyledTable loading={loading} columns={columns} dataSource={products} pagination={{
        pageSize: 20,
        total,
        onChange: (page, pageSize = 0) => {
          setOffset((page - 1) * pageSize)
        },
      }}/>
    </>
  );
};

export default ProductTable;
