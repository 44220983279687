import styles from "./InfoPages.module.css";
import {
  Button, Image,
  Input,
  Popconfirm,
  Space,
  Switch, Upload,
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined, UploadOutlined,
} from "@ant-design/icons";
import blogAPI from "../../http/blogs";
import { useEffect, useMemo, useState } from "react";
import ModalAddEditPage from "./Modals/ModalAddEditPage";
import {notifyError, notifySuccess} from "../../utils/notifications";
import { debounce } from "lodash";
import parseErrors from "../../utils/parseServerError";
import { StyledTable } from "../Users/Users";

const { Search } = Input;

const initialModalState = {
  title: "Add page",
  visible: false,
  item: null,
};

const InfoPages = () => {
  const [pageListData, setPageListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
    ordering: "-created",
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const [modalStateAddBlog, setModalStateAddBlog] =
    useState(initialModalState);

  const addNewBlog = () =>
    setModalStateAddBlog({ ...initialModalState, visible: true });

  const editBlog = (item) =>
    setModalStateAddBlog({
      ...initialModalState,
      title: "Edit page",
      item,
      visible: true,
    });

  const closeModal = () => setModalStateAddBlog({ ...initialModalState });

  const getListOfPages = async () => {
    try {
      setIsLoading(true);
      const res = await blogAPI().getBlogs(searchKeyWord, params);
      setPageListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  const changeStatusOfPage = async (page) => {
    try {
      const { status, id } = page;
      await blogAPI().editBlog({ status: !status }, id);
      await getListOfPages();
      notifySuccess(`Status for  ${page?.top_description_title}  changed`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const deleteBlog = async (blog) => {
    try {
      const { id } = blog;
      await blogAPI().deleteBlog(id);
      notifySuccess(`${blog.top_description_title} is deleted.`);
      await getListOfPages();
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const customRequest = (file, onSuccess, onError, id) => {
    const data = new FormData();
    data.append("card_photo", file);

    if (onSuccess) {
      onSuccess(
        data,
        blogAPI()
          .uploadBlogPhoto(id, data)
          .then(() => {
            getListOfPages();
            notifySuccess("Your photo has been uploaded successfully.");
          })
          .catch((err) => {
            if (onError) {
              onError(err);
            }
            notifyError(err?.response?.data);
          })
      );
    }
  };

  const uploadSitemapHandler = (file, onSuccess, onError) => {
    const data = new FormData();
    data.append("file", file);
    
    if (onSuccess) {
      onSuccess(
          data,
          blogAPI()
              .uploadSitemap(data)
              .then(() => {
                notifySuccess("Your sitemap has been uploaded successfully.");
              })
              .catch((err) => {
                if (onError) {
                  onError(err);
                }
                notifyError(err?.response?.data);
              })
      );
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
    getListOfPages(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "Photo",
      dataIndex: "card_photo",
      key: "card-photo",
      render: (card_photo) => {
        return (
              card_photo ? <Image src={card_photo} height={50} width={50} /> : <QuestionCircleOutlined height={50} width={50} />
        );
      },
    },
    {
      title: "Main title",
      dataIndex: "top_description_title",
      key: "main-title",
    },
    {
      title: "Main text",
      dataIndex: "top_description",
      key: "main-text",
      render: (desc)=> <p>{desc.substring(0, 50) + '...'}</p>
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Keywords",
      dataIndex: "keywords",
      key: "keywords"
    },
    {
      title: "Status",
      key: "status",
      render: (data) => {
        return (
            <Switch
                checked={data.status}
                onChange={() => changeStatusOfPage(data)}
            />
        );
      },
    },
    {
      title: "Edit",
      key: "edit",
      render: (item) => {
        return (
          <Space>
            <Button
              type={"primary"}
              icon={<EditOutlined />}
              onClick={() => editBlog(item)}
            >
              Edit
            </Button>
            <Popconfirm
              title={"Are you sure to delete " + item.top_description_title + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => deleteBlog(item)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
            <Upload
                accept="image/png, image/gif, image/jpeg"
                fileList={[]}
                customRequest={({file, onSuccess, onError})=>customRequest(file, onSuccess, onError, item.id)}
            >
              <Button><UploadOutlined /> Upload photo</Button>
            </Upload>
          </Space>
        );
      },
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  return (
    <div className={styles.container}>
      <ModalAddEditPage
        modalState={modalStateAddBlog}
        closeModal={closeModal}
        refreshData={getListOfPages}
      />
      <h1 className={styles.header}>Info pages</h1>
      <div className={styles.uploadSitemap}>
        <Upload
            accept="application/xml"
            fileList={[]}
            customRequest={({file, onSuccess, onError})=>uploadSitemapHandler(file, onSuccess, onError)}
        >
          <Button><UploadOutlined /> Upload sitemap (XML)</Button>
        </Upload>
      </div>
      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search info pages"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />

        <Button
          shape={"round"}
          size={"large"}
          icon={<PlusSquareOutlined />}
          onClick={addNewBlog}
        >
          Add page
        </Button>
      </div>
      <StyledTable
        pagination={paginationSettings}
        dataSource={pageListData}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default InfoPages;
