import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  Tooltip,
} from "antd";
import ReactQuill from "react-quill"; // ES6
import styles from "../GameServiceDetails.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { notifySuccess } from "../../../utils/notifications";
import parseServerError from "../../../utils/parseServerError";
import Title from "antd/es/typography/Title";
import productsAPI from "../../../http/products";
import categoriesAPI from "../../../http/categories";
import { REACT_QUILL_MODULES } from "../../../utils/constants";

const { Item, useForm } = Form;
const { Group } = Checkbox;
const { Option } = Select;

const ModalAddProduct = ({
  modalState,
  closeModal,
  refreshData,
  serviceId,
  gameId,
}) => {
  const [form] = useForm();
  const formRef = useRef();
  const [showSubCategory, setShowSubcategory] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [quillData, setQuillData] = useState("");

  const onFinish = (values) => {
    if (modalState.item) {
      modifyExistingCategory({
        ...values,
        description: quillData,
        service: serviceId,
      });
    } else {
      addNewCategory({
        ...values,
        description: quillData,
        service: serviceId,
        game_id: gameId,
      });
    }
  };

  const loadCategoriesList = async () => {
    try {

      const extra = {
        parent_category__isnull: true
      }

      const res = await categoriesAPI().getCategoryList(gameId, serviceId, extra);

      setCategories(
        res.data
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadCategoriesList();
  }, [modalState]);

  const formatDataForSelect = (services) => {
    const result = services.map((service) => {
      return {
        label: service.name,
        value: service.id,
      };
    });
    return result;
  };

  const memoizedCategories = useMemo(
    () => formatDataForSelect(categories),
    [categories]
  );

  const addNewCategory = async (categoryData) => {
    try {
      await categoriesAPI().addCategory(categoryData);
      refreshData();
      closeModal();
      notifySuccess("New category added.");
    } catch (e) {
      parseServerError(e.response.data);
    }
  };
  const modifyExistingCategory = async (categoryData) => {
    try {
      await categoriesAPI().editCategory(categoryData, modalState?.item?.id);
      refreshData();
      closeModal();
      notifySuccess("Category edited.");
    } catch (e) {
      parseServerError(e.response.data);
    }
  };

  const handleDataFromQuill = (data) => {
    setQuillData(data);
  };

  const handleCategoryTypeSelection = (value) => {
    if (value == 1) {
      setShowSubcategory(true);
    } else {
      setShowSubcategory(false);
    }
  };

  useEffect(() => {
    form.resetFields();
    if (modalState?.item && modalState?.item !== null) {
      if (modalState?.item?.parent_category !== null) {
        setShowSubcategory(true);
      }
      setQuillData(modalState.item?.description);
    } else {
      setShowSubcategory(false);

      setQuillData("");
    }
  }, [modalState]);

  let initialValues = {};
  if (modalState?.item && modalState.item !== null) {
    initialValues = {
      ...modalState?.item,
      parent_category: modalState?.item?.parent_category,
    };
  }

  console.log({ initialValues, modalState });
  return (
    <Modal
      width={1080}
      visible={modalState.visible}
      title={modalState.title}
      onOk={() => console.log("OK")}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current.submit()}>
          {modalState?.item ? "Edit category" : "Add category"}
        </Button>,
      ]}
    >
      <Form
        initialValues={initialValues}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Title level={4}>
          {modalState?.item ? "Edit category" : "Add category"}
        </Title>
        <Item
          name="name"
          label="Category name"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input category name!" }]}
        >
          <Input placeholder="Enter product name" />
        </Item>
        <Item
          name="top_description_title"
          label="Main title"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Main title!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="top_description"
          label="Main text"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Main text!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="page_heading_title"
          label="Subtitle"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Subtitle!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="page_heading"
          label="Subtitle text"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input Subtitle text!" }]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        {!modalState.item && (
          <>
            <Item name="category_type" label="Category type" initialValue={0}>
              <Select
                optionFilterProp="children"
                showArrow={true}
                onChange={handleCategoryTypeSelection}
              >
                <Option value={1}>Subcategory</Option>;
                <Option value={0}> Main category</Option>;
              </Select>
            </Item>
            {showSubCategory && (
              <Item
                name="parent_category"
                label="Parent category"
                required
                tooltip="This is a required field"
                rules={[
                  { required: true, message: "Please select subcategory!" },
                ]}
                shouldUpdate
              >
                <Select optionFilterProp="children" showArrow={true}>
                  {memoizedCategories.map((option) => {
                    return <Option value={option.value}>{option.label}</Option>;
                  })}
                </Select>
              </Item>
            )}
          </>
        )}
        <label>
          <Tooltip title={"This field is required"}>
            <b style={{ color: "red", fontSize: "12px" }}>*</b> Description
          </Tooltip>
        </label>
        <div className={styles.reactquill}>
          <ReactQuill
            style={{ paddingBottom: "4vh" }}
            value={quillData}
            onChange={handleDataFromQuill}
            theme="snow"
            modules={REACT_QUILL_MODULES}
          />
        </div>
        <Item name="sale" label="Category sale">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            max={100}
            placeholder="Enter sale amount"
            addonAfter="$"
          />
        </Item>
        <Item
          name="keywords"
          label="Keywords"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input keywords!" }]}
        >
          <Input placeholder="Enter keywords" />
        </Item>{" "}
        <Item
          name="seo_title"
          label="SEO Title"
          required
          tooltip="This is a required field"
          rules={[{ required: true, message: "Please input SEO title!" }]}
        >
          <Input placeholder="Enter SEO title" />
        </Item>{" "}
        <Item
          name="meta_description"
          label="Meta description"
          required
          tooltip="This is a required field"
          rules={[
            { required: true, message: "Please input meta description!" },
          ]}
        >
          <Input placeholder="Enter meta description" />
        </Item>
        <Item
          name="status"
          label="Status"
          tooltip="This field is for category activation"
          initialValue={modalState?.item?.status ?? false}
        >
          <Switch defaultChecked={modalState?.item?.status}></Switch>
        </Item>
      </Form>
    </Modal>
  );
};

export default ModalAddProduct;
