import { Button, Modal, Tabs } from "antd";
import React, { useRef, useState } from "react";
import InvitationForm from "./InvitationForm";
import InvitationInfo from "./InvitationInfo";

const { TabPane } = Tabs;
const ModalOrderInvitation = ({ modalState, closeModal, refreshData }) => {
  const [step, setStep] = useState(0);
  const [storedValues, setStoredValues] = useState(null);
  const formRef = useRef();

  if (!modalState.item) return <></>;

  const { item, editing } = modalState;

  const handleTabChange = (numberOfStep) => {
    setStep(numberOfStep);
  };

  const saveValues = (values) => {
    setStoredValues(values);
  };

  return (
    <Modal
      width={1080}
      title={editing ? "Order invitation editing" : "Order invitation"}
      visible={modalState.isVisible}
      onCancel={closeModal}
      destroyOnClose={true}
      footer={[
        <Button onClick={closeModal}>Close</Button>,
        <Button type="primary" onClick={() => formRef?.current?.submit()}>
          {editing ? "Resend invitations" : "Send invitation"}
        </Button>,
      ]}
    >
      <Tabs onChange={handleTabChange} type="card" value={step}>
        <TabPane tab="Inviting booster" key="0"></TabPane>
        <TabPane tab="Purchase review" key="1"></TabPane>
      </Tabs>
      {step == 0 && (
        <InvitationForm
          saveValues={saveValues}
          storedValues={storedValues}
          item={item}
          closeModal={closeModal}
          formRef={formRef}
          refreshData={refreshData}
          editing={editing}
        />
      )}
      {step == 1 && <InvitationInfo item={item} />}
    </Modal>
  );
};

export default ModalOrderInvitation;
