import { Button, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import moment from "moment";

const RequestInfo = ({ modalState, closeModal, changeStatus }) => {
  console.log({ modalState });

  if (modalState.item == null) return <></>;

  const { item } = modalState;

  let footerWithButtons = [
    <Button type="round" key="back" onClick={closeModal}>
      Close
    </Button>,
    <Button
      key="back"
      type="primary"
      round
      onClick={() => {
        changeStatus(1, item);
        closeModal();
      }}
      icon={<CheckCircleFilled />}
    >
      Accept request
    </Button>,
    <Button
      icon={<CloseCircleFilled />}
      type="round "
      danger
      key="back"
      onClick={() => {
        changeStatus(2, item);
        closeModal();
      }}
    >
      Decline request
    </Button>,
  ];

  if (item.status != 0) {
    footerWithButtons.pop();
    footerWithButtons.pop();
  }

  return (
    <Modal
      width={1080}
      footer={footerWithButtons}
      title={"Request for boosting"}
      onCancel={closeModal}
      visible={modalState.isVisible}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>
          Request from:{" "}
          <b style={{ color: "green" }}>
            {item?.user?.first_name ?? "" + " " + item?.user?.last_name ?? ""}{" "}
          </b>
        </h4>{" "}
        <h4>
          Created:{" "}
          <b style={{ color: "green" }}>
            {moment(item?.created).format("DD MMM YYYY")}
          </b>
        </h4>
      </div>

      <h3 style={{ textAlign: "center" }}>Games requested to play</h3>
      {item?.games.map((item) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>
              {" "}
              <b>{item?.game?.name}</b>{" "}
            </label>
            {item?.services?.map((service) => {
              return (
                <Input style={{ margin: "5px 0" }} value={service.name}></Input>
              );
            })}
          </div>
        );
      })}
      <label>
        <b>
          Describe your gaming experience as a seller [booster/coacher] (must)
        </b>
      </label>
      <TextArea
        style={{ margin: "5px 0" }}
        value={modalState?.item?.gaming_experience ?? ""}
      >
        {" "}
      </TextArea>
      <label>
        <b>
          Please show evidence of your main account statistics/characters for
          every game you have selected (optional)
        </b>
      </label>
      <TextArea
        style={{ margin: "5px 0" }}
        value={modalState?.item?.gaming_experience ?? ""}
      >
        {" "}
      </TextArea>
      <label>
        <b>Payments from another Companies (optional)</b>
      </label>
      <TextArea
        style={{ margin: "5px 0" }}
        value={modalState?.item?.another_companies_payments ?? ""}
      >
        {" "}
      </TextArea>
    </Modal>
  );
};
export default RequestInfo;
