import styles from "./TypeOfService.module.css";
// import { ClipLoader } from 'react-spinners';
import {
  Button,
  Input,
  Popconfirm,
  Skeleton,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { Link } from "react-router-dom";

import {
  DeleteOutlined,
  EyeOutlined,
  PercentageOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import gamesAPI from "../../http/games";
import moment from "moment";
import ModalAddGame from "./Modals/ModalAddGame";
import { debounce } from "lodash";
import { notifySuccess } from "../../utils/notifications";
import parseErrors from "../../utils/parseServerError";
import { StyledTable } from "../Users/Users";
import add_new_brand from "../../assets/icons/ADD-NEW-SERVICE.png";
import brands_img from "../../assets/icons/ADD-NEW-SERVICE.png";
import service_type from "../../assets/icons/servise-type.png";
import add_new_service from "../../assets/icons/ADD-NEW-SERVICE.png";
import service_type_inner from "../../assets/icons/Service-Test-2.png";
import edit_Service from "../../assets/icons/EDIT-SERVICE.png";
import delete_service from "../../assets/icons/DELETE-SERVICE.png";
import DeletePopup from "../DeletePopup/DeletePopup";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Title } = Typography;
const { Search } = Input;
const dummyData = [
    {
    id: 1,
    name: "Service 1",
    description: "Service 1 description",

},
    {
    id: 2,
    name: "Service 2",
    description: "Service 2 description",

},
    {
    id: 3,
    name: "Service 3",
    description: "Service 3 description",

},
    {
    id: 4,
    name: "Service 4",
    description: "Service 4 description",

},
]

const initialModalState = { visible: false, item: null, title: "Add new game" };

const WebServicePage = () => {
  const history = useHistory();
  const [modalState, setModalState] = useState(initialModalState);
  const [gamesListData, setGamesListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [propName, setPropName] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    defaultCurrent: 1,
    defaultPageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPaginationSettings({ ...paginationSettings, current, pageSize });
    setParams({
      ...params,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    });
  };

  const getListOfGames = async (searchKeyWord, params) => {
    try {
      setIsLoading(true);
      const res = await gamesAPI().getGameList(searchKeyWord, params);
      setGamesListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  const closeModal = () => {
    setModalState(initialModalState);
  };

  const openModal = (game) => {
    setModalState({ ...initialModalState, visible: true, item: game });
  };

  const viewDetails = (game) => {
    return history.push("/wb/brands/" + game.id);
  };

  const handlePopularGame = async (data) => {
    // try {
    //   await gamesAPI().editGame({ ...data, popular: !data.popular }, data.id);
    //   getListOfGames(searchKeyWord, params);
    //   notifySuccess(`${data.name} is updated.`);
    // } catch (e) {
    //   parseErrors(e.response.data);
    // }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getListOfGames(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "Game name",
      render: (data) => {
        return (
          <Tooltip title={"View details"}>
            <Space
              style={{ cursor: "pointer" }}
              onClick={() => viewDetails(data)}
            >
              <EyeOutlined></EyeOutlined>
              <b>{data?.name ?? "/"}</b>
            </Space>
          </Tooltip>
        );
      },
    },

    {
      title: "Popular",
      render: (data) => {
        return (
          <Switch
            checked={data.popular}
            onChange={() => handlePopularGame(data)}
          />
        );
      },
    },
    {
      title: "Sale",
      render: (data) => {
        return (
          <div>
            <b>{data?.sale ?? 0}</b>
            <PercentageOutlined />
          </div>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created",
      key: "created",
      render: (created) => {
        return (
          <Tag color={"green"}>{moment(created).format("DD MM YYYY")}</Tag>
        );
      },
    },
    {
      title: "Updated at",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => {
        return <Tag color={"blue"}>{moment(updated).format("DD MM YYYY")}</Tag>;
      },
    },
    {
      title: "Edit",
      key: "edit",
      render: (game) => {
        return (
          <Space>
            <Button onClick={() => openModal(game)}>Edit game</Button>
            <Popconfirm
              title={"Are you sure to delete " + game.name + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => handleDelete(game)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: paginationSettings.current - 1,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  const handleDelete = async (item) => {
    // try {
    //   const { id } = item;
    //   await gamesAPI().deleteGame(id);
    //   getListOfGames(searchKeyWord, params);
    //   notifySuccess(`${item?.name}  is deleted.`);
    // } catch (e) {
    //   parseErrors(e.response.data);
    // }
    alert("Api not connected")
  };
  console.log("gamesListData", gamesListData);
  const [popupDelete, setPopupDelete] = useState(false);
  const onPopup = (state) => {
    setPopupDelete(state);
  };
  

  return (
    <div className={styles.container}>
      <ModalAddGame
        refreshData={() => getListOfGames(searchKeyWord, params)}
        modalState={modalState}
        closeModal={closeModal}
      />
      <h1 className="text-left text-4xl font-bold text-[#f4f4f4] flex items-center gap-5 p-10">
        {" "}
        <img width={25} src={brands_img}></img>Web Service Page{" "}
      </h1>

      <div className={styles.addNewContainer}>
        <Search
          placeholder="Search Service Page"
          size="large"
          allowClear
          loading={false}
          onChange={debouncedChangeHandler}
        />

        {/* <Button
          shape={"round"}
          onClick={() => openModal()}
          size={"large"}
          icon={<PlusSquareOutlined />}
        >
          Add game
        </Button> */}
        <Link to = "/add-web-service-page"> 
        <button
          className="flex items-center gap-4 min-w-max
           text-white bg-[#027f7b] px-5 py-3 border-2 border-[#00d8a3] transition-all hover:bg-butotn_bg_hover"
         
        >
          <img src={add_new_brand}></img>
          Add New Service Page
        </button>
        </Link>
      </div>
      {/* <StyledTable
        pagination={paginationSettings}
        dataSource={gamesListData}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      /> */}
      <div>
        {isLoading?
        
        <Skeleton active />
         :
        (<div className=" grid grid-cols-2 gap-2">
          {dummyData.map((item, index) => (
            <div
              className="flex items-center gap-5 bg-[#03364a] w-[100%] h-[8vw] rounded-lg relative z-1"
              key={index}
            >
              <div className="flex items-center justify-center left-panel gap-5 w-4/5 ml-3 relative z-10">
                
                  <div className="bg-[#052634] p-4 flex justify-center items-center rounded-lg">
                    <img
                      className=""
                      src={service_type_inner}
                      alt="service_type_inner"
                    ></img>
                  </div>
               

                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-white font-bold text-2xl text-left w-full">
                    {item.name}
                  </h1>

                  <div className="flex items-center gap-5">
                    <Link to="/edit-web-service-page">
                    <button
                      className="new_btn_service text-sm font-bold flex items-center gap-1 relative z-10"
                      
                    >
                      <img src={edit_Service} alt="editService"></img>Edit
                      Service
                    </button>
                    </Link>
                    {popupDelete ? (
                      <DeletePopup
                        id="deletepopup"
                        style={{ color: "red", transform: "scale(1.3)" }}
                        onDelete={() => handleDelete(item)}
                        
                        handleCancel={onPopup}
                        serviceName={propName}
                        
                      />
                    ) : null}
                    {/* <Popconfirm
                      title={"Are you sure to delete " + item.name + "?"}
                      icon={
                        <QuestionCircleOutlined
                          style={{ color: "red", transform: "scale(1.3)" }}
                        />
                      }
                      onConfirm={() => handleDelete(item)}
                      okText="Yes"
                      cancelText="No"
                      size={"large"}
                    > */}
                    <button
                      className="danger_btn text-sm font-bold flex items-center gap-1 relative z-10"
                      onClick={(e)=>{ onPopup(e); 
                        setPropName(item.name);}
                       }
                    >
                      <img src={delete_service} alt="editService"></img>Delete
                      Service
                    </button>
                    {/* </Popconfirm> */}
                  </div>
                </div>
              </div>

              <div className="flex items-start justify-end right-panel h-full pt-3 gap-3 pr-2 w-2/5 ">
                <span className="text-[#b1b6b9] font-bold">Service Status</span>
                <div class="toggle-switch">
                  <input type="checkbox" id="toggle" class="toggle-input" />
                  <label for="toggle" class="toggle-label"></label>
                </div>
              </div>
            </div>
          ))}
        </div>)}
      </div>
    </div>
    // </div>
  );
};

export default WebServicePage;
