import { Tabs } from "antd";
import { useState } from "react";

import AcceptedOrders from "./AcceptedOrders";
import NewOrders from "./NewOrders";
import RequestLobby from "./RequestLobby";
import BoostingSettings from "./BoostingSettings";
import ProfileImage from "../ProfileImage";
import MainRequestLobby from "./MainRequestLobby";

const { TabPane } = Tabs;

const Selling = ({ userId, refreshData }) => {
  const [activeTab, setActiveTab] = useState("new");
  return (
    <>
      <Tabs
        onChange={(tab) => setActiveTab(tab)}
        type="card"
        defaultActiveKey="profile"
      >
        <TabPane tab="New orders" key="new" />
        <TabPane tab="Accepted orders" key="accepted" />
        <TabPane tab="Request lobby " key="lobby" />
        <TabPane tab="Boosting setting " key="settings" />
      </Tabs>
      {activeTab == "new" && <NewOrders userId={userId} />}
      {activeTab == "accepted" && <AcceptedOrders userId={userId} />}
      {activeTab == "lobby" && <MainRequestLobby userId={userId} />}
      {activeTab == "settings" && <BoostingSettings userId={userId} />}
    </>
  );
};

export default Selling;
