import UsersComponent from "../../components/Users/Users.js";
import styles from "./Users.module.css";

const Users = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Users </h1>
      <UsersComponent />
    </div>
  );
};

export default Users;
