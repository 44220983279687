import usersAPI from "../../../http/users";
import { notifyError, notifySuccess } from "../../../utils/notifications";
import { useEffect, useMemo, useState } from "react";
import { Button, List, Popconfirm, Space } from "antd";
import Search from "antd/es/input/Search";
import { debounce } from "lodash";
import moment from "moment";
import { DollarCircleFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import ModalOrderInfo from "../../Sales/Modals/ModalOrderInfo";
import { StyledTable } from "../Users";

const NewOrders = ({ userId }) => {
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState({});
  const [modalState, setModalState] = useState({
    isVisible: false,
    item: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
    defaultCurrent: 1,
    defaultPageSize: 5,
    pageSizeOptions: [5,10,20]
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setPaginationSettings({ ...paginationSettings, current, pageSize });
    setParams({
      ...params,
      limit: pageSize,
      offset: (current - 1) * pageSize,
    });
  };
  const getList = async () => {
    try {
      setIsLoading(true);
      const res = await usersAPI().getNewOrders(userId, searchKeyWord, params);
      setData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      console.log({ res }, "regular orders");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notifyError(e?.response?.data);
    }
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
    getList();
  }, [searchKeyWord, params]);

  const viewDetailsModal = (data) => {
    setModalState({ ...modalState, isVisible: true, item: data });
  };

  const closeModal = (data) => {
    setModalState({ isVisible: false });
  };

  const acceptOrder = async (data) => {
    console.log(data);
    try {
      const res = await usersAPI().handleNewOrder(userId, data?.order?.id, {
        invite_status: 1,
      });
      getList();
      notifySuccess("Order is accepted.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };
  const declineOrder = async (data) => {
    console.log(data);
    try {
      const res = await usersAPI().handleNewOrder(userId, data?.order?.id, {
        invite_status: 2,
      });
      getList();
      notifySuccess("Order is declined.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };

  const mouseHandle = (id, type) => {
    if (type == 0) {
      setShowDetails({ ...showDetails, [id]: true });
    } else {
      setShowDetails({ ...showDetails, [id]: false });
    }
  };

  const columns = [
    {
      title: "Order info",
      key: "name",
      width: 280,

      render: (data) => {
        return (
          <Space direction="vertical">
            <Space>
              <b> Order ID:</b>
              <span> {data?.order?.id ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b>Game:</b>
              <span> {data?.order?.game?.name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b>Service:</b>
              <span> {data?.service_name ?? "/"}</span>
            </Space>{" "}
            <Space>
              <b> Order date:</b>
              <span> {moment(data.created).format("DD MMM YYYY")}</span>
            </Space>{" "}
            <Space>
              <b> Buyer:</b>
              <span>
                {" "}
                {data?.order?.buyer?.first_name +
                  " " +
                  data?.order?.buyer?.last_name ?? "/"}
              </span>
            </Space>{" "}
            <Space>
              <b> Price:</b>
              <Space>
                {" "}
                <DollarCircleFilled />
                <span style={{ color: "#22c58b" }}>
                  {data?.order?.usd_price ?? "/"}
                </span>
              </Space>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Offer",
      key: "offer",
      render: (data) => {
        return (
          <OfferContainer
            // onMouseEnter={() => mouseHandle(data?.id)}
            onMouseLeave={() => mouseHandle(data?.id, 1)}
          >
            <StyledInfos>
              {!showDetails[data?.id] && (
                <div>
                  <b>Offer: </b>
                  <span>{data?.order?.offer?.name ?? "/"}</span>
                </div>
              )}
              {showDetails[data?.id] && (
                <StyledInfoCard>
                  <List size={"small"} header={<b>1.Order Info</b>}>
                    <List.Item>
                      <b>Order ID:</b>
                      <span>#{data?.order?.id ?? "/"}</span>
                    </List.Item>{" "}
                    <List.Item>
                      <b>Game:</b>
                      <span>{data?.order?.game?.name ?? "/"}</span>
                    </List.Item>{" "}
                    <List.Item>
                      <b>Service:</b>
                      <span>{data?.order?.service?.name ?? "/"}</span>
                    </List.Item>{" "}
                    <List.Item>
                      <b>Product:</b>
                      <span>{data?.order?.product_name ?? "/"}</span>
                    </List.Item>{" "}
                    <List.Item>
                      <b>Offer:</b>
                      <span>{data?.order?.offer.name ?? "/"}</span>
                    </List.Item>{" "}
                    <List.Item>
                      <b>Seller:</b>
                      <span>
                        {data?.user?.first_name + " " + data?.user?.last_name ??
                          "/"}
                      </span>
                    </List.Item>
                  </List>
                  <List size={"small"} header={<b>2.Offer Details</b>}>
                    <List.Item>
                      <b>Offer name: </b>
                      <span>{data?.order?.offer?.name} </span>
                    </List.Item>{" "}
                    <List.Item>
                      <b>Created: </b>
                      <span>
                        {moment(data?.order?.offer?.created).format(
                          "DD MM YYYY"
                        )}{" "}
                      </span>
                    </List.Item>{" "}
                    <List.Item>
                      <b>Price: </b>
                      <Space>
                        <DollarCircleFilled />
                        <span>{data?.order?.offer?.usd_price}</span>
                      </Space>
                    </List.Item>{" "}
                    <List.Item>
                      <b>Description: </b>

                      <span>{data?.order?.offer?.description}</span>
                    </List.Item>
                  </List>
                </StyledInfoCard>
                // <Button onClick={() => viewDetailsModal(data)}>
                //   Order info
                // </Button>
              )}
            </StyledInfos>
            <Space direction={"vertical"}>
              <Popconfirm
                title="Are you sure to accept this order?"
                onConfirm={() => acceptOrder(data)}
                okText="Yes"
                cancelText="No"
              >
                <Button type={"primary"}>Accept</Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure to decline this order?"
                onConfirm={() => declineOrder(data)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Decline</Button>
              </Popconfirm>
              {!showDetails[data?.id] ? (
                <Button onClick={() => mouseHandle(data?.id, 0)}>
                  Details
                </Button>
              ) : (
                <Button onClick={() => mouseHandle(data?.id, 1)}>Close</Button>
              )}
            </Space>
          </OfferContainer>
        );
      },
    },
  ];

  return (
    <>
      {/*<PurchaseReviewModal modalState={modalState} closeModal={closeModal} />*/}
      <ModalOrderInfo modalState={modalState} closeModal={closeModal} />
      <StyledSearch
        placeholder="Search orders"
        size="large"
        allowClear
        loading={false}
        onChange={debouncedChangeHandler}
      />

      <StyledTable
        pagination={paginationSettings}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default NewOrders;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #f1f1f6;
  border: solid 1px #e0e5ec;
  border-radius: 6px;
  height: 58px;
  width: 100%;
`;

const StyledSearch = styled(Search)`
  width: 300px;
  margin: 10px 0;
`;

export const OfferContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;
export const StyledInfos = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const StyledInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  transition: ease-in 1s;
`;
