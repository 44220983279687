import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const router = useHistory();
  const redirectToLogin = () => router.push("/");
  return (
    <Result
      status="warning"
      title="There are some problems with your operation."
      extra={
        <Button onClick={redirectToLogin} type="primary">
          Back To Login
        </Button>
      }
    />
  );
};

export default NotFound;
