import styles from "./UserDetails.module.css";
import UserDetailsComponent from "../../components/Users/UserDetails.js";

const UserDetails = (props) => {
  const userId = props.match.params.userId;
  console.log(userId);
  return (
    <div className={styles.container}>
      <UserDetailsComponent userId={userId} />
    </div>
  );
};

export default UserDetails;
