import { Button, Modal, Tabs } from "antd";
import React from "react";
import Details from "../../Sales/Modals/Details";

const ModalViewOffer = ({ closeModal, modalState }) => {
  if (!modalState.item) return <></>;

  const { item } = modalState;

  return (
    <Modal
      width={1080}
      title="Viewing order"
      visible={modalState.isVisible}
      onCancel={closeModal}
      footer={[<Button onClick={closeModal}>Close</Button>]}
    >
      <Details item={item} />
    </Modal>
  );
};

export default ModalViewOffer;
