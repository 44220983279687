import styles from "./TypeOfService.module.css";
import styles2 from "./DeliveryOptions.module.css";
import {
  Button,
  Input,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
  Skeleton,
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  PercentageOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import servicesAPI from "../../http/services";
import { useEffect, useMemo, useState } from "react";
// import ModalAddService from "./Modals/ModalAddService";
import { notifySuccess } from "../../utils/notifications";
import { debounce } from "lodash";
import parseErrors from "../../utils/parseServerError";
// import { StyledTable } from "../Users/Users";
import "../../newStyle.css";
import service_type_inner from "../../assets/icons/Service-Test-2.png";
import edit_Service from "../../assets/icons/EDIT-SERVICE.png";
import delete_service from "../../assets/icons/DELETE-SERVICE.png";
// import DeletePopup from "../DeletePopup/DeletePopup";
import axios from "axios";
import add_new_service from "../../assets/icons/ADD-NEW-SERVICE.png";
import DeletePopupWithoutLink from "../DeletePopup/DeletePopupWithoutLink";

const { Title } = Typography;
const { Search } = Input;

const initialModalState = {
  title: "Add New Service",
  visible: false,
  item: null,
};

const DeliveryOptions = () => {
  const [visible, setVisible] = useState(false);
  const [deliveryOptions_name, setDeliveryOptions_name] = useState({
    name: "",
  });
  const [modalState, setModalState] = useState(initialModalState);
  const [deliveryOptions_EditName, setDeliveryOptions_EditName] = useState({
    name: deliveryOptions_name.name,
  });
  const [servicesListData, setServicesListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [selectedServiceName , setSelectedServiceName] = useState(null)
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [popupDelete, setPopupDelete] = useState(false);
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });
  const openModal = (game) => {
    setModalState({ ...initialModalState, visible: true, item: game });
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const handleDelete = async (item) => {
    // try {
    //   const { id } = item;
    //   await gamesAPI().deleteGame(id);
    //   getListOfGames(searchKeyWord, params);
    //   notifySuccess(`${item?.name}  is deleted.`);
    // } catch (e) {
    //   parseErrors(e.response.data);
    // }
  };
  const [modalStateAddService, setModalStateAddService] =
    useState(initialModalState);

  const addNewService = () =>
    setModalStateAddService({ ...initialModalState, visible: true });

  const editService = (item) =>
    setModalStateAddService({
      ...initialModalState,
      title: "Edit service",
      item,
      visible: true,
    });

  let url = "https://api.masterloot.com";
  const closeModal = () => setModalStateAddService({ ...initialModalState });

  const getListOfServices = async () => {
    try {
      setIsLoading(true);
      const res = await servicesAPI().getServicesList(searchKeyWord, params);
      setServicesListData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.dir(e);
    }
  };

  const changeStatusOfService = async (servis) => {
    try {
      const { status, id } = servis;
      await servicesAPI().editService({ status: !status }, id);
      getListOfServices();
      notifySuccess(`Status for  ${servis?.name}  changed`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const deleteServis = async (servis) => {
    try {
      const { id } = servis;
      await servicesAPI().deleteService(id);
      notifySuccess(`${servis.name} is deleted.`);
      getListOfServices();
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getListOfServices(searchKeyWord, params);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyWord, params]);

  const columns = [
    {
      title: "Service",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Games added",
      dataIndex: "games",
      key: "games",
      render: (game) => {
        return <Tag>{game.length}</Tag>;
      },
    },
    {
      title: "Sale",
      render: (data) => {
        return (
          <div>
            <b>{data?.sale ?? 0}</b>
            <PercentageOutlined />
          </div>
        );
      },
    },
    {
      title: "Popular games",
      dataIndex: "popular_games",
      key: "popular_games",
      render: (popular_games) => {
        return <Tag>{popular_games.length}</Tag>;
      },
    },
    {
      title: "Status",
      render: (data) => {
        return (
          <Switch
            checked={data.status}
            onChange={() => changeStatusOfService(data)}
          />
        );
      },
    },
    {
      title: "Edit",
      key: "edit",
      render: (item) => {
        return (
          <Space>
            <Button
              type={"primary"}
              icon={<EditOutlined />}
              onClick={() => editService(item)}
            >
              Edit
            </Button>
            <Popconfirm
              title={"Are you sure to delete " + item.name + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => deleteServis(item)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const changeHandler = (event) => {
    setPaginationSettings({ ...paginationSettings, current: 1 });
    setParams({
      ...params,
      offset: (paginationSettings.current - 1) * 5,
    });
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );
  const [deliveryOptions, setDeliveryOptions] = useState([]);

  useEffect(() => {
    const getOffer = async () => {
      const response = await axios.get(`${url}/api/delivery-options`);
      // const data = await response.json();
      setIsLoading(true);
      console.log("delivery options ki detials", response);
      if (response.status == 200) {
        setIsLoading(false);
        setDeliveryOptions(response.data);
      } else {
        alert("No Data found");
      }
    };
    getOffer();
  }, []);

  const deleteOffer = async (id) => {
    const response = await axios.delete(` ${url}/api/delivery-options/${id}`);
    setIsLoading(true);
    if (response.ok) {
      setIsLoading(false);

      window.location.reload();
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    setDeliveryOptions_name({
      ...deliveryOptions_name,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeEdit = (e) => {
    e.preventDefault();
    setDeliveryOptions_EditName({
      ...deliveryOptions_EditName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const response = await fetch(` ${url}/api/delivery-options/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: deliveryOptions_name.name,
      }),
    });

    const json = await response;
    // console.log(json);

    if (response.ok) {
      alert("Delivery Option added successfully", deliveryOptions_name.name);
      console.log(
        "Delivery Option added successfully",
        deliveryOptions_name.name
      );
      // window.location.reload();
    } else {
      alert(json.message);
      console.log(json.message);
    }
  };
  const onPopup = (state , item) => {
    setPopupDelete(state);
    setSelectedServiceName(item)

  };
  const handleEdit = async (id) => {
    const response = await axios.patch(` ${url}/api/delivery-options/${id}`, {
      name: deliveryOptions_name.name,
    });

    const json = await response;
    // console.log(json);

    if (response.ok) {
      alert("Delivery Option Editted successfully");
      window.location.reload();
    } else {
      alert(json.message);
      console.log(json.message);
    }
  };

  return (
    <div className={styles2.section}>
      <div className={styles.container}>
        <h1 className={styles.header}>Delivery Options</h1>

        <div className={styles.addNewContainer}>
          <Search
            placeholder="Search Delivery Option"
            size="large"
            allowClear
            loading={false}
            onChange={debouncedChangeHandler}
          />

          <button
            className="bg-button_bg_main p-3 w-80 text-white border-neon_border_line border-2 flex justify-center items-center gap-3"
            onClick={() => openModal()}
            size={"large"}
            icon={<PlusSquareOutlined />}
          >
            <img src={add_new_service} alt={add_new_service}></img>
            Add New Offer Type
          </button>
        </div>

        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {deliveryOptions?.map((item) => (
              <div
                className="flex items-center gap-5 bg-[#03364a] w-[100%] h-[8vw] rounded-lg"
                key={item.id}
              >
                <div className="flex items-center justify-center left-panel w-4/5 gap-2">
                  <div className="bg-[#052634] p-4 flex justify-center items-center rounded-lg">
                    <img
                      className=""
                      src={service_type_inner}
                      alt="service_type_inner"
                    ></img>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-2">
                    <h1 className="text-white font-bold text-2xl text-left w-full">
                      {item.name}
                    </h1>
                    <div className="flex items-center gap-5">
                      <button
                        className="bg-button_bg_main border-2 border-neon_border_line text-white p-1 px-2 text-sm font-bold flex items-center gap-1"
                        onClick={() => {
                          setModalState({
                            ...initialModalState,
                            visible: true,
                            item: item,
                          });
                          openModal(item);
                        }}
                      >
                        <img src={edit_Service} alt="editService"></img>Edit
                        Delivery Option
                      </button>

                      {popupDelete ? (
                        <DeletePopupWithoutLink
                          id="deletepopup"
                          style={{ color: "red", transform: "scale(1.3)" }}
                          onDelete={(item) => handleDelete(item)}
                          handleCancel={onPopup}
                          serviceName={selectedServiceName}
                          serviceId={item.id}
                          item={item}
                        />
                      ) : null}
                      {/* <Popconfirm
                  title={"Are you sure to delete " + item.name + "?"}
                  icon={
                    <QuestionCircleOutlined
                      style={{ color: "red", transform: "scale(1.3)" }}
                    />
                  }
                  onConfirm={() => deleteServis(item)}
                  okText="Yes"
                  cancelText="No"
                  size={"large"}
                > */}
                      <button
                        className="bg-danger_btn border-2 border-danger_btn_outline text-white p-1 px-2 text-sm font-bold flex items-center gap-1 "
                        onClick={()=>onPopup(true, item.name)}
                      >
                        <img src={delete_service} alt="editService"></img>Delete
                        Delivery Option
                      </button>
                      {/* </Popconfirm> */}
                    </div>
                  </div>
                </div>
                <div className="flex items-start justify-end right-panel h-full pt-3 gap-3 pr-2 w-2/5 ">
                  <span className="text-[#b1b6b9] font-bold">
                    Service Status
                  </span>
                  <div class="toggle-switch">
                    <input type="checkbox" id="toggle" class="toggle-input" />
                    <label for="toggle" class="toggle-label"></label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default DeliveryOptions;
