import { getApiClient } from "../client";

function roomsAPI() {
  return {
    getRooms: (search, rules) =>
      getApiClient().get("/admin/chat/rooms", { params: { search, ...rules } }),
    getRoom: (roomId, search, rules) =>
      getApiClient().get(`/admin/chat/room/${roomId}`, {
        params: { search, ...rules },
      }),
  };
}

export default roomsAPI;
