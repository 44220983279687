import { getApiClient } from "../client";

function transactionsAPI() {
  return {
    editWithdraw: (updatedWithdrawData, withdrawId) =>
      getApiClient().put(`/admin/withdraw/${withdrawId}`, updatedWithdrawData),
    getWithdrawsList: (search, params) =>
      getApiClient().get("/admin/withdraw/list", {
        params: { search, ...params },
      }),
    editRefund: (updatedRefundData, refundId) =>
      getApiClient().put(`/admin/refund/${refundId}`, updatedRefundData),
    getRefundsList: (search, rules) =>
      getApiClient().get("/admin/refund/list", {
        params: { search, ...rules },
      }),
  };
}

export default transactionsAPI;
