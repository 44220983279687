import { Form, InputNumber, List, Modal, Space } from "antd";
import { useRef } from "react";
import transactionsAPI from "../../../http/transactions";
import parseErrors from "../../../utils/parseServerError";
import { notifySuccess } from "../../../utils/notifications";

const RefundModal = ({ closeModal, modalState, reloadData }) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  if (!modalState.item) return <></>;

  const onFinish = async (values) => {
    try {
      const res = await transactionsAPI().editRefund(
        { ...values, status: 3 },
        modalState?.item?.id
      );
      reloadData();
      form.resetFields();
      closeModal();
      notifySuccess("Order is refunded.");
    } catch (e) {
      parseErrors(e?.response?.data);
    }
  };

  return (
    <Modal
      width={1080}
      title={"Refunding order"}
      onCancel={closeModal}
      visible={modalState?.isVisible}
      okText={"Refund order"}
      cancelText={"Cancel"}
      onOk={() => formRef?.current?.submit()}
    >
      <List size={"large"} header={<h4>Specify refund price</h4>}>
        <List.Item
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Space direction="vertical">
            <label style={{ fontSize: "18px" }}>Order Price:</label>
            <b style={{ fontSize: "26px", color: "#52c41a" }}>
              {(modalState?.item?.order_price).toFixed(2)} $
            </b>
          </Space>
        </List.Item>
        <List.Item>
          <Form
            ref={formRef}
            form={form}
            layout={"vertical"}
            style={{ width: "100%" }}
            onFinish={onFinish}
          >
            <Form.Item
              hasFeedback
              label={"Buyers refund"}
              name={"buyer_refund_price"}
              required
              rules={[
                { required: true, message: "This field is required" },
                {
                  type: "number",
                  warningOnly: true,
                  message: "This field require number",
                },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>{" "}
            <Form.Item
              hasFeedback
              label={"Players share"}
              name={"booster_refund_price"}
              required
              rules={[
                { required: true, message: "This field is required" },
                {
                  type: "number",
                  warningOnly: true,
                  message: "This field require number",
                },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>{" "}
            <Form.Item
              hasFeedback
              label={"Boostrooms share"}
              name={"admin_refund_price"}
              required
              rules={[
                { required: true, message: "This field is required" },
                {
                  type: "number",
                  warningOnly: true,
                  message: "This field require number",
                },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </List.Item>
      </List>
    </Modal>
  );
};

export default RefundModal;
