import usersAPI from "../../../http/users";
import { notifyError, notifySuccess } from "../../../utils/notifications";
import { useEffect, useMemo, useState } from "react";
import { Button, List, Popconfirm, Space, Switch, Table, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import { debounce } from "lodash";
import moment from "moment";
import {
  ArrowLeftOutlined,
  DollarCircleFilled,
  DownOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import ModalOrderInfo from "../../Sales/Modals/ModalOrderInfo";
import { StyledTable } from "../Users";
import parseServerError from "../../../utils/parseServerError";

const SettingsServices = ({ userId }) => {
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState({});
  const [modalState, setModalState] = useState({
    isVisible: false,
    item: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 5,
    offset: 0,
  });

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    setPaginationSettings({ ...paginationSettings, current });
    setParams({
      ...params,
      offset: (current - 1) * 5,
    });
  };
  const getList = async () => {
    try {
      setIsLoading(true);
      const res = await usersAPI().getSettingsServices(
        userId,
        searchKeyWord,
        params
      );
      setData(res.data.results);
      setPaginationSettings({ ...paginationSettings, total: res.data.count });
      console.log({ res }, "regular orders");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      notifyError(e?.response?.data);
    }
  };

  const changeHandler = (event) => {
    setSearchKeyWord(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  useEffect(() => {
    getList();
  }, [searchKeyWord, params]);

  const viewDetailsModal = (data) => {
    setModalState({ ...modalState, isVisible: true, item: data });
  };

  const closeModal = (data) => {
    setModalState({ isVisible: false });
  };

  const acceptOrder = async (data) => {
    console.log(data);
    try {
      const res = await usersAPI().handleNewOrder(userId, data.id, {
        invite_status: 1,
      });
      getList();
      notifySuccess("Order is accepted.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };
  const declineOrder = async (data) => {
    console.log(data);
    try {
      const res = await usersAPI().handleNewOrder(userId, data.id, {
        invite_status: 2,
      });
      getList();
      notifySuccess("Order is declined.");
    } catch (e) {
      notifyError(e?.response?.data);
    }
  };

  const mouseHandle = (id, type) => {
    if (type == 0) {
      setShowDetails({ ...showDetails, [id]: true });
    } else {
      setShowDetails({ ...showDetails, [id]: false });
    }
  };

  const handleServiceChange = async (value, gameId, service, services) => {
    try {
      console.log(value, gameId, service);

      let filteredServices = services.filter((item) => item.id !== service.id);

      const res = await usersAPI().editSettingsServices(userId, {
        game_services: [
          {
            game: gameId,
            services:
              filteredServices.length > 0
                ? filteredServices.map((item) => item.id)
                : [],
          },
        ],
      });
      getList();
      notifySuccess("Status changed");
    } catch (e) {
      parseServerError(e);
    }
  };
  const columns = [
    {
      title: "Sent requests",
      key: "request",
      render: (data, key) => {
        return (
          <>
            <RequestContainer>
              <InfoStyles>
                <GameName>{data?.game?.name}</GameName>
                <SubscriptionList>
                  Active services {data?.number_of_active_services ?? 0}/
                  {data?.number_of_services ?? 0}
                </SubscriptionList>
              </InfoStyles>
              <ActionStyle
                onClick={() => {
                  if (!showDetails[key]) {
                    mouseHandle(key, 0);
                  } else {
                    mouseHandle(key, 1);
                  }
                }}
              >
                <Tooltip title={"Show details"}>
                  {!showDetails[key] ? <LeftOutlined /> : <DownOutlined />}
                </Tooltip>
              </ActionStyle>
            </RequestContainer>
            {showDetails[key] && (
              <EditedRequestContainer>
                <TitleApprovedService>Approved services</TitleApprovedService>
                <DescriptionApprovedService>
                  Select services you can do for this game
                </DescriptionApprovedService>
                {data?.services?.map((service) => {
                  return (
                    <SwitchContainer key={service?.id}>
                      <Switch
                        checked={service?.active}
                        onChange={(value) =>
                          handleServiceChange(
                            value,
                            data?.game?.id,
                            service,
                            data?.services
                          )
                        }
                      ></Switch>
                      <ServiceNameStyle>{service?.name}</ServiceNameStyle>
                    </SwitchContainer>
                  );
                })}
              </EditedRequestContainer>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Container>
      <StyledSearch
        placeholder="Search requests"
        size="large"
        allowClear
        loading={false}
        onChange={debouncedChangeHandler}
      />

      <StyledTable
        pagination={paginationSettings}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </Container>
  );
};

export default SettingsServices;

const RequestContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e0e5ec;
  border-radius: 5px;
`;

const GameName = styled.span`
  font-weight: 600;
  font-size: 20px;
  font-family: 'Montserrat';
`;

const ServicesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledSearch = styled(Search)`
  width: 300px;
  margin: 10px 0;
`;

export const StyledInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  transition: ease-in 1s;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppliedStyle = styled.span`
  margin: 0px 5px 0 0;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #001334;
`;

const ServicesStyleNames = styled.span`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #001334;
`;

const SubscriptionList = styled.span`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1aa2f9;
`;

const InfoStyles = styled.div`
  display: flex;
  flex-direction: column;
`;
const ActionStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SwitchContainer = styled.div`
  display: flex;
  border: solid 1px #e0e5ec;
  width: 100%;
  height: 48px;
  align-items: center;
  padding: 10px;
`;
const ServiceNameStyle = styled.span`
  margin: 1px 0 0 15.5px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #001334;
`;

const TitleApprovedService = styled.span`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #001334;
`;

const DescriptionApprovedService = styled.span`
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #001334;
`;

const EditedRequestContainer = styled(RequestContainer)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 5px;
`;
