import axios from "axios";
import {notifyError} from "../utils/notifications";

let apiClient;
// let host = process.env.NODE_ENV == "development"
//     ? process.env.REACT_APP_BACKEND_LOCALHOST
//     : process.env.REACT_APP_BACKEND_HOST;

// let host = 'https://boostroom.com/br-api'
let host = 'https://api.masterloot.com/api'


console.log("host details" , host );

function createApiClient() {
  const client = axios.create({
    baseURL: host,
  });
  return client;
}

export function getApiClient() {
  let token = localStorage.getItem("token");
  if (token && token != "undefined") {
    apiClient = createApiClient();
    apiClient.interceptors.request.use(
      async (config) => {
        config.headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  } else {
    apiClient = createApiClient();
   apiClient.interceptors.response.use((response) => {
       return response;
     }, (error) => {
           console.log(error);
           console.log("Anes");
           console.log([401, 403].includes(error.response.status));
         if (error.response && [401, 403].includes(error.response.status)) {
           localStorage.removeItem("token"); // <-- add your var
           window.location = "/";  // <-- add your path
         } else
           return Promise.reject(error);
    });
  }

  return apiClient;
}
