import { Button, Popconfirm, Space, Switch, Input, Table } from "antd";
import { notifySuccess } from "../../utils/notifications";
import {
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import offersAPI from "../../http/offers";
import ModalAddOffer from "./Modals/ModalAddOffer";
import parseErrors from "../../utils/parseServerError";
import styles from "./GameServiceDetails.module.css";
import { useState } from "react";
import { StyledTable } from "../Users/Users";

const OffersTable = ({
  offers,
  onSearch,
  refreshData,
  loading,
  setOffset,
  products,
  total,
  serviceId,
  serviceType,
  gameId,
}) => {
  const changeStatusOfOffer = async (offer) => {
    try {
      const { status, id } = offer;
      if (status == 0) {
        await offersAPI().editOffer(
          { ...offer, delivery_time: 3, status: 1 },
          id
        );
      } else {
        await offersAPI().editOffer(
          { ...offer, delivery_time: 3, status: 0 },
          id
        );
      }
      refreshData();
      notifySuccess(`Status for  ${offer?.name}  changed`);
    } catch (e) {
      console.dir(e);
      parseErrors(e.response.data);
    }
  };

  const handleDelete = async (item) => {
    try {
      const { id } = item;
      await offersAPI().deleteOffer(id);
      refreshData();
      notifySuccess(`${item?.name}  is deleted.`);
    } catch (e) {
      parseErrors(e.response.data);
    }
  };

  const columns = [
    {
      title: "Offer name",
      key: "offer",
      dataIndex: "name",
      render: (data) => {
        return <b>{data}</b>;
      },
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category_name",
    },
    {
      title: "Product",
      key: "product_name",
      dataIndex: "product_name",
    },
    {
      title: "Sale",
      render: (data) => {
        return (
          <div>
            <b>{data?.sale ?? 0}</b>
            <PercentageOutlined />
          </div>
        );
      },
    },
    {
      title: "Status",
      render: (data) => {
        let checked = data.status == 1 ? true : false;
        return (
          <Switch
            checked={checked}
            onChange={() => changeStatusOfOffer(data)}
          />
        );
      },
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "usd_price",
      render: (data) => {
        return (
          <Space>
            <ArrowUpOutlined style={{ color: "green" }} />
            <b>${data}</b>{" "}
          </Space>
        );
      },
    },
    {
      title: "Actions",
      key: "edit",
      render: (item) => {
        return (
          <Space>
            <Button
              onClick={(event) => openModal(event, item)}
              type={"primary"}
              icon={<EditOutlined />}
            >
              Edit{" "}
            </Button>
            <Popconfirm
              title={"Are you sure to delete " + item.name + "?"}
              icon={
                <QuestionCircleOutlined
                  style={{ color: "red", transform: "scale(1.3)" }}
                />
              }
              onConfirm={() => handleDelete(item)}
              okText="Yes"
              cancelText="No"
              size={"large"}
            >
              <Button icon={<DeleteOutlined />} danger color={"red"}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const [modalState, setModalState] = useState({
    isVisible: false,
    editing: false,
  });

  const closeModal = () => {
    console.log("Desava se");
    setModalState({ visible: false, item: null, editing: false });
  };
  const openModal = (event, item) => {
    if (item) {
      setModalState({ ...modalState, visible: true, item, editing: true });
    } else {
      setModalState({ ...modalState, visible: true, item, editing: false });
    }
  };

  return (
    <>
      <ModalAddOffer
        modalState={modalState}
        refreshData={refreshData}
        closeModal={closeModal}
        products={products}
        serviceId={serviceId}
        serviceType={serviceType}
        gameId={gameId}
      />
      <div className={styles.addingButton}>
        <Input.Search allowClear={true} enterButton={true} onSearch={onSearch}/>
        <Button
          type="primary"
          shape="round"
          icon={<PlusCircleOutlined />}
          onClick={(event) => openModal(event)}
        >
          Add offer
        </Button>
      </div>
      <StyledTable loading={loading}
                   columns={columns}
                   dataSource={offers}
                   pagination={{
                       pageSize: 20,
                       total,
                       onChange: (page, pageSize = 0) => {
                           setOffset((page - 1) * pageSize)
                       },
                   }}
      />
    </>
  );
};

export default OffersTable;
