import { Button, DatePicker, Form, Input, InputNumber, Modal } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useRef } from "react";
import moment from "moment";
import parseServerError from "../../../../utils/parseServerError";
import usersAPI from "../../../../http/users";
import { notifySuccess } from "../../../../utils/notifications";

const ModalCreateOffer = ({ modalState, closeModal, refreshData, userId }) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  if (!modalState?.item) return <></>;

  const onFinish = async (values) => {
    try {
      const res = await usersAPI().creatingOffer(userId, {
        order: modalState.item.id,
        ...values,
      });
      notifySuccess("Offer is sent.");
      refreshData();
      closeModal();
    } catch (e) {
      parseServerError(e);
    }
  };

  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };
  function disabledDate(current) {
    return current && current < moment().endOf("day");
  }

  return (
    <Modal
      width={1080}
      visible={modalState.isVisible}
      title={"Creating offer"}
      onOk={() => console.log("OK")}
      onCancel={() => {
        form.resetFields();
        closeModal();
      }}
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          Close
        </Button>,
        <Button key="add" onClick={() => formRef.current?.submit()}>
          Send offer
        </Button>,
      ]}
    >
      <Form
        initialValues={modalState.item}
        ref={formRef}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Enter your price"
          name="usd_price"
          required
          tooltip="Enter price in USD currency"
          rules={[
            {
              required: true,
              message: "Please input your price!",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Enter date & time you can start on"
          name="start_date"
          required
          rules={[
            {
              required: true,
              message: "Please input start date !",
            },
          ]}
          {...config}
        >
          <DatePicker
            disabledDate={disabledDate}
            style={{ width: "100%" }}
            showTime
            format="DD MMMM YYYY HH:mm"
          />
        </Form.Item>
        <Form.Item
          label="Enter date & time you can complete order"
          name="end_date"
          required
          rules={[
            {
              required: true,
              message: "Please input end date !",
            },
          ]}
          {...config}
        >
          <DatePicker
            disabledDate={disabledDate}
            style={{ width: "100%" }}
            showTime
            format="DD MMMM YYYY HH:mm"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreateOffer;
